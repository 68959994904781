import {Services} from '../api/services';
import { PRINT_IN_CONSOLE } from '../components/shared/constants/constants'

export const setGeneralEnums = (locations, userRoles, userStatusTypes, userTypes) => {
    return (dispatch, getState) => {
        
        locations = [];
        userRoles = [];
        userStatusTypes = [];
        userTypes = [];

        Services.Locations.getLocations().then((data)=> {
            if(data.val() !== null) {
                const response = data.val();
                for (var key in response) {
                    locations.push(response[key]);
                }
            } 
        })
        .catch((error)=> {
            if(PRINT_IN_CONSOLE) {
                console.log("Error while getting the locations!");
            }
        });

        Services.General.getUserRoles().then((data)=> {
            if(data.val() !== null) {
                const response = data.val();
                for (var key in response) {
                    userRoles.push(response[key]);
                }
            } 
        })
        .catch((error)=> {
            if(PRINT_IN_CONSOLE) {
                console.log("Error while getting the User Roles!");
            }
        });

        Services.General.getUserStatusTypes().then((data)=> {
            if(data.val() !== null) {
                const response = data.val();
                for (var key in response) {
                    userStatusTypes.push(response[key]);
                }
            } 
        })
        .catch((error)=> {
            if(PRINT_IN_CONSOLE) {
                console.log("Error while getting the User Status Types!");
            }
        });

        Services.General.getUserTypes().then((data)=> {
            if(data.val() !== null) {
                const response = data.val();
                for (var key in response) {
                    userTypes.push(response[key]);
                }
            } 

            const generalEnums = {
                locations: locations.sort((a,b) => (String(a.name).toLowerCase() > String(b.name).toLowerCase()) ? 1 : ((String(b.name).toLowerCase() > String(a.name).toLowerCase()) ? -1 : 0)),
                userRoles: userRoles.sort((a,b) => (String(a.name).toLowerCase() > String(b.name).toLowerCase()) ? 1 : ((String(b.name).toLowerCase() > String(a.name).toLowerCase()) ? -1 : 0)),
                userStatusTypes: userStatusTypes.sort((a,b) => (String(a.name).toLowerCase() > String(b.name).toLowerCase()) ? 1 : ((String(b.name).toLowerCase() > String(a.name).toLowerCase()) ? -1 : 0)),
                userTypes: userTypes.sort((a,b) => (String(a.name).toLowerCase() > String(b.name).toLowerCase()) ? 1 : ((String(b.name).toLowerCase() > String(a.name).toLowerCase()) ? -1 : 0))
            }
    
            dispatch({ type: 'SET_GENERAL_ENUMS', generalEnums });
        })
        .catch((error)=> {
            if(PRINT_IN_CONSOLE) {
                console.log("Error while getting the User Types!");
            }
        });

        
    }
};

export const updateStatusTypesInStore = (userStatusTypes) => {
    return (dispatch, getState) => {
        dispatch({ type: 'SET_USER_STATUS_TYPES', userStatusTypes });
    };
};

export const setProjectsStatusType = (projectsStatusTypes) => {
    return (dispatch, getState) => {
        Services.General.getProjectsStatusTypes().then((data)=>{
            if(data.val() !== null) {
                projectsStatusTypes = [];
                const response = data.val();
                for (var key in response) {
                    projectsStatusTypes.push(response[key]);
                }
                dispatch({ type: 'SET_PROJECT_STATUS_TYPES', projectsStatusTypes: projectsStatusTypes.sort((a,b) => (String(a.name).toLowerCase() > String(b.name).toLowerCase()) ? 1 : ((String(b.name).toLowerCase() > String(a.name).toLowerCase()) ? -1 : 0)) });
            }
        })
        .catch((error)=> {
            alert("Error while getting the Projects Status Types!");
        });
    };
}

export const updateClientStatusTypes = (clientsStatusTypes) => {
    return (dispatch, getState) => {
        dispatch({ type: 'SET_CLIENT_STATUS_TYPES', clientsStatusTypes });
    };
};

export const updateProjectStatusTypes = (projectsStatusTypes) => {
    return (dispatch, getState) => {
        dispatch({ type: 'SET_PROJECT_STATUS_TYPES', projectsStatusTypes });
    };
};

export const setClientsStatusType = (clientsStatusTypes) => {
    return (dispatch, getState) => {
        Services.General.getClientsStatusTypes().then((data)=>{
            if(data.val() !== null) {
                clientsStatusTypes = [];
                const response = data.val();
                for (var key in response) {
                    clientsStatusTypes.push(response[key]);
                }
                dispatch({ type: 'SET_CLIENT_STATUS_TYPES', clientsStatusTypes: clientsStatusTypes.sort((a,b) => (String(a.name).toLowerCase() > String(b.name).toLowerCase()) ? 1 : ((String(b.name).toLowerCase() > String(a.name).toLowerCase()) ? -1 : 0)) });
            }
        })
        .catch((error)=> {
            alert("Error while getting the Clients Status Types!") 
        });
    };
};

export const updateUserRoles = (userRoles) => {
    return (dispatch, getState) => {
        dispatch({ type: 'SET_USER_ROLES', userRoles});
    }
};

export const setUserRoles = (userRoles) => {
    return (dispatch, getState) => {
        Services.General.getUserRoles().then((data)=>{
            if(data.val() !== null) {
                userRoles = [];
                const response = data.val();
                for (var key in response) {
                    userRoles.push(response[key]);
                }
                dispatch({ type: 'SET_USER_ROLES', userRoles: userRoles.sort((a,b) => (String(a.name).toLowerCase() > String(b.name).toLowerCase()) ? 1 : ((String(b.name).toLowerCase() > String(a.name).toLowerCase()) ? -1 : 0)) });
            } else {
                userRoles = [];
                dispatch({ type: '', userRoles });
            }}
        )
        .catch((error)=> {
            alert("Error while getting the User Roles!");
        });
    }
};

export const updateUserStatusTypes = (userStatusTypes) => {
    return (dispatch, getState) => {
        dispatch({ type: 'SET_USER_STATUS_TYPES', userStatusTypes});
    }
};

export const setUserStatusTypes = (userStatusTypes) => {
    return (dispatch, getState) => {
        Services.General.getUserStatusTypes().then((data)=>{
            if(data.val() !== null) {
                userStatusTypes = [];
                const response = data.val();
                for (var key in response) {
                    userStatusTypes.push(response[key]);
                }
                dispatch({ type: 'SET_USER_STATUS_TYPES', userStatusTypes: userStatusTypes.sort((a,b) => (String(a.name).toLowerCase() > String(b.name).toLowerCase()) ? 1 : ((String(b.name).toLowerCase() > String(a.name).toLowerCase()) ? -1 : 0)) });
            } else {
                userStatusTypes = [];
                dispatch({ type: '', userStatusTypes });
            }}
        )
        .catch((error)=> {
            alert("Error while getting the User Status Types!");
            userStatusTypes = [];
            dispatch({ type: '', userStatusTypes });
        });
    }
};

export const updateUserTypes = (userTypes) => {
    return (dispatch, getState) => {
        dispatch({ type: 'SET_USER_TYPES', userTypes});
    }
};

export const setUserTypes = (userTypes) => {
    return (dispatch, getState) => {
        Services.General.getUserTypes().then((data)=>{
            if(data.val() !== null) {
                userTypes = [];
                const response = data.val();
                for (var key in response) {
                    userTypes.push(response[key]);
                }
                dispatch({ type: 'SET_USER_TYPES', userTypes: userTypes.sort((a,b) => (String(a.name).toLowerCase() > String(b.name).toLowerCase()) ? 1 : ((String(b.name).toLowerCase() > String(a.name).toLowerCase()) ? -1 : 0)) });
            } else {
                userTypes = [];
                dispatch({ type: '', userTypes });
            }}
        )
        .catch((error)=> {
            alert("Error while getting the User Types!") 
        });
    }
};

export const setUserProjects = () => {
    return (dispatch, getState) => {
        Services.General.getUserProjects().then((data)=>{

            let userProjects = [];
            if(data.val() !== null) {
                userProjects.push(data.val());
                dispatch({ type: 'SET_USER_PROJECTS', userProjects });
            } else {
                userProjects = [];
                dispatch({ type: '', userProjects });
            }}
        )
        .catch((error)=> {
            alert("Error while getting the User Projects!") 
        });
    }
};

export const updateCapacitiesInStore = (userProjects) => {
    return (dispatch, getState) => {
        dispatch({ type: 'SET_USER_PROJECTS', userProjects });
    }
}

export const getCapacities = (projectId) => {
    return (dispatch, getState) => {
        Services.General.getUserProjects().then((data)=>{
            let capacities = [];
            let userProjects = [];
            let capacity;
            if(data.val() !== null) {
                userProjects = data.val();
                Object.keys(userProjects).forEach((a) => {
                    Object.keys(userProjects[a]).forEach((c) => {
                        capacity = userProjects[a][c];
                        if(typeof(capacity) !== 'undefined' && capacity && capacity.projectId.toString() === projectId.toString()){
                            capacities.push(capacity);
                        }
                    });
                });
                dispatch({ type: 'SET_PROJECT_CAPACITIES', capacities });
            } else {
                userProjects = [];
                dispatch({ type: '', userProjects });
            }}
        )
        .catch((error)=> {
            alert("Error while getting the Capacities!") 
        });
    }
}

export const saveCapacityInStore = (userProjects, userProject) => {
    return (dispatch, getState) => {
        dispatch({ type: 'CREATE_NEW_USER_PROJECT', payload: { userProjects, userProject }});
    };
}

export const createNewUserProject = (userId, projectId, beginDate, endDate, userProjects, status) => {
    return (dispatch, getState) => {
        Services.General.createNewUserProject(userId, projectId, beginDate, endDate, userProjects, status)
            .then((data) => {
                let userProject = {
                    beginDate: data.beginDate, 
                    endDate: data.endDate,
                    id: data.id,
                    projectId: data.projectId,
                    userId: data.userId,
                    status
                };
                if(data.userProjectAdded)
                {
                    // if(!userProjects[0][data.userId]) {
                    //     userProjects[0][data.userId] = {}
                    // }
                    //userProjects[0][data.userId][data.id] = 
                    userProjects.push({ 
                        beginDate: data.beginDate, 
                        endDate: data.endDate,
                        id: data.id, 
                        projectId: data.projectId, 
                        userId: data.userId,
                        status: data.status
                    });
                    userProject["userProjectAdded"] = data.userProjectAdded;
                    userProject["userProjectAddedMessage"] = data.userProjectAddedMessage;
                    dispatch({ type: 'CREATE_NEW_USER_PROJECT', payload: { userProjects, userProject }});
                }
                else {
                    userProject["userProjectAdded"] = false;
                    userProject["userProjectAddedMessage"] = data.userProjectAddedMessage;
                    dispatch({ type: 'CREATE_NEW_USER_PROJECT', payload: { userProjects, userProject }});
                }
            }).catch((error) => {
                let userProject = {
                    beginDate, 
                    endDate,
                    projectId,
                    userId,
                    status
                 };
                userProject["userProjectAdded"] = false;
                userProject["userProjectAddedMessage"] = "Error when creating the capacity";
                dispatch({ type: 'CREATE_NEW_USER_PROJECT', payload: { userProjects, userProject }});
        });
    }
};  

export const updateUserCapacity = (userProjectId, userId, projectId, beginDate, endDate, userProjects, oldUserId, status) => {
    return (dispatch, getState) => {
        Services.General.updateUserCapacity(userProjectId, userId, projectId, beginDate, endDate, userProjects, oldUserId, status)
            .then((data) => {
                let userProject = {
                    beginDate: data.beginDate, 
                    endDate: data.endDate,
                    id: data.id,
                    projectId: data.projectId,
                    userId: data.userId,
                    status: data.status
                };
                if(data.userProjectUpdated)
                {
                    userProjects = userProjects.map(userProject => {
                        if((typeof useProject) !== 'undefined' && userProject !== null && userProject.id === userProjectId) {
                            userProject.beginDate = data.beginDate;
                            userProject.endDate = data.endDate;
                            userProject.projectId = data.projectId; 
                            userProject.userId = data.userId;
                            userProject.status = data.status;
                        }
                    })
                    // if((typeof oldUserId) !== 'undefined' && oldUserId && userId.toString() !== oldUserId.toString()) {
                    //     delete userProjects[0][oldUserId][userProjectId];
                    //     if(Object.keys(userProjects[0][oldUserId]).length === 0) {
                    //         delete userProjects[0][oldUserId];
                    //     }
                    // }
                    // if(!userProjects[0][data.userId]) {
                    //     userProjects[0][data.userId] = {}
                    // }
                    // userProjects[0][data.userId][data.id] = { 
                    //     beginDate: data.beginDate, 
                    //     endDate: data.endDate,
                    //     id: data.id, 
                    //     projectId: data.projectId, 
                    //     userId: data.userId,
                    //     status: data.status
                    // }
                    userProject["userProjectUpdated"] = data.userProjectUpdated;
                    userProject["userProjectAddedMessage"] = data.userProjectUpdatedMessage;
                    dispatch({ type: 'UPDATE_USER_PROJECT', payload: { userProjects, userProject }});
                }
                else {
                    userProject["userProjectUpdated"] = false;
                    userProject["userProjectUpdatedMessage"] = data.userProjectUpdatedMessage;
                    dispatch({ type: 'UPDATE_USER_PROJECT', payload: { userProjects, userProject }});
                }
            }).catch((error) => {
                let userProject = {
                    beginDate, 
                    endDate,
                    id: userProjectId,
                    projectId,
                    userId,
                    status
                 };
                userProject["userProjectUpdated"] = false;
                userProject["userProjectUpdatedMessage"] = "Error when updating the capacity";
                dispatch({ type: 'UPDATE_USER_PROJECT', payload: { userProjects, userProject }});
        });
    }
}; 

export const deleteUserCapacity = (userProjectId, userId, userProjects) => {
    return (dispatch, getState) => {
        Services.General.deleteUserCapacity(userProjectId, userId, userProjects).then((data)=> {
            if(data !== null) {
                if((typeof data.userProjectId) !== 'undefined' && data.userProjectId !== null) {
                    delete data.userProjects[0][userId][userProjectId];
                    const userProject = { "id": data.userProjectId, "userProjectDeleted": true, 
                                    "userProjectDeletedMessage": "Successfully deleted capacity" };
                    const payload = {
                        userProjectId: data.userProjectId,
                        userProjects: data.userProjects,
                        userProject
                    };
                    dispatch({ type: 'REMOVE_USER_PROJECT', payload });
                }
                else {
                    if((typeof data.message) !== 'undefined' && data.message) {
                        const userProject = { "userProjectDeleted": false, "userProjectDeletedMessage": data.message };
                        dispatch({ type: 'REMOVE_USER_PROJECT_ERROR_MESSAGE', userProject });
                    }
                }
            } 
        });
   }; 
}

export const setSelectedUserProject = (selectedUserProjectId, userId) => {
    return (dispatch, getState) => {
        const payload = {
            selectedUserProjectId,
            userId
        }
        dispatch({ type: 'SET_USER_PROJECT_ID', payload });      
   };
}

export const getUserRolesButtonNavigation = () => {
    return (dispatch, getState) => {
        Services.General.getUserRolesButtonNavigation().then((data) => {
            let userRolesButtonNavigation = [];
            if(data !== null) {
                userRolesButtonNavigation = data.userRolesButtonNavigation;
            }
            dispatch({ type: 'SET_USER_ROLES_BUTTON_NAVIGATION', userRolesButtonNavigation });
        });
   };
}

export const setNavigationItems = (navigationItems) => {
    return (dispatch, getState) => {
        dispatch({ type: 'SET_NAVIGATION_ITEMS', navigationItems });      
   };
}

export const getNavigationItems = (currentUserId) => {
    return (dispatch, getState) => {
        let navigationItems = getState().generalStore.navigationItems;
        if(!currentUserId) {
            navigationItems = [];
            dispatch({ type: 'SET_NAVIGATION_ITEMS', navigationItems });
        }
        if(((typeof navigationItems) === 'undefined' || !navigationItems) && currentUserId) {
            Services.General.getNavigationItems(currentUserId).then((data)=> {
                if(data !== null) {
                    navigationItems = data.navigation.sort((a,b) => (String(a.order).toLowerCase() > String(b.order).toLowerCase()) ? 1 : ((String(b.order).toLowerCase() > String(a.order).toLowerCase()) ? -1 : 0))
                    dispatch({ type: 'SET_NAVIGATION_ITEMS', navigationItems });
                } 
            });
        } else {
            navigationItems = navigationItems.sort((a,b) => (String(a.order).toLowerCase() > String(b.order).toLowerCase()) ? 1 : ((String(b.order).toLowerCase() > String(a.order).toLowerCase()) ? -1 : 0))
            dispatch({ type: 'SET_NAVIGATION_ITEMS', navigationItems });
        }
        
        
   };
}

export const setButtonNavigationItems = (buttonNavigationItems) => {
    return (dispatch, getState) => {
        dispatch({ type: 'SET_BUTTON_NAVIGATION_ITEMS', buttonNavigationItems });      
   };
}

export const getButtonNavigationItems = (currentUserId) => {
    return (dispatch, getState) => {
        let buttonNavigationItems = getState().generalStore.buttonNavigationItems;
        if(buttonNavigationItems.length === 0 && currentUserId) {
            Services.General.getButtonNavigationItems(currentUserId).then((data)=> {
                if(data !== null) {
                    buttonNavigationItems = data.navigation;
                    dispatch({ type: 'SET_BUTTON_NAVIGATION_ITEMS', buttonNavigationItems });
                } 
            });
        } else {
            dispatch({ type: 'SET_BUTTON_NAVIGATION_ITEMS', buttonNavigationItems });
        }
             
   };
}

export const setSelectedButton = (selectedButtonId) => {
    return (dispatch, getState) => {
        dispatch({ type: 'SET_BUTTON_ID', selectedButtonId });      
   };
}

export const setButtonNavigationInfo = (buttonNavigationItems, userRolesButtonNavigation, userRoles) => {
    return (dispatch, getState) => {
        const payload = {
            userRoles,
            buttonNavigationItems,
            userRolesButtonNavigation
        };
        dispatch({ type: 'SET_BUTTON_NAVIGATION_INFO', payload });
    };
}

export const setButtonNavigationItemsForButton = (buttonNavigationItems, button) => {
    return (dispatch, getState) => {
        const payload = {
            buttonNavigationItems,
            button
        };
        dispatch({ type: 'CREATE_BUTTON_NAVIGATION_ITEM', payload });
    };
}

export const createButtonNavigation = (button, order, userRoles, buttonNavigationItems) => {
    return (dispatch, getState) => {
        Services.General.createButtonNavigation(button, order, userRoles, buttonNavigationItems).then((data)=> {
            if(data !== null) {
                button.id = data.id
                button.external = data.external;
                button['order'] = order;
                button['originalIconUrl'] = data.iconUrl;
                button["buttonAdded"] = Object.assign(data.buttonAdded);
                button["buttonAddedMessage"] = Object.assign(data.buttonAddedMessage);
                delete button.icon;
                delete data.buttonAdded;
                delete data.buttonAddedMessage;
                delete data.buttonUpdated;
                delete data.buttonNavigationItems;
                buttonNavigationItems.push(data);
                button['buttonNavigationItems'] = buttonNavigationItems;
                const payload = {
                    buttonNavigationItems,
                    button
                };
                dispatch({ type: 'CREATE_BUTTON_NAVIGATION_ITEM', payload });
            } else {
                button["buttonAdded"] = false;
                button["buttonAddedMessage"] = "Error when creating the navigation button";
                dispatch({ type: 'BUTTON_NAVIGATION_ERROR', button });
            }
        })
        .catch(error => {
            button["buttonAdded"] = false;
            button["buttonAddedMessage"] = "Error when creating the navigation button";
            dispatch({ type: 'BUTTON_NAVIGATION_ERROR', button });
        });   
   };
}

const handleButtonNavigationUpdateApiResponse = (dispatch, button, uploadIcon, /*uploadButtonImage,*/ buttonNavigationItems) => {
        if(typeof(button["buttonUpdated"]) !== 'undefined' && button["buttonUpdated"] === false){
            if(typeof(button["buttonUpdatedMessage"]) === 'undefined' || button["buttonUpdatedMessage"] === "") {
                button["buttonUpdatedMessage"] = "Error when updating the navigation button";
            }
            dispatch({ type: 'BUTTON_NAVIGATION_ERROR', button });
        } else {
            buttonNavigationItems.forEach((buttonNavigation) => {
                if(buttonNavigation.id === button.id) {
                    buttonNavigation.title = button.title;
                    buttonNavigation.description = button.description;
                    if(uploadIcon) {
                        buttonNavigation.iconName = button.iconName;
                        buttonNavigation.iconUrl = button.iconUrl;
                        button['originalIconUrl'] = button.iconUrl;
                        button['originalIconName'] = button.iconName;
                    }
                    buttonNavigation.link = button.link;
                }
            });
            const payload = {
                buttonNavigationItems,
                button
            };
            dispatch({ type: 'CREATE_BUTTON_NAVIGATION_ITEM', payload });
        }
}

const updateUserRolesNavigation = async (button, userRoles, userRolesButtonNavigation) => {
    if(typeof(userRolesButtonNavigation) !== 'undefined' && userRolesButtonNavigation) {
    let buttonAlreadyAdded = false;
    let rolesIds = [];
    userRoles.map(async userRole => {
        buttonAlreadyAdded = false;
        if(typeof(userRolesButtonNavigation[userRole.id]) !== 'undefined' && userRolesButtonNavigation[userRole.id] !== null) {
            Object.keys(userRolesButtonNavigation[userRole.id]).map(async key => {
                if(key === button.id) {
                    buttonAlreadyAdded = true;
                    if(!userRole.checked) {
                        await Services.General.deleteUserRoleButtonNavigationItem(userRole.id, button.id).catch(error => {
                            button["buttonUpdated"] = false;
                            return button;
                        });
                    }
                }
            });
        }
        if(userRole.checked) {
            rolesIds.push(userRole.id);
            if(!buttonAlreadyAdded) {
                console.log("id rol a agregar: ", userRole.id);
                await Services.General.addUserRoleButtonNavigationItem(userRole.id, button.id).catch(error => {
                    button["buttonUpdated"] = false;
                    return button;
                });
            }
        } 
    });
        button["buttonUpdated"] = true;
        button["rolesIds"] = rolesIds;
    } else {
        button["buttonUpdated"] = false;
    }
    return button;
}

const auxUpdateButtonNavigation = async (dispatch, button, uploadIcon, userRoles, userRolesButtonNavigation, buttonNavigationItems) => {
    let data = await Services.General.updateButtonNavigation(button);
    if(typeof(data) !== 'undefined' && data !== null && typeof(data["buttonUpdated"]) !== 'undefined' && data["buttonUpdated"] === true){
        let result = await updateUserRolesNavigation(button, userRoles, userRolesButtonNavigation);
        if(typeof(result) !== 'undefined' && result !== null && typeof(result["buttonUpdated"]) !== 'undefined' && result["buttonUpdated"] === true) {
                    //set rolesIds de result en button
            button["rolesIds"] = result["rolesIds"];
            if(uploadIcon) {
                let imagesResult = await Services.General.updateButtonNavigationImages(button, uploadIcon/*, uploadButtonImage*/);
                if(typeof(imagesResult) !== 'undefined' && imagesResult !== null && typeof(imagesResult["buttonUpdated"]) !== 'undefined') {
                    button["buttonUpdated"] = imagesResult["buttonUpdated"];
                    button["buttonUpdatedMessage"] = imagesResult["buttonUpdatedMessage"];
                    if(uploadIcon && typeof(imagesResult["iconName"]) !== 'undefined' && imagesResult["iconName"] !== null 
                    && typeof(imagesResult["iconUrl"]) !== 'undefined' && imagesResult["iconUrl"] !== null) {
                        button['iconName'] = imagesResult["iconName"];
                        button['iconUrl'] = imagesResult["iconUrl"];
                    }
                } else {
                    button["buttonUpdated"] = false;
                }
                handleButtonNavigationUpdateApiResponse(dispatch, button, uploadIcon, buttonNavigationItems);
            
            } else {
                button["buttonUpdated"] = true;
                button["buttonUpdatedMessage"] = 'Successfully updated button';
                handleButtonNavigationUpdateApiResponse(dispatch, button, uploadIcon, buttonNavigationItems);
            }
        } else {
            button["buttonUpdated"] = false;
            handleButtonNavigationUpdateApiResponse(dispatch, button);
        }
    } else{
        button["buttonUpdated"] = false;
        handleButtonNavigationUpdateApiResponse(dispatch, button);
    }
}

export const updateButtonNavigation = (button, uploadIcon, userRoles, userRolesButtonNavigation, buttonNavigationItems) => {
    return (dispatch, getState) => {
        auxUpdateButtonNavigation(dispatch, button, uploadIcon, userRoles, userRolesButtonNavigation, buttonNavigationItems);
   };
}

export const deleteButtonNavigation = (id) => {
    return (dispatch, getState) => {
        Services.General.deleteButtonNavigation(id).then((data)=> {
            if(data !== null) {
                if((typeof data.id) !== 'undefined' && data.id !== null) {
                    let buttonNavigation = { "id": data.id, "buttonDeleted": true, 
                                    "buttonDeletedMessage": "Successfully deleted button navigation" };
                    dispatch({ type: 'REMOVE_BUTTON_NAVIGATION', buttonNavigation });
                }
                else {
                    if((typeof data.message) !== 'undefined' && data.message) {
                        let buttonNavigation = { "buttonDeleted": false, "buttonDeletedMessage": data.message };
                        dispatch({ type: 'REMOVE_BUTTON_NAVIGATION_ERROR_MESSAGE', buttonNavigation });
                    }
                }
            } 
        });
   }; 
}