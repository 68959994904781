import React from 'react';

const TextArea = props => {
    return ( 
        <div className="form-group rb-label-input" style={props.style}>
            {props.required ? 
                <span className={props.labelClass} htmlFor="exampleInputEmail1">{props.labelName}<span className="ml-1" style={{color: 'red'}}>*</span></span>
                :
                <span className={props.labelClass} htmlFor="exampleInputEmail1">{props.labelName}</span>
            }
            <textarea
            style={props.style}
            name={props.inputName}
            className={props.inputClass}
            id={props.inputId}
            aria-describedby="emailHelp"
            placeholder={props.inputPlaceholder}
            defaultValue={props.defaultValue}
            value={props.value}
            readOnly={ props.readOnly }
            maxLength={props.maxLength}
            onChange={props.onChangeFunction} />
            
            <div className="text-danger">{props.errorMessage}</div>
        </div>
        );
    
}
 
export default TextArea;