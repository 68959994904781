import firebase from 'firebase/app';
import "firebase/database";
import {Services} from '../services';
import { PRINT_IN_CONSOLE } from '../../components/shared/constants/constants'

export class RolesApi {

    getNavigationItems() {
        return new Promise((resolve, reject) => {
            firebase.database()
                        .ref(`Navigation`)
                        .once("value")
                        .then(navItems => {
                            resolve(navItems);
                        })
                        .catch((error) => {
                            if(PRINT_IN_CONSOLE) {
                                console.log('Error getting the navigation items ', error.message);
                            }
                            resolve(null); 
                        });
        });
    }

    getRoleNavigationItems(roleId, role) {
        return new Promise((resolve, reject) => {
                firebase.database()
                .ref(`UserRoleNavigation/${roleId}`)
                .once("value")
                .then((navItems) => {
                    let navigation = [];
                    Object.values(navItems.val()).forEach(item=> {
                        firebase.database()
                        .ref(`Navigation/${item}`)
                        .once("value")
                        .then(navItem => {
                            navigation.push(navItem.val())
                            if(Object.values(navItems.val()).length === navigation.length) {
                                resolve({
                                    role: role,
                                    navigation: navigation.sort((a,b) => (String(a.order).toLowerCase() > String(b.order).toLowerCase()) ? 1 : ((String(b.order).toLowerCase() > String(a.order).toLowerCase()) ? -1 : 0))
                                });
                            }
                        })
                    })
                })
                .catch((error) => {
                    if(PRINT_IN_CONSOLE) {
                        console.log('Error getting the role navigation items ', error.message);
                    }
                    resolve(null); 
                });
        }); 
    }

    getRoles() {
        return new Promise((resolve, reject) => {
            firebase.database()
            .ref(`UserRoles`)
            .once("value")
            .then((roles) => {
                resolve(roles);
            });    
        });
    };

    getRole(id) {
        return new Promise((resolve, reject) => {
            firebase.database()
            .ref(`UserRoles/${id}`)
            .once("value")
            .then((role) => {
                resolve(role);
            })
            .catch((error) => {
                if(PRINT_IN_CONSOLE) {
                    console.log('error getting role with id: ' + id, error.message);
                }
                resolve(null);
            });    
        });
    }

    //por ahora no se usa la idea es hacer las validaciones para evitar duplicados a nivel de base de datos
    //Solo esta en profile falta roles, buttons, location, inventory, clients, projects, user types
    validateRoleName = async(name, id) => {
        const response = await this.getRoles();
        let isNameValid = true;
        response.val().forEach(role => {
            if(typeof(id) !== 'undefined') {
                if(role.name.toLowerCase() === name.toLowerCase() && role.id.toString() !== id.toString()) {
                    isNameValid = false;
                }
            } else {
                if(role.name.toLowerCase() === name.toLowerCase()) {
                    isNameValid = false;
                }
            }
        });
        return isNameValid;
    }

    createNewRole = async(name, description, navigationItems) => {
        let counter = 0;
        return new Promise((resolve, reject) => {
            const status = "Active";
            const roleKey = firebase.database().ref(`UserRoles`).push({name, description, status}).getKey();
            firebase.database()
            .ref(`UserRoles/${roleKey}`)
            .update({ 
                id: roleKey 
            })
            .then(()=> {
                navigationItems.forEach((navItem) => {
                    firebase.database().ref(`UserRoleNavigation/${roleKey}`).push(navItem.id)
                    .then(() => {
                        counter++;
                        if(navigationItems.length === counter) {
                            resolve({
                                id: roleKey,
                                name,
                                description,
                                status,
                                roleAdded: true,
                                roleUpdated: false,
                                roleAddedMessage: 'Successfully created role'
                            });
                        }
                    });
                });
                
            })
            .catch((error) => {
                if(PRINT_IN_CONSOLE) {
                    console.log('Error creating role:', error.message);
                }
                resolve(null); 
            })
        });
    };

    updateRole = (id, name, description, status, navigationItems) => {
        let navigationItemsCounter = 0;
        let userStatusId = 0;
        let usersIdWithRole = [];
        let usersCounter= 0;
        return new Promise((resolve, reject) => {
            firebase.database()
            .ref(`UserRoles/${id}`)
            .update({ 
                name: name,
                description: description,
                status: status
            })
            .then(()=> {
                // no se como hacer await
                firebase
                .database()
                .ref(`UserRoleNavigation/${id}`)
                .remove()
                .then(()=> { 
                    navigationItems.forEach((navItem) => {
                        firebase.database().ref(`UserRoleNavigation/${id}`).push(navItem.id)
                        .then(() => {
                            navigationItemsCounter++;
                            if(navigationItems.length === navigationItemsCounter && status === 'Inactive') {
                                Services.General.getUserStatusTypes().then(userStatusTypes => {
                                    userStatusTypes.val().forEach(userStatusType => {
                                        if(userStatusType.name === 'Inactive') {
                                            userStatusId = userStatusType.id;
                                        }
                                    });
                                    Services.Users.getUsers()
                                    .then((users) => {
                                        let usersResponse = users.val();
                                        for (var key in usersResponse) {
                                            if(usersResponse[key]['userRoleId'].toString() === id.toString()) {
                                                usersIdWithRole.push(usersResponse[key]['id']);
                                            }
                                        }
                                        if(usersIdWithRole.length > 0) {
                                            usersIdWithRole.forEach(userId => {
                                                firebase.database()
                                                .ref(`Users/${userId}`)
                                                .update({
                                                    userStatusTypeId: userStatusId
                                                })
                                                .then(()=> { 
                                                    usersCounter++;
                                                    if(usersCounter === usersIdWithRole.length) {
                                                        resolve({
                                                            id: id,
                                                            name,
                                                            description,
                                                            status,
                                                            roleAdded: false,
                                                            roleUpdated: true,
                                                            roleUpdatedMessage: 'Successfully updated role'
                                                        }); 
                                                    }
                                                })
                                                .catch((error) => {
                                                    if(PRINT_IN_CONSOLE) {
                                                        console.log('error updating user with id: ' + userId, error.message)
                                                    }
                                                    resolve(null);
                                                    });
                                            });
                                        } else {
                                            resolve({
                                                id: id,
                                                name,
                                                description,
                                                status,
                                                roleAdded: false,
                                                roleUpdated: true,
                                                roleUpdatedMessage: 'Successfully updated role'
                                            }); 
                                        }
                                    })
                                    .catch((error) => {
                                        if(PRINT_IN_CONSOLE) {
                                            console.log('error getting users', error.message);
                                        }
                                        resolve(null);
                                    });
                                })
                                .catch((error) => {
                                    if(PRINT_IN_CONSOLE) {
                                        console.log('error getting userStatusTypes', error.message);
                                    }
                                    resolve(null);
                                });   
                            } else {
                                if(navigationItems.length === navigationItemsCounter && status === 'Active') {
                                    resolve({
                                        id: id,
                                        name,
                                        description,
                                        status,
                                        roleAdded: false,
                                        roleUpdated: true,
                                        roleUpdatedMessage: 'Successfully updated role'
                                    }); 
                                }
                            }
                        })
                        .catch((error) => {
                            if(PRINT_IN_CONSOLE) {
                                console.log('error updating UserRoleNavigation with navItemId: ' 
                                + navItem.id, error.message);
                            }
                            resolve(null); 
                        });
                    });
                })
                .catch((error) => {
                    if(PRINT_IN_CONSOLE) {
                        console.log('Error removing the userRoleNavigation:', error.message);
                    }
                    resolve(null); 
                });
            })
            .catch((error) => {
                if(PRINT_IN_CONSOLE) {
                    console.log('Error updating role:', error.message);
                }
                resolve(null); 
            });
        });
    };
}