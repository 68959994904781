import React from 'react'
import ReadText from './readText';

const PersonalFormData = (props) => {
    if(!props.userInfo) return null;
    return (
        <React.Fragment>
            <ReadText labelText="First Name:" inputText={props.userInfo.firstName} />
            <ReadText labelText="Last Name:" inputText={props.userInfo.lastName} />
            <ReadText labelText="Email:" inputText={props.userInfo.email} />
            <ReadText labelText="Email (personal):" inputText={props.userInfo.personalEmail} />
            <ReadText labelText="Status:" inputText={props.userInfo.firstName} />
            <ReadText labelText="Location:" inputText={props.location} />
            <ReadText labelText="Birth date:" inputText={props.userInfo.birthDate} />
            {/* {props.info === "public" ? "" : <ReadText labelText="Address:" inputText={props.userInfo.homeAddress} />} */}
            <ReadText labelText="Mobile:" inputText={props.userInfo.cellularNumber} />
            <ReadText labelText={props.userInfo.identificationType + ":"} inputText={props.userInfo.identificationNumber} />
            <ReadText labelText="Nationality:" inputText={props.userInfo.nationality} />
        </React.Fragment>
    )
}

export default PersonalFormData;
