import firebase from 'firebase/app';
import "firebase/database";
import {Services} from '../services';
import { PRINT_IN_CONSOLE } from '../../components/shared/constants/constants'

export class UserTypesApi {

    getUserTypes() {
        return new Promise((resolve, reject) => {
            firebase.database()
            .ref(`UserTypes`)
            .once("value")
            .then((userTypes) => {
                resolve(userTypes);
            });    
        });
    };

    createNewUserType(name, description) {
        return new Promise((resolve, reject) => {
            const userTypeKey = firebase.database().ref(`UserTypes`).push({name, description}).getKey();
            firebase.database()
            .ref(`UserTypes/${userTypeKey}`)
            .update({ 
                id: userTypeKey 
            })
            .then(()=> {
                resolve({
                    id: userTypeKey,
                    name,
                    description,
                    userTypeAdded: true,
                    userTypeUpdated: false,
                    userTypeAddedMessage: 'Successfully created user type'
                }); 
            })
            .catch((error) => {
                if(PRINT_IN_CONSOLE) {
                    console.log('Error creating user type:', error.message);
                }
                resolve(null); 
            })
        });
    };

    updateUserType(id, name, description) {
        return new Promise((resolve, reject) => {
            firebase.database()
            .ref(`UserTypes/${id}`)
            .update({ 
                name: name,
                description: description 
            })
            .then(()=> { 
                resolve({
                    id,
                    name,
                    description,
                    userTypeAdded: false,
                    userTypeUpdated: true,
                    userTypeUpdatedMessage: 'Successfully updated user type'
                }); 
            })
            .catch((error) => {
                if(PRINT_IN_CONSOLE) {
                    console.log('Error updating user type:', error.message);
                }
                resolve(null); 
            })
        });
    };

    deleteUserType(id) {
        return new Promise((resolve, reject) => {
            let canDeleteUserType = true;
            Services.Users.getUsers().then(users => {
                let usersResponse = users.val();
                for (var key in usersResponse) {
                    if(usersResponse[key]['userTypeId'].toString() === id.toString()) {
                        canDeleteUserType = false;
                    }
                }
                if(canDeleteUserType) {
                    firebase
                    .database()
                    .ref(`UserTypes/${id}`)
                    .remove()
                    .then(()=> { 
                        let response = { id };
                        resolve(response);
                    })
                    .catch((error) => {
                        if(PRINT_IN_CONSOLE) {
                            console.log('Error removing user type:', error.message);
                        }
                        resolve({ 'message': 'Error removing user type'});
                    });  
                } else {
                    let response = { 'message': 'The user type is being used by some users. Please modify them before re trying' };
                    resolve(response);
                }
            })
            .catch((error) => {
                if(PRINT_IN_CONSOLE) {
                    console.log('error getting users to control the user types', error.message);
                }
                resolve({ 'message': 'Error removing user type: can not get users to control the user types'});
            });
                        
        });
    }

}