import React, { useState } from 'react';
import RequiredInputLabel from '../../shared/required-input-label';
import './../../../App.css';
import { isTextValid } from '../../../utilities/string.utilities';
import { getPropertyValue } from './userUtilities';
import { Services } from '../../../api/services';
import { Button } from '@material-ui/core';
import _ from 'lodash';

const IndependentFormData = ({ user, title, notify, next, nextText="Next Step", profile, handleCloseNotification,notRequired }) => {
  const labelClass = 'mb-2 rb-textbox-style';
  const inputClass = 'form-control mb-2 rb-textbox-style';
  const [error, setError] = useState({});
  const [state, setState] = useState({
    action: '',

    specificData: {
      bpsCertificate: {
        url: '',
        fileName: '',
      },
      dgiCertificate: {
        url: '',
        fileName: '',
      },
      userId: '',
      companyIdentifier: '',
      companyName: '',
      companyNumber: '',
    },
  });

  const getSpecificFormData = async () => {
    const specificData = await Services.Users.getUserSpecificFormData(user.id)
      .then((data) => {
        data = data.val();
        if (getPropertyValue(data) !== '') {
          if (getPropertyValue(data.bpsCertificate) !== '') {
            data.bpsCertificate.originalName = data.bpsCertificate.fileName;
            data.bpsCertificate.originalUrl = data.bpsCertificate.url;
          }
          if (getPropertyValue(data.dgiCertificate) !== '') {
            data.dgiCertificate.originalName = data.dgiCertificate.fileName;
            data.dgiCertificate.originalUrl = data.dgiCertificate.url;
          }
        }
        return data;
      })
      .catch((_) => undefined);
    return specificData;
  };

  React.useEffect(() => {
    getSpecificFormData().then((specificFormData) => {
      if (getPropertyValue(specificFormData) !== '') {
        setState({
          ...state,
          specificData: {
            ...state.specificData,
            bpsCertificate: specificFormData.bpsCertificate,
            companyIdentifier: specificFormData.companyIdentifier,
            companyName: specificFormData.companyName,
            companyNumber: specificFormData.companyNumber,
            dgiCertificate: specificFormData.dgiCertificate,
          },
        });
      } else {
        setState({
          ...state,
          specificData: {
            ...state.specificData,
            userId: user.id,
          },
        });
      }
    });
  }, [user.id]);

  const updateSpecificData = async (specificData) => {
    if (getPropertyValue(specificData) !== '') {
      if (specificData.bpsCertificate) {
        specificData.bpsCertificate.originalName =
          specificData.bpsCertificate.fileName;
        specificData.bpsCertificate.originalUrl =
          specificData.bpsCertificate.url;
      }
      if (specificData.dgiCertificate) {
        specificData.dgiCertificate.originalName =
          specificData.dgiCertificate.fileName;
        specificData.dgiCertificate.originalUrl =
          specificData.dgiCertificate.url;
      }
      await setState({
        ...state,
        specificData: {
          ...state.specificData,
          bpsCertificate: specificData.bpsCertificate,
          companyIdentifier: specificData.companyIdentifier,
          companyName: specificData.companyName,
          companyNumber: specificData.companyNumber,
          dgiCertificate: specificData.dgiCertificate,
        },
      });
    }
  };

  const onSubmit = async (data) => {
    handleCloseNotification();
    setError({});
    try {
      let specificData = state.specificData;
      if(profile)
        isFormDataValid(specificData);

      specificData.userId = user.id;
      delete specificData.url;
      delete specificData.originalName;
      delete specificData.fileName;
      delete specificData.file;
      let registerFormResult;
      const dgiCertificateOriginalName =
        specificData.dgiCertificate.originalName;
      const bpsCertificateOriginalName =
        specificData.bpsCertificate.originalName;
      if (
        typeof specificData !== 'undefined' &&
        specificData !== null &&
        typeof state.bpsCertificateFile !== 'undefined' &&
        state.bpsCertificateFile !== null
      ) {
        if (!state.processing) await setState({ ...state, processing: true });
        const bpsResponse = await Services.Users.uploadImageOnStorage(
          user.id,
          state.bpsCertificateFile,
          bpsCertificateOriginalName
        );
        if (bpsResponse.success) {
          delete specificData.bpsCertificate.originalName;
          specificData.bpsCertificate.url = bpsResponse.downloadUrl;
        } else {
          registerFormResult = {
            success: false,
            message:
              'There was an error trying to upload the bps certificate. Please try again',
          };
          notify(
            registerFormResult.message,
            registerFormResult.success === true ? 'success' : 'danger'
          );
        }
      }
      if (
        typeof specificData !== 'undefined' &&
        specificData !== null &&
        typeof state.dgiCertificateFile !== 'undefined' &&
        state.dgiCertificateFile !== null
      ) {
        if (!state.processing) await setState({ ...state, processing: true });
        const dgiResponse = await Services.Users.uploadImageOnStorage(
          user.id,
          state.dgiCertificateFile,
          dgiCertificateOriginalName
        );
        if (dgiResponse.success) {
          delete specificData.dgiCertificate.originalName;
          specificData.dgiCertificate.url = dgiResponse.downloadUrl;
        } else {
          registerFormResult = {
            success: false,
            message:
              'There was an error trying to upload the dgi certificate. Please try again',
          };
          notify(
            registerFormResult.message,
            registerFormResult.success === true ? 'success' : 'danger'
          );
        }
      }
      await Services.Users.getUserSpecificFormData(user.id)
        .then(async (specificFormData) => {
          if (
            typeof specificFormData !== 'undefined' &&
            specificFormData !== null &&
            typeof specificFormData.val() !== 'undefined' &&
            specificFormData.val() !== null
          ) {
            await Services.Users.updateUserSpecificFormData(specificData)
              .then(async () => {
                registerFormResult = {
                  success: true,
                  message: 'The information was successfully updated',
                  step: 3,
                  specificFormData: specificData,
                };
                notify(
                  registerFormResult.message,
                  registerFormResult.success === true ? 'success' : 'danger'
                );
                updateSpecificData(specificData);
              })
              .catch((error) => {
                console.log('error updating specific form data', error);
                registerFormResult = {
                  success: false,
                  message:
                    'An error ocurred when trying to update the information. Please try again later',
                  step: 3,
                };
                notify(
                  registerFormResult.message,
                  registerFormResult.success === true ? 'success' : 'danger'
                );
              });
          } else {
            await Services.Users.postUserSpecificFormData(specificData)
              .then(async () => {
                registerFormResult = {
                  success: true,
                  message: 'The information was successfully updated',
                  step: 3,
                  specificFormData: specificData,
                };
                notify(
                  registerFormResult.message,
                  registerFormResult.success === true ? 'success' : 'danger'
                );
                updateSpecificData(specificData);
              })
              .catch((error) => {
                console.log('error saving specific form data', error);
                registerFormResult = {
                  success: false,
                  message:
                    'An error ocurred when trying to save the information. Please try again later',
                  step: 3,
                };
                notify(
                  registerFormResult.message,
                  registerFormResult.success === true ? 'success' : 'danger'
                );
              });
          }
        })
        .catch((error) => {
          console.log('error getting specific form data', error);
          registerFormResult = {
            success: false,
            message:
              'An error ocurred when trying to save the information. Please try again later',
            step: 3,
          };
          notify(
            registerFormResult.message,
            registerFormResult.success === true ? 'success' : 'danger'
          );
        });

        !profile && next();
    } catch (err) {
      await setError(err);
      throw err;
    }
  };

  const onChangeBpsCertificate = async (e) => {
    const files = e.target.files;
    if (files.length > 0) {
      const file = files[0];
      let { specificData } = state;
      if (typeof specificData.bpsCertificate === 'undefined')
        specificData.bpsCertificate = {};
      specificData.bpsCertificate.url = '';
      specificData.bpsCertificate.fileName = file.name;
      await setState({
        ...state,
        specificData,
        bpsCertificateFile: file,
      });
    }
  };

  const onChangeDgiCertificate = async (e) => {
    const files = e.target.files;
    if (files.length > 0) {
      const file = files[0];
      let { specificData } = state;
      if (typeof specificData.dgiCertificate === 'undefined')
        specificData.dgiCertificate = {};
      specificData.dgiCertificate.url = '';
      specificData.dgiCertificate.fileName = file.name;
      //specificData.dgiCertificate.file = file;
      await setState({
        ...state,
        specificData,
        dgiCertificateFile: file,
      });
    }
  };

  const isFormDataValid = (data) => {
    let error = {};
    if (isTextValid(data.companyName) === false) {
      error = { ...error, ['companyName']: 'Company name is required' };
    }

    if (isTextValid(data.companyNumber) === false) {
      error = { ...error, ['companyNumber']: 'Company number is required' };
    }

    if (isTextValid(data.companyIdentifier) === false) {
      error = {
        ...error,
        ['companyIdentifier']: 'Company identifier is required',
      };
    }

    if (
      getPropertyValue(data.bpsCertificate) === '' ||
      getPropertyValue(data.bpsCertificate.fileName) === ''
    ) {
      error = { ...error, ['bpsCertificate']: 'Bps certificate is required' };
    }

    if (
      getPropertyValue(data.dgiCertificate) === '' ||
      getPropertyValue(data.dgiCertificate.fileName) === ''
    ) {
      error = { ...error, ['dgiCertificate']: 'Dgi certificate is required' };
    }

    if (!_.isEmpty(error)) throw error;
  };

  return (
    <React.Fragment>
      <h2 className="onboarding-title">{title}</h2>
      <div className="container" style={{ display: 'flex', flexWrap: 'wrap' }}>
        <div className="col-12 col-lg-6">
          <RequiredInputLabel
            labelName="Company name:"
            labelClass={labelClass}
            inputClass={inputClass}
            //refProp={register}
            inputName="companyName"
            value={state.specificData.companyName}
            errorMessage={error.companyName}
            notRequired={notRequired}
            onChangeFunction={(event) => {
              setState({
                ...state,
                specificData: {
                  ...state.specificData,
                  companyName: event.target.value,
                },
              });
            }}
          />
        </div>
        <div className="col-12 col-lg-6">
          <RequiredInputLabel
            labelName="Company number:"
            labelClass={labelClass}
            inputClass={inputClass}
            //refProp={register}
            inputName="companyNumber"
            inputType="number"
            value={state.specificData.companyNumber}
            errorMessage={error.companyNumber}
            notRequired={notRequired}
            onChangeFunction={(event) => {
              setState({
                ...state,
                specificData: {
                  ...state.specificData,
                  companyNumber: event.target.value,
                },
              });
            }}
          />
        </div>
        <div className="col-12 col-lg-6">
          <RequiredInputLabel
            labelName="Company identifier (RUT):"
            labelClass={labelClass}
            inputClass={inputClass}
            inputName="companyIdentifier"
            inputType="number"
            value={state.specificData.companyIdentifier}
            errorMessage={error.companyIdentifier}
            notRequired={notRequired}
            onChangeFunction={(event) => {
              setState({
                ...state,
                specificData: {
                  ...state.specificData,
                  companyIdentifier: event.target.value,
                },
              });
            }}
          />
        </div>
        <div className="col-12 col-lg-6 d-none d-lg-table-cell"></div>
        <div className="col-12 col-lg-6">
          <br className="d-none d-lg-block" />
          <Button
            variant="contained"
            component="label"
            className="upload-button"
          >
            <span className="upload-button-text">Upload BPS Certificate</span>
            <i class="fas fa-paperclip"></i>
            <input
              type="file"
              accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps"
              style={{ display: 'none' }}
              onChange={onChangeBpsCertificate}
            />
          </Button>
          <a
            href={state.specificData.bpsCertificate && state.specificData.bpsCertificate.url}
            className="certificate-link"
            target="_blank"
          >
            {typeof state.specificData.bpsCertificate !== 'undefined' &&
            state.specificData.bpsCertificate !== null &&
            typeof state.specificData.bpsCertificate.fileName !== 'undefined' &&
            state.specificData.bpsCertificate.fileName !== null
              ? state.specificData.bpsCertificate.fileName
              : ''}
          </a>

          {state.bpsCertificateError !== '' && (
            <div className="text-danger">{error.bpsCertificate}</div>
          )}
        </div>
        <div className="col-12 col-lg-6">
          <br />
          <Button
            variant="contained"
            component="label"
            className="upload-button"
          >
            <span className="upload-button-text">Upload DGI Certificate</span>
            <i class="fas fa-paperclip"></i>
            <input
              type="file"
              accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps"
              style={{ display: 'none' }}
              onChange={onChangeDgiCertificate}
            />
          </Button>
          <a
            href={state.specificData.dgiCertificate && state.specificData.dgiCertificate.url}
            className="certificate-link"
            target="_blank"
          >
            {typeof state.specificData.dgiCertificate !== 'undefined' &&
            state.specificData.dgiCertificate !== null &&
            typeof state.specificData.dgiCertificate.fileName !== 'undefined' &&
            state.specificData.dgiCertificate.fileName !== null
              ? state.specificData.dgiCertificate.fileName
              : ''}
          </a>

          {state.dgiCertificateError !== '' && (
            <div className="text-danger">{error.dgiCertificate}</div>
          )}
        </div>
        <div className="col-12 text-center">
          <button type="button" onClick={onSubmit} className="next-button">
            {nextText}{!profile && <i class="fas fa-arrow-right next-button-icon"></i>}
          </button>
        </div>
      </div>
    </React.Fragment>
  );
};

export default IndependentFormData;
