import {Services} from '../api/services';
import _ from 'lodash';

export const setInventoryItems = (inventoryItems, userId) => {
    return (dispatch, getState) => {
        Services.InventoryItems.setInventoryItems(userId).then((data)=>{
            if(data.val() !== null) {
                const response = data.val();
                for (var key in response) {
                    inventoryItems.push(response[key]);
                }
                
                dispatch({ type: 'SET_INVENTORY_ITEMS', inventoryItems });
            } else {
                inventoryItems = [];
                dispatch({ type: '', inventoryItems });
            } 
        })
        .catch((error)=>{
            alert("Error while getting the inventory items!") 
        });
    }
}

export const updateInventoryItems = (inventoryItems) => {
    return (dispatch, getState) => {
        dispatch({ type: 'SET_INVENTORY_ITEMS', inventoryItems });
    };
}

export const setSelectedItem = (selectedItemId) => {
    return (dispatch, getState) => {
        dispatch({ type: 'SET_SELECTED_ITEM', selectedItemId });      
   };
}

export const setInventoryItemUser = (value, inventoryItems) => {
    return (dispatch, getState) => {
        dispatch({ type: 'SET_ITEM_USER', payload: { inventoryItems, item: value }});
    }
}

export const updateInventoryItemUser = (item, userId, userFullName, inventoryItems) => {
    return (dispatch, getState) => {
        Services.InventoryItems.updateInventoryItemUser(item.id, userId)
            .then(data => {
                if(data !== null) {
                    inventoryItems.forEach((itemToUpdate) => {
                        if(itemToUpdate.id === item.id) {
                            itemToUpdate.userId = userId;
                            itemToUpdate.user = userFullName;
                        }
                    });
                    item["userId"] = userId;
                    item["itemUpdated"] = true;
                    dispatch({ type: 'SET_ITEM_USER', payload: { inventoryItems, item }});
                } else {
                    item["itemUpdated"] = false;
                    item["itemUpdatedMessage"] = "There was an error when updating the item";
                    dispatch({ type: 'SET_ITEM_ERROR', item});
                }
            })
    }
}

export const updateItem = (item, selectedUser, inventoryItems) => {
    let isNewItem = false;
    if((typeof item.id) === 'undefined' || item.id === null || item.id === "") {
        isNewItem = true;
    }
    if((typeof selectedUser) === 'undefined' || selectedUser === null || _.isEmpty(selectedUser)) 
        selectedUser = { firstName: "NOT", lastName: "ASSIGNED", userStatusTypeId: "1", id: "-1" };
    return (dispatch, getState) => {
        Services.InventoryItems.updateItem(item, selectedUser, isNewItem)
            .then((data)=> {
                if(data !== null) {
                    /*
                    itemAddMessage: this.props.selectedItem.itemAdded ? "Item has been added." : this.props.selectedItem.itemAddedMessage,
        itemUpdatedMessage: this.props.selectedItem.itemUpdated ? "Item has been updated." : this.props.selectedItem.itemUpdatedMessage,
                    */
                    if(isNewItem) {
                        item.id = data;
                        item.userId = selectedUser.id;
                        item.user = selectedUser.firstName + " " + selectedUser.lastName;
                        item["isUserActive"] = (selectedUser.userStatusTypeId.toString() === "1") ? true : false;
                        //inventoryItems.push(item);
                        const jsonValues = Object.values(item);
                        const jsonKeys = Object.keys(item);
                        let itemToAdd = {};
                        jsonKeys.map(function(key, index) {
                            return itemToAdd[key] = jsonValues[index];
                        });
                        //let itemToAdd = item;
                        inventoryItems.push(itemToAdd);
                        item["itemAdded"] = true;
                        dispatch({ type: 'SET_ITEM', payload: { inventoryItems, item: item }});
                    } else {
                        item.user = selectedUser.firstName + " " + selectedUser.lastName;
                        item.userId = selectedUser.id;
                        inventoryItems.forEach((itemToUpdate) => {
                            if(itemToUpdate.id === item.id) {
                                itemToUpdate.brand = item.brand;
                                itemToUpdate.codeId = item.codeId;
                                itemToUpdate.detail = item.detail;
                                itemToUpdate.lastUpdate = item.lastUpdate;
                                itemToUpdate.locationId = item.locationId;
                                itemToUpdate.model = item.model;
                                itemToUpdate.type = item.type;
                                itemToUpdate.user = item.user;
                                itemToUpdate.userId = item.userId;
                                itemToUpdate['locationName'] = item.locationName;
                            }
                        });
                        item["itemUpdated"] = true;
                        dispatch({ type: 'SET_ITEM', payload: { inventoryItems, item }});
                    }
                    
                } else {
                    if(isNewItem) {
                        item["itemAdded"] = false;
                        item["itemAddedMessage"] = "There was an error when creating the item";
                    } else {
                        item["itemUpdated"] = false;
                        item["itemUpdatedMessage"] = "There was an error when updating the item";
                    }
                    dispatch({ type: 'SET_ITEM_ERROR', item});
                }
         })
         .catch(error => {
            if(isNewItem) {
                item["itemAdded"] = false;
                item["itemAddedMessage"] = "There was an error when creating the item";
            } else {
                item["itemUpdated"] = false;
                item["itemUpdatedMessage"] = "There was an error when updating the item";
            }
            dispatch({ type: 'SET_ITEM_ERROR', item});
         });
   };    
};

export const deleteItem = (id) => {
    return (dispatch, getState) => {
        Services.InventoryItems.deleteItem(id).then((data)=> {
            if(data !== null) {
                if((typeof data.id) !== 'undefined' && data.id !== null) {
                    let inventoryItem = { "id": data.id, "itemDeleted": true, 
                                    "itemDeletedMessage": "Successfully deleted inventory item" };
                    dispatch({ type: 'REMOVE_ITEM', inventoryItem });
                }
                else {
                    if((typeof data.message) !== 'undefined' && data.message) {
                        let inventoryItem = { "itemDeleted": false, "itemDeletedMessage": data.message };
                        dispatch({ type: 'REMOVE_ITEM_ERROR_MESSAGE', inventoryItem });
                    }
                }
                //dispatch({ type: 'REMOVE_ITEM', id });
            } 
        });
   };    
};

