import React, { Component } from 'react';
import onClickOutside from 'react-onclickoutside';
import Textbox from '../shared/textbox';
import { connect } from 'react-redux';
import {
  createNewRole,
  setRoles,
  updateRole,
  getRoleNavigationItems,
  getNavigationItems,
  setSelectedRole,
} from '../../actions/role-actions';
import { setUsers } from '../../actions/user-actions';
import { setUserStatusTypes } from '../../actions/general-actions';
import Spinner from 'react-bootstrap/Spinner';
import { isTextValid } from '../../utilities/string.utilities';
import { LocalModal } from '../common';

class RolesModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      roles: [],
      rolesById: [],
      navigationItems: [],
      usersWithSelectedRole: [],
      statusTypes: ['Active', 'Inactive'],
      statusTypeButtonText: 'Select',
      selectedStatusType: '',
      role: {
        name: '',
        description: '',
      },
      selectedRoleId: '',
      nameError: '',
      descriptionError: '',
      navigationItemsError: '',
      baseAlertClass: 'text-center alert alert-dismissible fade show alert-',
      alertClass: '',
      showCreateRoleMessage: false,
      roleAddedMessage: '',
      roleUpdatedMessage: '',
      processing: false,
      statusTypeError: '',
      showWarnMessage: false,
      warnClass: '',
      usersWithSelectedRoleLoaded: false,
      notification:''
    };
  }

  componentDidMount() {
    const { roles } = this.state;
    this.props.setRoles(roles);
    this.props.getNavigationItems();
  }

  handleTextboxChange = async (e) => {
    await this.setState({
      ...this.state,
      role: {
        ...this.state.role,
        [e.target.name]: e.currentTarget.value,
      },
      alertClass: '',
      showCreateRoleMessage: false,
    });
  };

  handleRoleSelected = (event) => {
    let roleId = parseInt(event.target.value);
    if (roleId > 0) {
      const selectedRole = this.props.roles.find(function(role) {
        return role.id === roleId;
      });
      this.setState({
        ...this.state,
        role: selectedRole,
      });
    } else {
      this.setState({
        ...this.state,
        role: {
          name: '',
          description: '',
        },
      });
    }
  };

  componentDidUpdate = async (prevProps) => {
    const { selectedRoleId } = this.props;
    let usersWithSelectedRole = [];
    let userStatusTypes = this.props.userStatusTypes;
    let idStatusTypeActive = '';
    let usersWithSelectedRoleLoaded = false;
    if (
      prevProps.selectedRoleId !== selectedRoleId &&
      typeof selectedRoleId !== 'undefined'
    ) {
      if (
        typeof userStatusTypes !== 'undefined' &&
        userStatusTypes &&
        userStatusTypes.length > 0
      ) {
        usersWithSelectedRole = [];
        if (this.props.users && this.props.users.length > 0) {
          idStatusTypeActive = '';
          userStatusTypes.forEach((userStatusType) => {
            if (userStatusType.name.toLowerCase() === 'active') {
              idStatusTypeActive = userStatusType.id;
            }
          });
          this.props.users.forEach((user) => {
            if (
              user.userRoleId.toString() === selectedRoleId.toString() &&
              idStatusTypeActive.toString() === user.userStatusTypeId.toString()
            ) {
              usersWithSelectedRole.push(user);
            }
          });
          usersWithSelectedRoleLoaded = true;
        } else {
          //get users from database
          this.props.setUsers(usersWithSelectedRole);
        }
      } else {
        userStatusTypes = [];
        this.props.setUserStatusTypes(userStatusTypes);
        if (
          (typeof this.props.users !== 'undefined' && !this.props.users) ||
          this.props.users.length === 0
        ) {
          this.props.setUsers(usersWithSelectedRole);
        }
      }
      const selectedRole = this.props.roles.find(function(role) {
        return role.id.toString() === selectedRoleId.toString();
      });

      if (typeof selectedRole !== 'undefined' && selectedRole) {
        this.props.getRoleNavigationItems(selectedRole.id);
      }
      await this.setState({
        ...this.state,
        role: {
          name: selectedRole ? selectedRole.name : '',
          description: selectedRole ? selectedRole.description : '',
        },
        selectedRoleId: selectedRoleId ? selectedRoleId : '',
        statusTypeButtonText: selectedRole ? selectedRole.status : 'Select',
        selectedStatusType: selectedRole ? selectedRole.status : '',
        usersWithSelectedRole: usersWithSelectedRole,
        usersWithSelectedRoleLoaded: usersWithSelectedRoleLoaded,
      });
    }
    if (prevProps.role !== this.props.role) {
      this.props.setRoles(this.state.roles);
      this.setState({
        ...this.state,
        role: {
          name: this.props.role.roleAdded ? '' : this.state.role.name,
          description: this.props.role.roleAdded
            ? ''
            : this.state.role.description,
          statusTypeButtonText: this.props.role.roleAdded
            ? 'Select'
            : this.state.statusTypeButtonText,
          selectedStatusType: this.props.role.roleAdded
            ? ''
            : this.state.selectedStatusType,
        },
        roles: this.props.roles,
        roleAddedMessage: this.props.role.roleAddedMessage
          ? this.props.role.roleAddedMessage
          : '', //? this.state.location.name + " has been added." : "",//this.props.location.locationAddedMessage,
        roleUpdatedMessage: this.props.role.roleUpdatedMessage
          ? this.props.role.roleUpdatedMessage
          : '', //? "The location has been updated." : "",//this.props.location.locationUpdatedMessage,
        alertClass:
          this.props.role.roleAdded || this.props.role.roleUpdated
            ? this.state.baseAlertClass + 'success'
            : this.state.baseAlertClass + 'danger',
        showCreateRoleMessage: true,
        processing: false,
      });
    }
    if (prevProps.roleNavigationItems !== this.props.roleNavigationItems) {
      //recorrer props.navigationItems y checkear las roleNavigationItems
      let navigationItems = this.props.navigationItems;
      navigationItems.forEach((navItem) => {
        navItem.checked = navItem.checked || false;
        this.props.roleNavigationItems.forEach((roleNavItem) => {
          if (navItem.id === roleNavItem.id) {
            navItem.checked = true;
          }
        });
      });
      this.setState({
        ...this.state,
        navigationItems: navigationItems,
      });
    }
    if (prevProps.navigationItems !== this.props.navigationItems) {
      let navigationItems = this.props.navigationItems;
      navigationItems.forEach((navItem) => {
        navItem.checked = false;
        if (navItem.availableForAllRoles) {
          navItem.checked = true;
        }
      });
      this.setState({
        ...this.state,
        navigationItems: navigationItems,
      });
    }
    if (
      this.props.users &&
      this.props.users.length > 0 &&
      this.state.usersWithSelectedRole.length === 0 &&
      typeof selectedRoleId !== 'undefined' &&
      selectedRoleId &&
      typeof userStatusTypes !== 'undefined' &&
      userStatusTypes &&
      userStatusTypes.length > 0 &&
      usersWithSelectedRoleLoaded
    ) {
      usersWithSelectedRole = [];
      idStatusTypeActive = '';
      userStatusTypes.forEach((userStatusType) => {
        if (userStatusType.name.toLowerCase() === 'active') {
          idStatusTypeActive = userStatusType.id;
        }
      });
      this.props.users.forEach((user) => {
        if (
          user.userRoleId.toString() === selectedRoleId.toString() &&
          idStatusTypeActive.toString() === user.userStatusTypeId.toString()
        ) {
          usersWithSelectedRole.push(user);
        }
      });
      this.setState({
        ...this.state,
        usersWithSelectedRole: usersWithSelectedRole,
        usersWithSelectedRoleLoaded: true,
      });
    }
  };

  handleCreateRole = async () => {
   
    const { name, description } = this.state.role;
    const { navigationItems } = this.state;
    const response = await this.validateRoleForm(
      name,
      description,
      navigationItems
    );

    if (response.formValid) {
      await this.props.createNewRole(
        this.state.role.name,
        this.state.role.description,
        response.navigationItems,
        this.props.roles
      );

      await this.setState({notification:"Successfully created role!"})
    }
  };

  validateUpdateRoleForm = async (name, description, navigationItems) => {
    let response = await this.validateRoleForm(
      name,
      description,
      navigationItems
    );
    if (response.formValid) {
      await this.setState({
        ...this.state,
        ...this.state.role,
        statusTypeError:
          this.state.selectedStatusType === 'Active' ||
          this.state.selectedStatusType === 'Inactive'
            ? ''
            : 'Status is required',
      });
      response.formValid = this.state.statusTypeError === '';
    }
    return response;
  };

  handleUpdateRole = async () => {
   
    const { name, description } = this.state.role;
    const { navigationItems } = this.state;
    const response = await this.validateUpdateRoleForm(
      name,
      description,
      navigationItems
    );

    if (response.formValid) {
      this.setState({ processing: true, showWarnMessage: false });
      this.props.updateRole(
        this.props.selectedRoleId,
        this.state.role.name,
        this.state.role.description,
        this.state.selectedStatusType,
        response.navigationItems,
        this.props.roles
      );

      await this.setState({notification:"Successfully updated role!", alertClass:this.state.baseAlertClass+"success"});
    }
  };

  handleCloseNotification = () => {
    this.setState({
      roleAddedMessage: '',
      roleUpdatedMessage: '',
      showCreateRoleMessage: false,
    });
  };

  handleCloseAlert = () => {
    let { navigationItems } = this.state;
    navigationItems.forEach((navItem) => {
      if (!navItem.availableForAllRoles) {
        navItem.checked = false;
      }
    });
    this.props.setSelectedRole(undefined);
    this.setState({
      ...this.state,
      nameError: '',
      descriptionError: '',
      statusTypeError: '',
      navigationItemsError: '',
      roleAddedMessage: '',
      roleUpdatedMessage: '',
      showCreateRoleMessage: false,
      navigationItems: navigationItems,
      usersWithSelectedRole: [],
      showWarnMessage: false,
      warnClass: '',
      usersWithSelectedRoleLoaded: false,
    });
  };

  validateRoleForm = async (name, description, navigationItems) => {
    let formValid = false;
    let navItems = [];
    const { roles, selectedRoleId } = this.props;
    let isRoleValid = true;
    roles.forEach((role) => {
      if (role.name.toLowerCase().trim() === name.toLowerCase().trim()) {
        if (typeof selectedRoleId === 'undefined' || !selectedRoleId) {
          isRoleValid = false;
        } else {
          if (role.id.toString() !== selectedRoleId.toString()) {
            isRoleValid = false;
          }
        }
      }
    });

    navigationItems.forEach((navItem) => {
      if (navItem.checked) {
        navItems.push(navItem);
      }
    });
    await this.setState({
      ...this.state,
      nameError:
        name && isTextValid(name)
          ? !isRoleValid
            ? 'Already exists a role with this name'
            : ''
          : 'Name is required',
      descriptionError:
        description && isTextValid(description)
          ? ''
          : 'Description is required',
      navigationItemsError:
        navItems.length > 0 ? '' : 'You have to select one or more sections',
      roleAddedMessage: '',
      roleUpdatedMessage: '',
      showCreateRoleMessage: false,
    });
    formValid =
      this.state.nameError === '' &&
      this.state.descriptionError === '' &&
      this.state.navigationItemsError === '';
    const response = { formValid: formValid, navigationItems: navItems };
    return response;
  };

  handleCheckboxChange = (e) => {};

  handleCheckboxClick = (e) => {
    const checked = e.target.checked;
    const navItemId = e.target.value;
    let stateChanged = false;
    let navigationItems = JSON.parse(
      JSON.stringify(this.state.navigationItems)
    );
    navigationItems.forEach((item) => {
      if (
        typeof item !== 'undefined' &&
        item.id === navItemId &&
        item.checked !== checked
      ) {
        item.checked = checked;
        stateChanged = true;
      }
    });
    if (stateChanged) {
      this.setState({
        navigationItems: navigationItems,
      });
    }
  };

  handleStatusChange = (e) => {
    if (
      e.target.value === 'Inactive' &&
      this.state.usersWithSelectedRole.length > 0
    ) {
      this.setState({
        warnClass: this.state.baseAlertClass + 'warning',
        showWarnMessage: true,
        selectedStatusType: e.target.value,
        statusTypeButtonText: e.target.value,
      });
    } else {
      this.setState({
        selectedStatusType: e.target.value,
        statusTypeButtonText: e.target.value,
        showWarnMessage: false,
      });
    }
  };

  handleClickOutside = () => {
    this.handleCloseAlert();
  };

  render() {
    const {
      modalId,
      modalTitleId,
      selectedRoleId /*, navigationItems*/,
    } = this.props;
    const isNewRole = selectedRoleId === '';
    const { navigationItems, statusTypes } = this.state;

    return (
      <LocalModal
        modalName={modalId}
        title={modalTitleId}
        processing={this.state.processing}
        icon="user-lock"
        onConfirm={() =>
          isNewRole ? this.handleCreateRole() : this.handleUpdateRole()
        }
        onClose={this.handleCloseAlert}
        notification={this.state.notification}
        alertClass={this.state.alertClass}
        onCloseNotification={()=>this.setState({notification:''})}
      
      >
        <div>
          <div className="row">
            <div className="col-md-6 col-sm-12 col-xs-12">
              <Textbox
                name="name"
                value={this.state.role.name || ''}
                errorText={this.state.nameError}
                inputLabel="Name:"
                inputType="text"
                inputPlaceholder="Name"
                inputClass="form-control"
                handleTextboxChange={(e) => this.handleTextboxChange(e)}
                errorMessage={this.state.nameError}
                errorLabelClass="text-danger"
                required
              />
            </div>
            <div className="col-md-6 col-sm-12 col-xs-12">
              <Textbox
                name="description"
                value={this.state.role.description || ''}
                errorText={this.state.nameError}
                inputLabel="Description:"
                inputType="text"
                inputPlaceholder="Description"
                inputClass="form-control"
                handleTextboxChange={(e) => this.handleTextboxChange(e)}
                errorMessage={this.state.descriptionError}
                errorLabelClass="text-danger"
                required
              />
            </div>
          </div>
          {isNewRole ? (
            ''
          ) : (
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                <label>
                  Status:
                  <span className="ml-1" style={{ color: 'red' }}>
                    *
                  </span>
                </label>
                <div className="dropdown">
                  <button
                    style={{ width: '100%' }}
                    className="btn btn-sm btn-info dropdown-toggle"
                    type="button"
                    id="dropdownMenu2"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    {this.state.statusTypeButtonText}
                  </button>
                  <div
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenu2"
                  >
                    {statusTypes &&
                      statusTypes.map((statusType) => {
                        return (
                          <button
                            key={statusType}
                            onClick={(event) => this.handleStatusChange(event)}
                            className="dropdown-item"
                            type="button"
                            value={statusType}
                          >
                            {statusType}
                          </button>
                        );
                      })}
                  </div>
                </div>
                <p style={{ color: 'red' }}>{this.state.statusTypeError}</p>
              </div>
            </div>
          )}

          <div className="row">
            <div className="col">
              <label>
                Sections:
                <span className="ml-1" style={{ color: 'red' }}>
                  *
                </span>
              </label>
            </div>
          </div>
          {typeof navigationItems !== 'undefined' && navigationItems
            ? navigationItems.map((navItem) => {
                if (typeof navItem !== 'undefined' && navItem) {
                  return (
                    <div key={navItem.id + 'row'} className="row">
                      <div className="col col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <input
                          key={navItem.id + 'check'}
                          type="checkbox"
                          checked={navItem.checked}
                          value={navItem.id}
                          onClick={this.handleCheckboxClick}
                          onChange={this.handleCheckboxChange}
                          disabled={navItem.availableForAllRoles}
                        />
                        <label key={navItem.id + 'label'} className="ml-2">
                          {navItem.text}
                        </label>
                      </div>
                    </div>
                  );
                } else {
                  return null;
                }
              })
            : ''}
          <p style={{ color: 'red' }}>{this.state.navigationItemsError}</p>
        </div>
      </LocalModal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    role: state.rolesStore.role,
    selectedRoleId: state.rolesStore.selectedRoleId,
    roles: state.rolesStore.roles,
    roleNavigationItems: state.rolesStore.roleNavigationItems,
    navigationItems: state.rolesStore.navigationItems,
    users: state.usersStore.users,
    userStatusTypes: state.generalStore.userStatusTypes,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setRoles: (roles) => dispatch(setRoles(roles)),
  createNewRole: (name, description, navigationItems, roles) =>
    dispatch(createNewRole(name, description, navigationItems, roles)),
  updateRole: (id, name, description, status, navigationItems, roles) =>
    dispatch(updateRole(id, name, description, status, navigationItems, roles)),
  getRoleNavigationItems: (roleId) => dispatch(getRoleNavigationItems(roleId)),
  getNavigationItems: () => dispatch(getNavigationItems()),
  setSelectedRole: (id) => dispatch(setSelectedRole(id)),
  setUsers: (users) => dispatch(setUsers(users)),
  setUserStatusTypes: (userStatusTypes) =>
    dispatch(setUserStatusTypes(userStatusTypes)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(onClickOutside(RolesModal));
