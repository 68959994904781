import React, { Component } from 'react';
import onClickOutside from 'react-onclickoutside';
import Textbox from '../shared/textbox';
import { connect } from 'react-redux';
import {
  createNewClient,
  setClients,
  updateClient,
  setSelectedClient,
} from '../../actions/clients-actions';
import { setProjects } from '../../actions/projects-actions';
import Spinner from 'react-bootstrap/Spinner';
import {
  setClientsStatusType,
  setProjectsStatusType,
} from '../../actions/general-actions';
import { isTextValid } from '../../utilities/string.utilities';
import { LocalModal } from '../common';

class ClientsPopUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      clients: [],
      clientsStatusTypes: [],
      client: {
        id: '',
        name: '',
      },
      nameError: '',
      statusTypeError: '',
      baseAlertClass: 'text-center alert alert-dismissible fade show alert-',
      alertClass: '',
      showCreateClientMessage: false,
      clientAddMessage: '',
      clientUpdatedMessage: '',
      selectedClientId: '',
      processing: false,
      clientStatusTypeButtonText: 'Select',
      selectedStatusTypeId: '',
      loadUpdateForm: false,
    };
  }

  handleTextboxChange = async (e) => {
    await this.setState({
      ...this.state,
      client: {
        ...this.state.client,
        [e.target.name]: e.currentTarget.value,
      },
      alertClass: '',
      showCreateClientMessage: false,
    });
  };

  handleClientSelected = (event) => {
    let clientId = parseInt(event.target.value);
    if (clientId > 0) {
      const selectedClient = this.props.clients.find(function(client) {
        return client.id === clientId;
      });
      this.setState({
        ...this.state,
        client: selectedClient,
      });
    } else {
      this.setState({
        ...this.state,
        client: {
          id: '',
          name: '',
          statusId: '',
        },
      });
    }
  };

  componentDidMount() {
    this.props.setClients(this.state.clients);
    this.props.setClientsStatusType(this.state.clientsStatusTypes);
    let projects = [];
    this.props.setProjects(projects);
    this.props.setProjectsStatusType(projects);
  }

  componentDidUpdate = async (prevProps) => {
    const { selectedClientId, clientsStatusTypes } = this.props;
    if (prevProps.client !== this.props.client) {
      if (
        prevProps.clientsStatusTypes !== clientsStatusTypes &&
        typeof clientsStatusTypes !== 'undefined' &&
        clientsStatusTypes &&
        clientsStatusTypes.length > 0
      ) {
        this.setState({
          ...this.state,
          client: {
            id: this.props.client.clientAdded ? '' : this.state.id,
            name: this.props.client.clientAdded ? '' : this.state.name,
            statusId: this.props.client.clientAdded ? '' : this.state.statusId,
          },
          clients: this.props.clients,
          clientAddMessage: this.props.client.clientAdded
            ? this.state.name + ' has been added.'
            : this.props.client.clientAddedMessage,
          clientUpdatedMessage: this.props.client.clientUpdated
            ? 'The client has been updated.'
            : this.props.client.clientUpdatedMessage,
          alertClass:
            this.props.client.clientAdded || this.props.client.clientUpdated
              ? this.state.baseAlertClass + 'success'
              : this.state.baseAlertClass + 'danger',
          showCreateClientMessage: true,
          processing: false,
        });
      } else {
        this.props.setClients(this.state.clients);
        this.setState({
          ...this.state,
          ...this.state.client,
          clients: this.props.clients,
          clientAddMessage: this.props.client.clientAdded
            ? this.state.name + ' has been added.'
            : this.props.client.clientAddedMessage,
          clientUpdatedMessage: this.props.client.clientUpdated
            ? 'The client has been updated.'
            : this.props.client.clientUpdatedMessage,
          alertClass:
            this.props.client.clientAdded || this.props.client.clientUpdated
              ? this.state.baseAlertClass + 'success'
              : this.state.baseAlertClass + 'danger',
          showCreateClientMessage: true,
          processing: false,
        });
      }
    } else {
      if (prevProps.selectedClientId !== this.props.selectedClientId) {
        const selectedClient = this.props.clients.find(function(client) {
          return client.id === selectedClientId;
        });
        if (
          prevProps.clientsStatusTypes !== clientsStatusTypes &&
          typeof clientsStatusTypes !== 'undefined' &&
          clientsStatusTypes &&
          clientsStatusTypes.length > 0
        ) {
          this.setState({
            ...this.state,
            client: {
              id: selectedClient ? selectedClient.id : '',
              name: selectedClient ? selectedClient.name : '',
              statusId: selectedClient ? selectedClient.statusId : '',
            },
            clientStatusTypeButtonText: selectedClient
              ? clientsStatusTypes.find((statusType) => {
                  if (
                    statusType.id.toString() ===
                    selectedClient.statusId.toString()
                  ) {
                    return statusType;
                  }
                  return undefined;
                }).name
              : 'Active',
            selectedStatusTypeId: selectedClient
              ? selectedClient.statusId
              : '-L9rJozap1lBNrkFHk54',
          });
        } else {
          this.setState({
            ...this.state,
            client: {
              id: selectedClient ? selectedClient.id : '',
              name: selectedClient ? selectedClient.name : '',
              statusId: selectedClient ? selectedClient.statusId : '',
            },
          });
        }
      } else {
        if (
          typeof this.props.selectedClientId !== 'undefined' &&
          this.props.selectedClientId !== '' &&
          this.state.client.statusId !== '' &&
          (prevProps.clientsStatusTypes !== clientsStatusTypes ||
            !this.state.loadUpdateForm) &&
          typeof clientsStatusTypes !== 'undefined' &&
          clientsStatusTypes &&
          clientsStatusTypes.length > 0
        ) {
          this.setState({
            ...this.state,
            clientStatusTypeButtonText: clientsStatusTypes.find(
              (statusType) => {
                if (statusType.id === this.state.client.statusId) {
                  return statusType;
                }
                return undefined;
              }
            ).name,
            selectedStatusTypeId: this.state.client.statusId,
            loadUpdateForm: true,
          });
        }
      }
    }
  };

  handleCreateClient = async () => {
    await this.setState({
      ...this.state,
      ...this.state.client,
      alertClass: '',
      showCreateClientMessage: false,
    });
    const { name, selectedStatusTypeId } = this.state;
    const formValid = await this.validateClientForm(name, selectedStatusTypeId);

    if (formValid) {
      this.setState({ processing: true });
      this.props.createNewClient(
        this.state.client.name,
        selectedStatusTypeId,
        this.props.clients
      );
    }
  };

  handleUpdateClient = async () => {
    await this.setState({
      ...this.state,
      ...this.state.client,
      alertClass: '',
      showCreateClientMessage: false,
    });
    const { name, selectedStatusTypeId } = this.state;
    const formValid = await this.validateClientForm(name, selectedStatusTypeId);

    if (formValid) {
      this.setState({ processing: true });
      this.props.updateClient(
        this.props.selectedClientId,
        this.state.client.name,
        selectedStatusTypeId,
        this.props.clients
      );
    }
  };

  handleCloseAlert = () => {
    this.props.setSelectedClient('');
    this.setState({
      client: {
        id: '',
        name: '',
        statusId: '',
      },
      loadUpdateForm: false,
      showCreateClientMessage: false,
      clientAddMessage: '',
      clientUpdatedMessage: '',
      selectedClientId: '',
      processing: false,
      clientStatusTypeButtonText: 'Select',
      selectedStatusTypeId: '',
      nameError: '',
      statusTypeError: '',
    });
  };

  handleCloseNotification = () => {
    this.setState({
      showCreateClientMessage: false,
      clientAddMessage: '',
      clientUpdatedMessage: '',
      alertClass: '',
    });
  };

  validateClientForm = async (name, selectedStatusTypeId) => {
    const { clients, selectedClientId } = this.props;
    let isClientValid = true;
    clients.forEach((client) => {
      if (client.name.toLowerCase().trim() === name.toLowerCase().trim()) {
        if (typeof selectedClientId === 'undefined' || !selectedClientId) {
          isClientValid = false;
        } else {
          if (client.id.toString() !== selectedClientId.toString()) {
            isClientValid = false;
          }
        }
      }
    });
    let formValid = false;
    await this.setState({
      ...this.state,
      nameError:
        name && isTextValid(name)
          ? !isClientValid
            ? 'Already exists a client with this name'
            : ''
          : 'Name is required',
      statusTypeError:
        selectedStatusTypeId.length > 0 ? '' : 'Status is required',
    });
    formValid =
      this.state.nameError === '' && this.state.statusTypeError === '';
    if (
      formValid &&
      typeof this.props.projects !== 'undefined' &&
      this.props.projects &&
      typeof this.props.projectsStatusTypes !== 'undefined' &&
      this.props.projectsStatusTypes
    ) {
      let projectStatusActiveId = '';
      this.props.projectsStatusTypes.forEach((projectStatusType) => {
        if (projectStatusType.name === 'Active')
          projectStatusActiveId = projectStatusType.id.toString();
      });
      let clientActiveProjects = [];
      this.props.projects.forEach((project) => {
        if (
          project.clientId === selectedClientId &&
          project.statusId.toString() === projectStatusActiveId
        )
          clientActiveProjects.push(project);
      });
      if (
        this.state.clientStatusTypeButtonText === 'Inactive' &&
        clientActiveProjects.length > 0
      ) {
        formValid = false;
        let message =
          'You need to inactive all the active projects to continue with this operation. These are the projects that are still active: ';
        let first = true;
        clientActiveProjects.forEach((clientActiveProject) => {
          if (first) message += ', ';
          message += clientActiveProject.name;
        });
        message += '.';
        if (selectedClientId === '') {
          await this.setState({
            ...this.state,
            showCreateClientMessage: true,
            clientAddMessage: message,
            alertClass: this.state.baseAlertClass + 'danger',
          });
        } else {
          await this.setState({
            ...this.state,
            showCreateClientMessage: true,
            clientUpdatedMessage: message,
            alertClass: this.state.baseAlertClass + 'danger',
          });
        }
      }
    }
    return formValid;
  };

  handleClickOutside = () => {
    this.handleCloseAlert();
  };

  render() {
    const {
      modalId,
      modalTitleId,
      selectedClientId,
      clientsStatusTypes,
    } = this.props;
    const isNewClient = selectedClientId === '';
    return (
      <LocalModal
        modalName={modalId}
        title={modalTitleId}
        processing={this.state.processing}
        icon="far fa-handshake"
        onConfirm={() =>
          isNewClient ? this.handleCreateClient() : this.handleUpdateClient()
        }
        onClose={this.handleCloseAlert}
      >
        <div className={this.state.showCreateClientMessage ? '' : 'd-none'}>
          <div className={this.state.alertClass} role="alert">
            {isNewClient
              ? this.state.clientAddMessage
              : this.state.clientUpdatedMessage}
            <button
              type="button"
              className="close"
              onClick={this.handleCloseNotification}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        </div>
        <div>
          <div className="row">
            <div className="col-md-6 col-sm-12 col-xs-12">
              <Textbox
                name="name"
                value={this.state.client.name || ''}
                errorText={this.state.nameError}
                inputLabel="Name:"
                inputType="text"
                inputPlaceholder="Name"
                inputClass="form-control"
                handleTextboxChange={(e) => this.handleTextboxChange(e)}
                errorMessage={this.state.nameError}
                errorLabelClass="text-danger"
                required
              />
            </div>
            <div className="col-md-6 col-sm-12 col-xs-12">
              <label className="label">
                Status:
                <span className="ml-1" style={{ color: 'red' }}>
                  *
                </span>
              </label>
              <div className="dropdown">
                <button
                  className="btn btn-sm btn-info dropdown-toggle"
                  type="button"
                  id="dropdownMenu2"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  {this.state.clientStatusTypeButtonText}
                </button>
                <div className="dropdown-menu" aria-labelledby="dropdownMenu2">
                  {clientsStatusTypes &&
                    clientsStatusTypes.map((statusType) => {
                      return (
                        <button
                          key={statusType.id}
                          onClick={(event) =>
                            this.setState({
                              selectedStatusTypeId: event.target.value,
                              clientStatusTypeButtonText: statusType.name,
                            })
                          }
                          className="dropdown-item"
                          type="button"
                          value={statusType.id}
                        >
                          {statusType.name}
                        </button>
                      );
                    })}
                </div>
              </div>
              <p style={{ color: 'red' }}>{this.state.statusTypeError}</p>
            </div>
          </div>
        </div>
      </LocalModal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    clients: state.clientsStore.clients,
    client: state.clientsStore.client,
    selectedClientId: state.clientsStore.selectedClientId,
    clientsStatusTypes: state.generalStore.clientsStatusTypes,
    projects: state.projectsStore.projects,
    projectsStatusTypes: state.generalStore.projectsStatusTypes,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setClients: (clients) => dispatch(setClients(clients)),
  createNewClient: (name, selectedStatusTypeId, clients) =>
    dispatch(createNewClient(name, selectedStatusTypeId, clients)),
  updateClient: (clientId, name, selectedStatusTypeId, clients) =>
    dispatch(updateClient(clientId, name, selectedStatusTypeId, clients)),
  setClientsStatusType: (clientsStatusTypes) =>
    dispatch(setClientsStatusType(clientsStatusTypes)),
  setSelectedClient: (clientId) => dispatch(setSelectedClient(clientId)),
  setProjects: (projects) => dispatch(setProjects(projects)),
  setProjectsStatusType: (projectsStatusTypes) =>
    dispatch(setProjectsStatusType(projectsStatusTypes)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(onClickOutside(ClientsPopUp));
