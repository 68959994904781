import React from 'react';
import Cookies from 'universal-cookie';
import { connect } from 'react-redux';
import Spinner from 'react-bootstrap/Spinner';
import Card from 'react-bootstrap/Card';
import { setUser, updateUsersInStore } from '../../../actions/user-actions';
import {
  updateUserTypes,
  updateUserStatusTypes,
  updateUserRoles,
} from '../../../actions/general-actions';
import { Services } from '../../../api/services';
import { getPropertyValue } from './userUtilities';
import { setInventoryItems } from '../../../actions/inventory-actions';

const mapStateToProps = (state) => {
  return {
    users: state.usersStore.users,
    user: state.usersStore.user,
    userRoles: state.generalStore.userRoles,
    userStatusTypes: state.generalStore.userStatusTypes,
    userTypes: state.generalStore.userTypes,
    inventoryItems: state.inventoryStore.inventoryItems,
  };
};

const mapDispatchToProps = (dispatch) => ({
  updateUsersInStore: (users) => dispatch(updateUsersInStore(users)),
  setUser: (users, user) => dispatch(setUser(users, user)),
  updateUserTypes: (userTypes) => dispatch(updateUserTypes(userTypes)),
  updateUserStatusTypes: (userStatusTypes) =>
    dispatch(updateUserStatusTypes(userStatusTypes)),
  updateUserRoles: (userRoles) => dispatch(updateUserRoles(userRoles)),
  setInventoryItems: (inventoryItems, userId) =>
    dispatch(setInventoryItems(inventoryItems, userId)),
});

const AdminFormData = (props) => {
  const [state, setState] = React.useState({
    userRoleButtonText: 'Select',
    selectedRoleId: 0,
    selectedTypeId: 0,
    userTypeButtonText: 'Select',
    userStatusButtonText: 'Select',
    selectedStatusId: 0,
    userRoles: [],
    userTypes: [],
    userStatusTypes: [],
    userId: 0,
    roleError: '',
    typeError: '',
    statusError: '',
  });

  const getUser = async (userId, notify) => {
    if (typeof userId !== 'undefined' && userId && userId !== '') {
      const user = await Services.Users.getUser(userId)
        .then((response) => {
          return response.val();
        })
        .catch((error) => {
          console.log(error);
          if (notify === true)
            props.notify('Error when getting user info', 'danger');
        });
      return user;
    }
  };

  const getFormInfo = async (userId) => {
    const user = await getUser(userId, true);
    if (getPropertyValue(user) !== '') {
      let userRoles =
        getPropertyValue(props.userRoles) === '' ? [] : props.userRoles;
      if (
        getPropertyValue(userRoles) === '' ||
        !Array.isArray(userRoles) ||
        userRoles.length === 0
      ) {
        userRoles = await Services.General.getUserRoles()
          .then((response) => {
            let rolesAux = [];
            response = response.val();
            for (var key in response) {
              if (getPropertyValue(response[key]) !== '')
                rolesAux.push(response[key]);
            }
            props.updateUserRoles(rolesAux);
            return rolesAux;
          })
          .catch((error) => {
            console.log(error);
            props.notify('Error when getting user roles', 'danger');
          });
      }
      console.log(userRoles);
      let userStatusTypes =
        getPropertyValue(props.userStatusTypes) === ''
          ? []
          : props.userStatusTypes;
      if (
        getPropertyValue(userStatusTypes) === '' ||
        !Array.isArray(userStatusTypes) ||
        userStatusTypes.length === 0
      ) {
        userStatusTypes = await Services.General.getUserStatusTypes()
          .then((response) => {
            let userStatusTypesAux = [];
            response = response.val();
            for (var key in response) {
              if (getPropertyValue(response[key]) !== '')
                userStatusTypesAux.push(response[key]);
            }
            props.updateUserStatusTypes(userStatusTypesAux);
            return userStatusTypesAux;
          })
          .catch((error) => {
            console.log(error);
            props.notify('Error when getting user status types', 'danger');
          });
      }
      console.log(userStatusTypes);
      let userTypes =
        getPropertyValue(props.userTypes) === '' ? [] : props.userTypes;
      if (
        getPropertyValue(userTypes) === '' ||
        !Array.isArray(userTypes) ||
        userTypes.length === 0
      ) {
        userTypes = await Services.General.getUserTypes()
          .then((response) => {
            let userTypesAux = [];
            response = response.val();
            for (var key in response) {
              if (getPropertyValue(response[key]) !== '')
                userTypesAux.push(response[key]);
            }
            props.updateUserTypes(userTypesAux);
            return userTypesAux;
          })
          .catch((error) => {
            console.log(error);
            props.notify('Error when getting user types', 'danger');
          });
      }
      console.log(userTypes);
      if (getPropertyValue(userRoles) !== '') {
        for (let i = 0; i < userRoles.length; i++) {
          if (userRoles[i].status !== 'Active') {
            userRoles.splice(i, 1);
          }
        }
      }
      const userRole =
        getPropertyValue(userRoles) !== ''
          ? userRoles.find((role) => {
              if (
                getPropertyValue(role) !== '' &&
                getPropertyValue(user.userRoleId) !== '' &&
                role.id.toString() === user.userRoleId.toString()
              ) {
                return role;
              }
            })
          : undefined;
      const userType =
        getPropertyValue(userTypes) !== ''
          ? userTypes.find((type) => {
              if (
                getPropertyValue(type) !== '' &&
                getPropertyValue(user.userTypeId) !== '' &&
                type.id.toString() === user.userTypeId.toString()
              ) {
                return type;
              }
            })
          : undefined;
      const userStatusType =
        getPropertyValue(userStatusTypes) !== ''
          ? userStatusTypes.find((status) => {
              if (
                getPropertyValue(status) !== '' &&
                getPropertyValue(user.userStatusTypeId) !== '' &&
                status.id.toString() === user.userStatusTypeId.toString()
              ) {
                return status;
              }
            })
          : undefined;
      const userRoleButtonText =
        getPropertyValue(userRole) !== '' ? userRole.name : 'Select';
      const selectedRoleId =
        getPropertyValue(userRole) !== '' ? userRole.id : 0;
      const userTypeButtonText =
        getPropertyValue(userType) !== '' ? userType.name : 'Select';
      const selectedTypeId =
        getPropertyValue(userType) !== '' ? userType.id : 0;
      const userStatusButtonText =
        getPropertyValue(userStatusType) !== ''
          ? userStatusType.name
          : 'Select';
      const selectedStatusId =
        getPropertyValue(userStatusType) !== '' ? userStatusType.id : 0;
      console.log({
        user,
        userStatusTypes:
          getPropertyValue(userStatusTypes) === '' ? [] : userStatusTypes,
        userTypes: getPropertyValue(userTypes) === '' ? [] : userTypes,
        userRoles: getPropertyValue(userRoles) === '' ? [] : userRoles,
        userRoleButtonText,
        selectedRoleId,
        userTypeButtonText,
        selectedTypeId,
        userStatusButtonText,
        selectedStatusId,
        userId,
      });
      setState({
        ...state,
        user,
        userStatusTypes:
          getPropertyValue(userStatusTypes) === '' ? [] : userStatusTypes,
        userTypes: getPropertyValue(userTypes) === '' ? [] : userTypes,
        userRoles: getPropertyValue(userRoles) === '' ? [] : userRoles,
        userRoleButtonText,
        selectedRoleId,
        userTypeButtonText,
        selectedTypeId,
        userStatusButtonText,
        selectedStatusId,
        userId,
      });
    }
  };

  React.useEffect(() => {
    props.setInventoryItems([], 0);
    getFormInfo(props.userId);
  }, []);

  React.useEffect(() => {
    props.setInventoryItems([], 0);
    getFormInfo(props.userId);
  }, [props.userId]);

  const isFormValid = () => {
    if (state.userId === 0) {
      props.notify('Need to select an user', 'danger');
      return false;
    }
    if (state.selectedRoleId === 0) {
      props.notify('Need to select an user role', 'danger');
      setState({
        ...state,
        roleError: 'User role is required',
      });
      return false;
    }
    if (state.selectedTypeId === 0) {
      props.notify('Need to select an user type', 'danger');
      setState({
        ...state,
        typeError: 'User type is required',
      });
      return false;
    }
    if (state.selectedStatusId === 0) {
      props.notify('Need to select an user status', 'danger');
      setState({
        ...state,
        statusError: 'User status is required',
      });
      return false;
    }

    const inventoryItem = props.inventoryItems.find(
      (item) => item.userId == state.userId
    );

    if (inventoryItem && state.selectedStatusId == 2) {
      setState({
        ...state,
        statusError: 'User cannot be deactivated, has assigned inventory',
      });
      return false;
    }

    return true;
  };

  const onSubmit = async () => {
    props.handleCloseNotification();
    if (isFormValid() === true) {
      let users = props.users;
      if (
        getPropertyValue(users) === '' ||
        !Array.isArray(users) ||
        users.length === 0
      ) {
        users = await Services.Users.getUsers()
          .then((data) => {
            if (data !== null && data.val() !== null) {
              users = [];
              const response = data.val();
              for (var key in response) {
                users.push(response[key]);
              }
              return users;
            }
            return [];
          })
          .catch((error) => {
            console.log(error);
            return [];
          });
      }
      await Services.Users.getUser(state.userId).then(async (user) => {
        if (user !== null && user.val() !== null) {
          user = user.val();
          if (getPropertyValue(user) !== '') {
            user.userRoleId = state.selectedRoleId;
            user.userTypeId = state.selectedTypeId;
            user.userStatusTypeId = state.selectedStatusId;
            const updated = await Services.Users.uploadUserData(user);
            if (updated === true) {
              props.notify('User updated', 'success');
            } else {
              props.notify('Error trying to update user', 'danger');
            }
            users = getPropertyValue(users) === '' ? [] : users;
            let index = 0;
            users.forEach((userAux, i) => {
              if (userAux.id === user.id) index = i;
            });
            users[index] = user;
            const userId = new Cookies().get('1950SystemUserId');
            if (user.id === userId) props.setUser(users, user);
            else {
              props.updateUsersInStore(users);
            }
          }
        }
      });
    }
  };

  return (
    <>
      <h2 className="onboarding-title">{props.title}</h2>
      <div className="container" style={{ display: 'flex', flexWrap: 'wrap' }}>
        <div className="col-12 col-lg-6">
          <div className="form-group rb-label-input">
            <label
              className="mb-2 rb-textbox-style"
              // style={{marginLeft: '29px'}}
            >
              User Role:
            </label>
            <div className="dropdown">
              <button
                style={{ width: '100%' }}
                className="btn btn-sm btn-info dropdown-toggle"
                type="button"
                id="dropdownMenu2"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                {state.userRoleButtonText}
              </button>
              <div
                className="dropdown-menu"
                aria-labelledby="dropdownMenu2"
                style={{ width: '100%' }}
              >
                {state.userRoles &&
                  state.userRoles.map((role) => {
                    return (
                      <button
                        key={role.id}
                        onClick={(event) =>
                          setState({
                            ...state,
                            selectedRoleId: event.target.value,
                            userRoleButtonText: role.name,
                          })
                        }
                        className="dropdown-item"
                        type="button"
                        value={role.id}
                      >
                        {role.name}
                      </button>
                    );
                  })}
              </div>
            </div>
            <p style={{ color: 'red' }}>{state.roleError}</p>
            <br />
          </div>
        </div>
        <div className="col-12 col-lg-6">
          <div className="form-group rb-label-input">
            <label
              className="mb-2 rb-textbox-style"
              // style={{marginLeft: '29px'}}
            >
              User Type:
            </label>
            <div className="dropdown">
              <button
                style={{ width: '100%' }}
                className="btn btn-sm btn-info dropdown-toggle"
                type="button"
                id="dropdownMenu2"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                {state.userTypeButtonText}
              </button>
              <div
                className="dropdown-menu"
                aria-labelledby="dropdownMenu2"
                style={{ width: '100%' }}
              >
                {state.userTypes &&
                  state.userTypes.map((type) => {
                    return (
                      <button
                        key={type.id}
                        onClick={(event) =>
                          setState({
                            ...state,
                            selectedTypeId: event.target.value,
                            userTypeButtonText: type.name,
                          })
                        }
                        className="dropdown-item"
                        type="button"
                        value={type.id}
                      >
                        {type.name}
                      </button>
                    );
                  })}
              </div>
            </div>
            <p style={{ color: 'red' }}>{state.typeError}</p>
            <br />
          </div>
        </div>
        <div className="col-12 col-lg-6">
          <div className="form-group rb-label-input">
            <label
              className="mb-2 rb-textbox-style"
              // style={{marginLeft: '29px'}}
            >
              User Status:
            </label>
            <div className="dropdown">
              <button
                style={{ width: '100%' }}
                className="btn btn-sm btn-info dropdown-toggle"
                type="button"
                id="dropdownMenu2"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                {state.userStatusButtonText}
              </button>
              <div
                className="dropdown-menu"
                aria-labelledby="dropdownMenu2"
                style={{ width: '100%' }}
              >
                {state.userStatusTypes &&
                  state.userStatusTypes.map((status) => {
                    return (
                      <button
                        key={status.id}
                        onClick={(event) =>
                          setState({
                            ...state,
                            selectedStatusId: event.target.value,
                            userStatusButtonText: status.name,
                          })
                        }
                        className="dropdown-item"
                        type="button"
                        value={status.id}
                      >
                        {status.name}
                      </button>
                    );
                  })}
              </div>
            </div>
            <p style={{ color: 'red' }}>{state.statusError}</p>
            <br />
          </div>
        </div>
        <div className="col-12 text-center">
          <button type="button" onClick={onSubmit} className="next-button">
            Save Changes
          </button>
        </div>
      </div>
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminFormData);
