export default function formatDate(date) {
    const dateObject = new Date(date);
    if(isNaN(dateObject.getDate())) {
        return date;
    }
    const formatedDate = (dateObject.getDate() < 10 ? '0' : '') 
    + dateObject.getDate() + '/' + ((dateObject.getMonth() + 1) < 10 ? '0': '') 
    + (dateObject.getMonth() + 1) + '/' + dateObject.getFullYear();
    return formatedDate;
}

export function getDate(dateString, dateSeparator) {
    try {
        const dateParts = dateString.split(dateSeparator);

        const day = parseInt(dateParts[0]);
        const month = parseInt(dateParts[1]);
        const year = parseInt(dateParts[2]);
        return new Date(year, month-1, day);
    } catch(error) {
        return new Date();
    }
}