import React, { Component } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import './App.css';
import LoginForm from './components/login/login-form';
import IndexMain from './components/main/index';
import IndexInventory from './components/inventory/index';
import IndexTracking from './components/tracking/index';
import UsersMain from './components/users/index';
import ForgotPassword from './components/login/forgot-password';
import PasswordReset from './components/login/password-reset';
import AuthActions from './components/login/auth-actions';
//import ErrorPage from './components/main/errorPage';
import IndexClients from './components/clients/index';
import IndexProjects from './components/projects/index';
import IndexCapacity from './components/capacity/index';
import IndexNavigation from './components/navigation/index';
import Profile from './components/users/profile';
import LocationsMain from './components/locations/index';
import RolesMain from './components/roles/index';
import UserTypesMain from './components/userTypes/index';
import RegisterForm from './components/users/registerForm/register-form';
import { ProtectedRoute } from './components/classes/protectedRoute';
import { Route, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  getNavigationItems,
  setNavigationItems,
} from './actions/general-actions';
import { getUser, setUsers } from './actions/user-actions';
import Cookies from 'universal-cookie';
import Overlay from './components/shared/overlay/overlay';
import { withRouter } from 'react-router-dom';
import UsersFormData from './components/users/usersFormData/index';
import { Services } from './api/services';
import Navbar from './components/navbar';
import Footer from './components/footer';
import auth from './components/classes/auth';

const navs = [
  '/app',
  /*'/tracking',*/ '/users',
  '/inventory',
  '/locations',
  '/clients',
  '/projects',
  '/capacity',
  '/roles',
  '/profile',
  '/navigation',
  '/user_types',
  '/users_profile',
  '/registerForm'
];
const currentUserId = new Cookies().get('1950SystemUserId');
class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navigationItems: [],
      navigationItemsLoaded: false,
      userId: undefined,
    };
    //if(navs.indexOf(this.props.location.pathname.toLowerCase()) >= 0) {
    //props.getNavigationItems(currentUserId);
    //}
  }

  getNavigationItems = () => {
    const currentUserId = new Cookies().get('1950SystemUserId');
    if (
      typeof currentUserId !== 'undefined' &&
      currentUserId !== null &&
      currentUserId !== '' &&
      this.state.userId === undefined
    ) {
      Services.General.getNavigationItems(currentUserId).then(
        (navigationItems) => {
          if (
            typeof navigationItems !== 'undefined' &&
            navigationItems !== null &&
            typeof navigationItems.navigation !== 'undefined' &&
            navigationItems.navigation !== null &&
            (this.state.navigationItemsLoaded === false ||
              (this.state.navigationItems.length === 0 &&
                navigationItems.length > 0))
          ) {
            this.props.setNavigationItems(navigationItems.navigation);
            this.setState({
              ...this.state,
              navigationItems: navigationItems.navigation,
              navigationItemsLoaded: true,
              userId: currentUserId,
            });
          } else {
            if (this.state.navigationItemsLoaded === false) {
              this.setState({
                ...this.state,
                navigationItemsLoaded: true,
              });
            }
          }
        }
      );
    }
  };

  componentDidMount = () => {
    let authUser = Services.Users.getAuthUser();
    let isLogged = auth.isAuthenticatedLocal();
    // console.log('authUser',authUser); console.log('isLogged',isLogged);

    this.getNavigationItems();
    let currentUserIdSession =
      typeof new Cookies().get('1950SystemUserId') !== 'undefined'
        ? new Cookies().get('1950SystemUserId')
        : typeof this.props.user !== 'undefined' && this.props.user !== null
        ? this.props.user.id
        : undefined;

    let firebaseUser = (authUser!==null) ? authUser : Services.Users.getUserLoggedIn();
    const path = this.props.location.pathname;
    if(!firebaseUser && path !== "/forgotPassword" && path !== "/passwordReset" && path !== "/authActions") {
      currentUserIdSession = undefined;
      this.props.history.push("/");
    }
    if (
      (typeof this.props.user === 'undefined' || this.props.user === null) &&
      typeof currentUserIdSession !== 'undefined' &&
      currentUserIdSession !== null
    ) {
      this.props.getUser(currentUserIdSession);
      this.props.setUsers([]);
    }

    if(isLogged)
      this.props.history.push(path.replace("/",""));
  };

  componentDidUpdate = async (prevProps) => {
    const firebaseUser = Services.Users.getUserLoggedIn();
    console.log(firebaseUser);
    console.log(this.props.navigationItems);
    if(firebaseUser !== undefined && firebaseUser !== null && firebaseUser.uid !== this.state.userId) {
      await this.setState({...this.state, userId: firebaseUser.uid, navigationItems: this.props.navigationItems && this.props.navigationItems.length > 0 ? this.props.navigationItems : this.state.navigationItems })
    }
    if (
      typeof this.props.navigationItems !== 'undefined' &&
      this.props.navigationItems !== null &&
      this.state.navigationItems.length === 0 &&
      this.props.navigationItems.length > 0
    ) {
      let navigationItems = [];
      navigationItems = this.props.navigationItems;
      //navigationItems.push({id: 99, path: 'registerForm', component: 'RegisterForm'})
      await this.setState({
        ...this.state,
        navigationItems,
      });
    }
    this.getNavigationItems();
  };

  getComponent = (comp) => {
    switch (comp) {
      case 'IndexMain':
        return IndexMain;
      case 'IndexTracking':
        return IndexTracking;
      case 'UsersMain':
        return UsersMain;
      case 'IndexInventory':
        return IndexInventory;
      case 'LocationsMain':
        return LocationsMain;
      case 'IndexClients':
        return IndexClients;
      case 'IndexProjects':
        return IndexProjects;
      case 'IndexCapacity':
        return IndexCapacity;
      case 'Profile':
        return Profile;
      case 'RolesMain':
        return RolesMain;
      case 'IndexNavigation':
        return IndexNavigation;
      case 'UserTypesMain':
        return UserTypesMain;
      case 'UsersProfile':
        return UsersFormData;
      case 'RegisterForm':
        return RegisterForm;
      default:
        break;
    }
  };
  render() {
    const currentUserIdSession = this.state.userId;
    let navigationItems = [];
    if (
      typeof this.state.navigationItems !== 'undefined' &&
      this.state.navigationItems !== null &&
      this.state.navigationItems.length > 0
    ) {
      navigationItems = this.state.navigationItems;
      // navigationItems.push({
      //   id: 99,
      //   path: 'registerForm',
      //   component: 'RegisterForm',
      // });
    }
    if (currentUserIdSession && navigationItems.length === 0) {
      return (
        <div
          className="App"
          style={{ display: 'flex', flexDirection: 'column' }}
        >
          <div style={{ textAlign: 'center', marginTop: '5rem' }}>
            <Spinner style={{ color: 'black' }} animation="border" />
          </div>
        </div>
      );
    }
    // if(navigationItems.length > 0) {
    return (
      <>
        <div
          className="App"
          style={{
            display: 'flex',
            flexDirection: 'column',
            
          }}
        >
          {currentUserIdSession && navigationItems && (
            <Navbar history={this.props.history} />
          )}
          <div>
            <Switch>
              {currentUserIdSession && navigationItems ? (
                navigationItems.map((navItem) => {
                  if(navItem.component !== 'InviteUser') {
                    return (
                      <ProtectedRoute
                        key={navItem.id}
                        exact
                        path={'/' + navItem.path}
                        component={this.getComponent(navItem.component)}
                      />
                    );
                  }
                })
              ) : currentUserIdSession &&
                navs.indexOf(this.props.location.pathname.toLowerCase()) >=
                  0 ? (
                <Overlay />
              ) : null}
              <Route exact path="/passwordReset" component={PasswordReset} />
              <Route exact path="/forgotPassword" component={ForgotPassword} />
              <Route path="/authActions" component={AuthActions} />
              <Route path="/registerForm" component={RegisterForm} />
              <Route exact path="/" component={LoginForm} />
              <Redirect exact from="*" to="/" />
              {/* <Route path="*" component={()=> <ErrorPage className="error-page-main" />} /> */}
            </Switch>
          </div>
        </div>
        <Footer>1950Labs</Footer>
      </>
    );
    // } else {
    //   //if(this.state.navigationItemsLoaded === true) {
    //     return (
    //       <div className="App" style={{ display: 'flex', flexDirection: 'column' }}>
    //         <Switch>
    //           {/* <ProtectedRoute key={99} exact path="/registerForm" component={RegisterForm} /> */}
    //           <Route exact path="/passwordReset" component={PasswordReset} />
    //           <Route exact path="/forgotPassword" component={ForgotPassword} />
    //           <Route path="/authActions" component={AuthActions} />
    //           <Route exact path="/" component={LoginForm} />
    //           {/* <Route path="*" component={()=> <ErrorPage className="error-page-main" />} /> */}
    //         </Switch>
    //       </div>
    //     )
    //   // } else {
    //   //   return (
    //   //     <div className="App" style={{ display: 'flex', flexDirection: 'column' }}>
    //   //         <div style={{textAlign: 'center', marginTop: '5rem'}}>
    //   //             <Spinner style={{color: 'white'}} animation="border" />
    //   //         </div>
    //   //     </div>
    //   //   )
    //   // }
    // }
  }
}

const mapStateToProps = (state) => {
  return {
    navigationItems: state.generalStore.navigationItems,
    user: state.usersStore.user,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getNavigationItems: (currentUserId) =>
    dispatch(getNavigationItems(currentUserId)),
  setNavigationItems: (navigationItems) =>
    dispatch(setNavigationItems(navigationItems)),
  getUser: (userId) => dispatch(getUser(userId)),
  setUsers: (users) => dispatch(setUsers(users)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
