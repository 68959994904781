import React, { Component } from 'react';
import './button-card.css';
import { withRouter } from 'react-router-dom';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
const ButtonCard = ({
  isExternal,
  icon,
  title,
  description,
  link,
  history,
  isPopUp,
  dataTarget,
  component
}) => {
  const theme = useTheme();

  const onClickFunction = () => {
    if(isExternal) {
      window.open(link, '_blank')
    } else {
      if(!isExternal && !isPopUp) {
        history.push(`/${link}`)
      }
    }

  }

  if(!isExternal && isPopUp) {
    return (
      <div
        className="col-12 col-lg-3 col-md-4 col-sm-6 col-exsm-12 card-container"
        style={{ cursor: isExternal ? 'pointer' : 'default'}}
        data-toggle="modal"
        data-target={dataTarget}
      >
        <div className="img-container">
          <img className={"button-card-icon-min" + (isExternal ? " img-card-external":"")} src={icon} alt="" />
        </div>
        <div className={"content-container" + (isExternal ? " content-container-external":"")}>
          <div className="button-card-title-min">{title}</div>
          <div className="button-card-description-min">{description}</div>
            <a
              className="button-card-link-min"
              style={{border: 'none', backgroundColor: 'transparent', textDecoration: 'none'}}
            >
              Go to page >
            </a>
        </div>
      </div>
    );
  }

  return (
    <div
      className="col-12 col-lg-3 col-md-4 col-sm-6 col-exsm-12 card-container"
      style={{ cursor: isExternal ? 'pointer' : 'default'}}
      onClick={() => onClickFunction()}
    >
      <div className="img-container">
        <img className={"button-card-icon-min" + (isExternal ? " img-card-external":"")} src={icon} alt="" />
      </div>
      <div className={"content-container" + (isExternal ? " content-container-external":"")}>
        <div className="button-card-title-min">{title}</div>
        <div className="button-card-description-min">{description}</div>
        {!isExternal && !isPopUp && (
          <a
            className="button-card-link-min"
          >
            Go to page >
          </a>
        )}
      </div>
    </div>
  );
};

export default withRouter(ButtonCard);
