const initialState = {
  locations: [],
  userRoles: [],
  userStatusTypes: [],
  userTypes: [],
  clientsStatusTypes: [],
  projectsStatusTypes: [],
  userRolesButtonNavigation: [],
  button: undefined,
  buttonNavigationItems: [],
  capacities: []
};

const generalReducer = (state = initialState, action) => {
    switch (action.type) {
        case "SET_PROJECT_STATUS_TYPES":
          return {
            ...state,
            projectsStatusTypes: action.projectsStatusTypes
          };
        case "SET_CLIENT_STATUS_TYPES":
          return {
            ...state,
            clientsStatusTypes: action.clientsStatusTypes
          };
        case "SET_USER_ROLES":
          return {
            ...state,
            userRoles: action.userRoles
          };

        case "SET_USER_STATUS_TYPES":
          return {
            ...state,
            userStatusTypes: action.userStatusTypes
          };

        case "SET_USER_TYPES":
          return {
            ...state,
            userTypes: action.userTypes
          };

        case "SET_GENERAL_ENUMS":
            return {
                ...state,
                locations: action.generalEnums.locations,
                userRoles: action.generalEnums.userRoles,
                userStatusTypes: action.generalEnums.userStatusTypes,
                userTypes: action.generalEnums.userTypes
            };
          
        case 'SET_USER_PROJECTS':
          return {
            ...state,
            userProjects: action.userProjects
          };
        
        case 'CREATE_NEW_USER_PROJECT':
           const userProject = Object.assign({}, action.payload.userProject)
           const userProjects = Object.assign({}, action.payload.userProjects)
          
          return {
            ...state,
            userProject: userProject,
            userProjects: userProjects
        };

        case 'SET_USER_PROJECT_ID': 
          const newObject = Object.assign({}, action.payload)
          return {
            ...state,
            selectedUserProjectId: newObject.selectedUserProjectId,
            userId: newObject.userId
          };

        case 'UPDATE_USER_PROJECT':
          return {
            ...state,
            userProject: Object.assign({}, action.payload.userProject),
            userProjects: Object.assign({}, action.payload.userProjects)
          };

        case 'REMOVE_USER_PROJECT':
          return {
            ...state,
            userProjectId: action.payload.userProjectId,
            userProjects: Object.assign({}, action.payload.userProjects),
            userProject: action.payload.userProject
          };
        case 'REMOVE_USER_PROJECT_ERROR_MESSAGE':
          return {
            ...state,
            userProject: action.userProject
          }
        case 'SET_NAVIGATION_ITEMS':
          return {
            ...state,
            navigationItems: action.navigationItems
          };

        case 'SET_BUTTON_NAVIGATION_ITEMS':
          return {
            ...state,
            buttonNavigationItems: action.buttonNavigationItems
          };
        case 'CREATE_BUTTON_NAVIGATION_ITEM':
          return {
            ...state,
            button: action.payload.button,
            buttonNavigationItems: action.payload.buttonNavigationItems
            //button: action.button
          };
        case 'SET_BUTTON':
          return {
            ...state,
            button: action.button
          };
        case 'BUTTON_NAVIGATION_ERROR':
          return {
            ...state,
            button: action.button
          };
        case 'SET_BUTTON_ID':
          return {
            ...state,
            selectedButtonId: action.selectedButtonId
          };
        case 'SET_USER_ROLES_BUTTON_NAVIGATION':
          return {
            ...state,
            userRolesButtonNavigation: action.userRolesButtonNavigation
          };
        case 'REMOVE_BUTTON_NAVIGATION':
          let buttonToRemove = state.buttonNavigationItems.find(btn=> {
            if(btn.id === action.buttonNavigation.id) {
              return btn;
            } return undefined
                
          });
          let newButtonNavigationItems = [...state.buttonNavigationItems];
          let index = newButtonNavigationItems.indexOf(buttonToRemove);
          if (index !== -1) {
            newButtonNavigationItems.splice(index, 1);
              return {
                  ...state,
                  button: action.buttonNavigation,
                  buttonNavigationItems: newButtonNavigationItems
                }
          }
          else{
              return {
                  ...state,
                  button: action.buttonNavigation,
                  ...state.buttonNavigationItems
                }
          }
        case 'REMOVE_BUTTON_NAVIGATION_ERROR_MESSAGE':
          return {
            ...state,
            button: action.buttonNavigation
          }
        case 'SET_PROJECT_CAPACITIES':
          return {
            ...state,
            capacities: action.capacities
          }
        case 'SET_BUTTON_NAVIGATION_INFO': 
          return {
            ...state,
            userRoles: action.payload.userRoles,
            buttonNavigationItems: action.payload.buttonNavigationItems,
            userRolesButtonNavigation: action.payload.userRolesButtonNavigation
          }
        default:
          return state;
      }
    };
    
    export default generalReducer;