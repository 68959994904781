import React, { Component } from 'react';
import { connect } from "react-redux";
import Navbar from '../../main/navbar';
import { setUsers, downloadImage } from '../../../actions/user-actions';
import { setGeneralEnums } from '../../../actions/general-actions'

import './usersFormData.css'
import PersonalFormData from './personalFormData';
import ReadText from './readText';
class UsersFormData extends Component {
  constructor(props) {
    super(props);
    this.goToHome = this.goToHome.bind(this);
    this.state = { 
      users: [], 
      // activeUsersOnly: true,
      // orderByFirstName: true,
      // orderByLastName: true,
      // orderByEmail: true,
      // orderByRole: true,
      // orderByType: true,
      // orderByLocation: true,
      // orderByStatus: true,
      userInfo: {
        firstName: "",
        lastName: "",
        email: "",
        personalEmail: "",
        password: "",
        userStatus: "",
        locationId: "",
        birthDate: "",
        calendarBirthDate: new Date(),
        homeAddress: "",
        cellularNumber: "",
        identificationType: "DNI",
        identificationNumber: "",
        nationality: ""
      }
      
    }
  }

  componentDidMount = () => {
    const { users, locations, userRoles, userStatusTypes, userTypes } = this.state
    this.props.setUsers(users);  
    this.props.setGeneralEnums(locations, userRoles, userStatusTypes, userTypes);
  }

  goToHome = () => {
    this.props.history.push('/app')
  }

  getStatusEnumText = (statusId) => {
    if(this.props.userStatusTypes && this.props.userStatusTypes.length > 0) {
      let status = this.props.userStatusTypes.find(s => {
        return parseInt(s.id) === parseInt(statusId)})
        return status ? status.name : "NO STATUS";
    }
    return "..."
  }

  getLocationText = (locationId) => {
    if(this.props.locations && this.props.locations.length > 0) {
      let location = this.props.locations.find(l => {
        return l.id && locationId && l.id.toString() === locationId.toString();
      });
      return location ? location.name : "NO LOCATION";
    }
    return "..."
  }

  loadUserInfo = (userId) => {
    const selectedUser = this.props.users.find(u => { 
      return u.id === userId
    })
console.log(selectedUser)
    this.setState({
      userInfo: selectedUser
    })

  }

  render() { 
    const { users } = this.props
    
    console.log(users)
    console.log(this.props.userStatusTypes)

    
    return (
      <React.Fragment>
        <Navbar linkToHome={this.goToHome} />
        <div className="text-center grid card-cont">    
        { users ? users.map(user=> { const userFile = user.file ? user.id + "/" + user.file.name : "";
                            const statusText = this.getStatusEnumText(user.userStatusTypeId)
                            const location = this.getLocationText(user.locationId);
                            console.log(location);
                            let isActiveUser = statusText === 'Active'
                            if(isActiveUser) {
                             return (


          
          <div key={ user.id }>          
            <div>
            {/* {this.props.userStatusTypes && this.props.userStatusTypes.length > 0 ? alert(this.props.userStatusTypes.find( status => 
                     status.id === user.userStatusTypeId
      ).name) : alert('undefineddd')} */}
            
              <div 
                className="card" 
                data-target="#exampleModal"
                data-toggle="modal"
                onClick={()=> {
                  this.loadUserInfo(user.id)
                }}
                >
                  {/* <div className={`card-user-status ${ isActiveUser ? 'card-user-status-active' : 'card-user-status-inactive'}`}>{ statusText }</div> */}
                  {/* <div className="card-picture"><div className="no-image-text">NO IMAGE</div></div> */}
              <img alt="Avatar" className="card-picture" src={user.file && user.file.url ? user.file.url : require('../../../assets/images/default-user.png')} ></img>
              <div className="name-box-cont">
                <div className="name-box">
                  <div>{ `${user.lastName}, ${user.firstName}`}</div> 
                </div>
              </div>
              </div>
                    <div className="modal fade" id="exampleModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                      <div className="modal-dialog mw-100 w-75" style={{ maxWidth: 'calc(100% - 50px)' }} role="document">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">{ `${this.state.userInfo.firstName} ${this.state.userInfo.lastName}`}</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                              <span aria-hidden="true">&times;</span>
                            </button>
                          </div>
                          <div className="modal-body">
                            <div style={{ display: 'flex' }} >

                              <div style={{ width: '120px' }}>
                                <div style={{ width: '100px' }}>
                                  { this.state.userInfo.file && this.state.userInfo.file.url ? <img alt="Avatar" className="modal-card-picture"  src={ this.state.userInfo.file.url } ></img> : <div className="modal-card-picture"><div className="modal-no-image-text">NO IMAGE</div></div> }
                                </div>
                              </div>
                              <div>
                                <div style={{ textAlign: 'left', width: '100%', marginLeft: '10px' }}>
                                  <PersonalFormData userInfo={this.state.userInfo} info="public" location={location} />
                                </div>
                            </div>
                          </div>
                          </div>
                          <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                          </div>
                        </div>
                      </div>
                    </div>
            </div>
            <br />
          </div>
        )}
        return null }) : null }
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    users: state.usersStore.users,
    locations: state.generalStore.locations,
    userRoles: state.generalStore.userRoles,
    userStatusTypes: state.generalStore.userStatusTypes,
    userTypes: state.generalStore.userTypes
  };
};

const mapDispatchToProps = dispatch => ({
  setUsers: (users) => dispatch(setUsers(users)),
  setGeneralEnums: (locations, userRoles, userStatusTypes, userTypes) => dispatch(setGeneralEnums(locations, userRoles, userStatusTypes, userTypes)),
  downloadImage: (userId) => dispatch(downloadImage(userId)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UsersFormData);