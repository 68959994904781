const initialState = {
    roles: [],
    selectedRoleId: undefined,
    navigationItems: [],
    roleNavigationItems: []
};

const roleReducer = (state = initialState, action) => {
    switch (action.type) {
        case "SET_NAVIGATION_ITEMS":
            return {
                ...state,
                navigationItems: action.navigationItems
            };
        case "SET_ROLE_NAVIGATION_ITEMS":
            return {
                ...state,
                roleNavigationItems: action.roleNavigationItems
            }
        case "SET_ROLES":
          return {
            ...state,
            roles: action.roles
          };
        case "SET_ROLE_ID":
          return {
            ...state,
            selectedRoleId: action.selectedRoleId
          }
        case 'SET_NEW_ROLE':
            return  {
                ...state,
                role: action.payload.role,
                roles: action.payload.roles
            }
        case 'SET_NEW_ROLE_ERROR':
            return  {
                ...state,
                role: action.role
            }
        case 'SET_UPDATED_ROLE':
            return  {
                ...state,
                role: action.payload.role,
                roles: action.payload.roles
            }
        case 'SET_UPDATED_ROLE_ERROR':
            return  {
                ...state,
                role: action.role
            }
        case 'REMOVE_ROLE':
            let roleToRemove = state.roles.find(role=> {
                if(role.id === action.id) {
                    return role;
                } 
                return undefined;
            });
            let newRoles = [...state.roles];
            if((typeof roleToRemove) !== 'undefined') {
                let index = newRoles.indexOf(roleToRemove);
                if (index !== -1) {
                    newRoles.splice(index, 1);
                    return {
                        ...state,
                        ...state.selectedRoleId,
                        roles: newRoles
                    }
                }
            }
            return {
                ...state,
                ...state.selectedRoleId,
                ...state.newRoles
            }
        default:
            return state;
    }
}
export default roleReducer;