import auth from '../classes/auth';
import { connect } from 'react-redux';
import React from 'react';
import './styles.css';
import { setButtonNavigationItems } from '../../actions/general-actions';
import { setProfileImage, setUser } from '../../actions/user-actions';
import defaultImage from '../../assets/images/default-user.png';
import exit from '../../assets/images/icons/exit.svg';
import { Services } from '../../api/services';

const NavBar = (props) => {
  const { profileImage = defaultImage, user, width } = props;
  if(!user) return null;
  return (
    <div className='navbar-container'>
      <div className='container'>
        <div className='row'>
          <div className='col-12 navbar-content'>
            <UserNavbar profileImage={profileImage} user={user} width={width} />
            <div className='title'>DASHBOARD</div>
            <div className='logout-container'>
              <img className='logout' onClick={() => logout(props)} src={exit}/>              
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const logout = async (props) => {
  Services.Users.logout().then(async response => {
    if(response === true) {
      const buttonNavigationItems = [];
      await props.setButtonNavigationItems(buttonNavigationItems);
      await props.setProfileImage(undefined);
      await props.setUser(null);
      auth.logout(() => {
        props.history.push('/');
      });
    }
  })
 
};

const UserNavbar = ({ profileImage, user, width }) => {
  return (
    <div className='user-profile-container'>
      <img className='profile-image' src={user.file ? user.file.url : defaultImage} />
      <span className='user-profile-text d-none d-md-inline'>Hi {user.firstName}!</span>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    profileImage: state.usersStore.profileImage,
    user: state.usersStore.user,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setButtonNavigationItems: (buttonNavigationItems) =>
    dispatch(setButtonNavigationItems(buttonNavigationItems)),
  setProfileImage: (imageUrl) => dispatch(setProfileImage(imageUrl)),
  setUser: (users, user) => dispatch(setUser(users, user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NavBar);
