import React, { Component } from 'react';
import onClickOutside from 'react-onclickoutside';
import Textbox from '../shared/textbox';
import { connect } from "react-redux";
import { createNewLocation, setLocations, updateLocation, setSelectedLocation } from '../../actions/location-actions';
import Spinner from 'react-bootstrap/Spinner'
import { isTextValid } from '../../utilities/string.utilities';
import { LocalModal } from '../common';

class LocationsModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            locations: [],
            locationsById: [],
            location: {
                name: "",
                description: ""
            },
            selectedLocationId: "",
            nameError: "",
            descriptionError: "",
            baseAlertClass: "text-center alert alert-dismissible fade show alert-",
            alertClass: "",
            showCreateLocationMessage: false,
            locationAddedMessage: "",
            locationUpdatedMessage: "",
            processing: false,
            
        }
        this.validateLocationForm = this.validateLocationForm.bind(this);
      }

      componentDidMount() {
        const { locations } = this.state;
        this.props.setLocations(locations);
      }

    handleTextboxChange = async (e) => {
        await this.setState({
            ...this.state,
            location: {
                ...this.state.location,
                [e.target.name]: e.currentTarget.value
            },
            alertClass: "",
            showCreateLocationMessage: false
        }); 
      }

    handleLocationSelected = (event) => {
        let locationId = parseInt(event.target.value);
        if(locationId > 0)
        {
            const selectedLocation = this.props.locations.find(function (location) {
                return location.id === locationId
              });
            this.setState({
                ...this.state,
                location: selectedLocation
            });
        }
        else{
            this.setState({
                ...this.state,
                location:{
                    name: "",
                    description: ""
                }
            });
        }        
    }

    componentDidUpdate = async (prevProps) => {
        const { selectedLocationId } = this.props
        if(prevProps.selectedLocationId !== this.props.selectedLocationId && 
            (typeof this.props.selectedLocationId) !== 'undefined' && this.props.selectedLocationId) {
            const selectedLocation = this.props.locations.find(function (location) {
                //if((typeof location.id) === 'undefined') return null;
                return location.id.toString() === selectedLocationId.toString();
              });
            this.setState({
                ...this.state,
                location: {
                    name: selectedLocation ? selectedLocation.name : "",
                    description: selectedLocation ? selectedLocation.description : ""
                },
                selectedLocationId: selectedLocationId ? selectedLocationId : ""
            });
        }
        if(prevProps.location !== this.props.location && (typeof this.props.location) !== 'undefined' 
        && this.props.location && (typeof this.props.location.locationDeleted) === 'undefined') {
            this.props.setLocations(this.state.locations);
            this.setState({
                ...this.state,
                location:{
                    name: this.props.location.locationAdded ? "" : this.state.location.name,
                    description: this.props.location.locationAdded ? "" : this.state.location.description,
                },
                locations: this.props.locations,
                locationAddedMessage: this.props.location.locationAddedMessage ? this.props.location.locationAddedMessage : "", //? this.state.location.name + " has been added." : "",//this.props.location.locationAddedMessage,
                locationUpdatedMessage: this.props.location.locationUpdatedMessage ? this.props.location.locationUpdatedMessage : "", //? "The location has been updated." : "",//this.props.location.locationUpdatedMessage,
                alertClass: (this.props.location.locationAdded || this.props.location.locationUpdated) ? this.state.baseAlertClass + "success" : this.state.baseAlertClass + "danger" ,
                showCreateLocationMessage: true,
                processing: false
            });
        }
    }

    handleCreateLocation = async () => {
        await this.setState({
            ...this.state,
            ...this.state.location,
            alertClass: "",
            showCreateLocationMessage: false
        });
        const { name, description } = this.state.location
        const formValid = await this.validateLocationForm(name, description);
        
        if(formValid) {
            this.setState({ processing: true })
            this.props.createNewLocation(this.state.location.name, this.state.location.description, 
                this.state.locations);   
        }
    }

    handleUpdateLocation = async () => {
        await this.setState({
            ...this.state,
            ...this.state.location,
            alertClass: "",
            showCreateLocationMessage: false
        });
        const { name, description } = this.state.location
        const formValid = await this.validateLocationForm(name, description);
        const { selectedLocationId } = this.state;
        if(formValid) {
            this.setState({ processing: true })
            this.props.updateLocation(selectedLocationId, name, 
                description, this.props.locations);
        }
    }

    handleCloseAlert = () => {
        this.props.setSelectedLocation(undefined);
        this.setState({
            ...this.state,
            location: {
                name: "",
                description: ""
            },
            selectedLocationId: "",
            nameError: "",
            descriptionError: "",
            baseAlertClass: "text-center alert alert-dismissible fade show alert-",
            alertClass: "",
            showCreateLocationMessage: false,
            locationAddedMessage: "",
            locationUpdatedMessage: "",
            processing: false,
        });
    }

    handleCloseNotification = () => {
        this.setState({
            baseAlertClass: "text-center alert alert-dismissible fade show alert-",
            alertClass: "",
            showCreateLocationMessage: false,
            locationAddedMessage: "",
            locationUpdatedMessage: "",
        })
    }

    validateLocationForm = async (name, description) => {
        const { locations } = this.props;
        const { selectedLocationId } = this.state;
        let isLocationValid = true;
        locations.forEach(location => {
            if(location.name.toLowerCase().trim() === name.toLowerCase().trim()) {
                if((typeof selectedLocationId) === 'undefined' || !selectedLocationId) {
                    isLocationValid = false;
                } else {
                    if(location.id.toString() !== selectedLocationId.toString()) {
                        isLocationValid = false;
                    }
                }
            }
        });
        let formValid = false;
        await this.setState({
            ...this.state,
            nameError: name && isTextValid(name) ? (!isLocationValid ? "Already exists a location with this name": "") : "Name is required",
            descriptionError: description && isTextValid(description) > 0 ? "" : "Description is required",
            locationAddedMessage: "",
            locationUpdatedMessage: "",
            showCreateLocationMessage: false,
        });
        formValid = this.state.nameError === "" && this.state.descriptionError === "";
        return formValid;
    }

    handleClickOutside = () => {
        this.handleCloseAlert();
    }

    render() { 
        const { modalId, modalTitleId } = this.props;
        const { selectedLocationId } = this.state;
        const isNewLocation = selectedLocationId === "";
        return ( 
            <React.Fragment>
                <LocalModal
                    modalName={modalId}
                    title={isNewLocation ? "Create Location" : "Update Location"}
                    processing={this.state.processing}
                    icon="fas fa-location-arrow"
                    onConfirm={() =>
                        isNewLocation ? this.handleCreateLocation() : this.handleUpdateLocation()
                    }
                    onClose={this.handleCloseAlert}
                >
                    <div className={ this.state.showCreateLocationMessage ? "" : "d-none" }>
                        <div className={ this.state.alertClass } role="alert">
                            { isNewLocation ? this.state.locationAddedMessage : this.state.locationUpdatedMessage }
                            <button type="button" className="close" onClick={ this.handleCloseNotification }>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                    </div>
                        <div>
                            <div className="row">
                                <div className="col-md-6 col-sm-12 col-xs-12">
                                    <Textbox 
                                        name = "name"
                                        value={this.state.location.name || ''}
                                        errorText={this.state.nameError}
                                        inputLabel="Name:" 
                                        inputType="text" 
                                        inputPlaceholder="Name" 
                                        inputClass="form-control"
                                        handleTextboxChange={e=> this.handleTextboxChange(e)}
                                        errorMessage={this.state.nameError}
                                        errorLabelClass="text-danger"
                                        required/>
                                </div>
                                <div className="col-md-6 col-sm-12 col-xs-12">
                                    <Textbox 
                                        name = "description"
                                        value={this.state.location.description || ''}
                                        errorText={this.state.nameError}
                                        inputLabel="Description:" 
                                        inputType="text" 
                                        inputPlaceholder="Description" 
                                        inputClass="form-control"
                                        handleTextboxChange={e=> this.handleTextboxChange(e)}
                                        errorMessage={this.state.descriptionError}
                                        errorLabelClass="text-danger"
                                        required />
                                </div>
                            </div>
                        </div>
            </LocalModal>
            </React.Fragment>
         );
    }
}


 const mapStateToProps = state => {
    return {
        location: state.locationsStore.location,
        selectedLocationId: state.locationsStore.selectedLocationId,
        locations: state.locationsStore.locations
     };
   };
  
  const mapDispatchToProps = dispatch => ({
    setLocations: (locations) => dispatch(setLocations(locations)),
    createNewLocation: (name, description, locations) => dispatch(createNewLocation(name, description, locations)),
    updateLocation: (id, name, description, locations) => dispatch(updateLocation(id, name, description, locations)),
    setSelectedLocation: (locationId) => dispatch(setSelectedLocation(locationId))
  });
  
  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(onClickOutside(LocationsModal));