import { Services } from '../api/services';

export const isBankFormRequired = (userTypeId) => {
    return true;
    // if((typeof userTypeId) !== 'undefined' && userTypeId !== null) {
    //     userTypeId = userTypeId.toString();
    //     return userTypeId === "1" || userTypeId === "2" || userTypeId === "3" 
    //         || userTypeId === "-LsTSlUrmr8r_hCqcvQY";
    // } else {
    //     return true;
    // }
}
//isDependent, isIndependent, isAdministrator, isAnExternalDeveloper
export const isDependent = async (userTypeId) => {
    const userTypes = await Services.GeneralApi.getUserTypes().catch(_ => {
        return [];
    });
    let isDep = false
    if((typeof userTypeId) !== 'undefined' && userTypeId !== null) {
        userTypeId = userTypeId.toString();
        userTypes.forEach(ut=> {
            if((typeof ut) !== 'undefined' && ut !== null && userTypeId === ut.id.toString() && ut.name === 'Dependiente') {
                isDep = true
            }
        })
    }
    return isDep;
}

export const isIndependent = async (userTypeId) => {
    const userTypes = await Services.GeneralApi.getUserTypes().catch(_ => {
        return [];
    });
    let isIndep = false
    if((typeof userTypeId) !== 'undefined' && userTypeId !== null) {
        userTypeId = userTypeId.toString();
        userTypes.forEach(ut=> {
            if((typeof ut) !== 'undefined' && ut !== null && userTypeId === ut.id.toString() && ut.name === 'Independiente') {
                isIndep = true
            }
        })
    }
    return isIndep;
}

// export const isAdministrator = userTypeId => {
//     if((typeof userTypeId) !== 'undefined' && userTypeId !== null) {
//         userTypeId = userTypeId.toString();
//         return userTypeId === "3";
//     }
//     return false;
// }

// export const isAnExternalDeveloper = userTypeId => {
//     if((typeof userTypeId) !== 'undefined' && userTypeId !== null) {
//         userTypeId = userTypeId.toString();
//         return userTypeId === "4";
//     }
//     return false;
// }

// export const generateRandomPassword = () => {
//     return Math.random().toString(36).slice(-8);
// }

export const getPropertyValue = (value) => {
    if((typeof value) !== 'undefined' && value !== null) return value;
    return "";
}

export const getUserFromProps = (data, propsUserData) => {
    data = (typeof data) !== 'undefined' && data !== null ? data : propsUserData;
    if((typeof data) === 'undefined' || data === null) data = {};
    let user = {
        id: getPropertyValue(data.id),
        firstName: getPropertyValue(data.firstName),
        lastName: getPropertyValue(data.lastName),
        email: getPropertyValue(data.email),
        personalEmail: getPropertyValue(data.personalEmail),
        password: getPropertyValue(data.password),
        calendarBirthDate: getPropertyValue(data.birthDate) === "" ? new Date() : new Date(data.birthDate),
        birthDate: getPropertyValue(data.birthDate) === "" ? new Date().toLocaleDateString() : data.birthDate,
        homeAddress: getPropertyValue(data.homeAddress),
        cellularNumber: getPropertyValue(data.cellularNumber),
        identificationType: getPropertyValue(data.identificationType) === "" ? "DNI" : data.identificationType,
        identificationNumber: getPropertyValue(data.identificationNumber),
        nationality: getPropertyValue(data.nationality),
        file: getPropertyValue(data.file) === "" ? undefined : data.file,
        locationId: getPropertyValue(data.locationId),
        needsToFillRegister: getPropertyValue(data.needsToFillRegister),
        userRoleId: getPropertyValue(data.userRoleId),
        userStatusTypeId: getPropertyValue(data.userStatusTypeId),
        userTypeId: getPropertyValue(data.userTypeId)
    };
    if(getPropertyValue(user.file) === "") delete user.file;
    return user;
}

export const areArraysEqual = (a, b, areEqualItems) => {
    if(a === b) return true;
    if(a === null || b === null) return false;
    if(a.length !== b.length) return false;
    for(let i = 0; i < a.length; i++) {
        if(a[i] !== b[i]) return false;
    }
}

export const device = {
    exsm:'(max-width: 575px)',
    sm: '(min-width: 576px) and (max-width: 767px)',
    md: '(min-width: 768px) and (max-width: 991px)',
    lg: '(min-width: 992px) and (max-width: 1200px)',
    exlg:'(min-width: 1201px)',
  };