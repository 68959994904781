const initialState = {
  inventoryItems: [],
  selectedItem: undefined
};

const inventoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_ITEM":
        return  {
          ...state,
          selectedItem: action.payload.item,
          inventoryItems: action.payload.inventoryItems
      }
    case "SET_ITEM_USER":
      return  {
        ...state,
        itemUserUpdated: action.payload.item,
        inventoryItems: action.payload.inventoryItems
      }
    case "SET_ITEM_ERROR":
      return {
        ...state,
        ...state.inventoryItems,
        selectedItem: action.item
      }
    case "SET_INVENTORY_ITEMS":
      return {
        ...state,
        inventoryItems: action.inventoryItems
      };

    case "SET_SELECTED_ITEM":
      let selectedItem = state.inventoryItems.find(function(item) {
        return item.id === action.selectedItemId;
      });
      return {
          ...state, 
          selectedItem: selectedItem 
        };
      case 'REMOVE_ITEM':
        let itemToRemove = state.inventoryItems.find(item=> {
          if(item.id === action.inventoryItem.id) {
            return item;
          } return undefined
             
        });
        let newInventoryItems = [...state.inventoryItems];
        let index = newInventoryItems.indexOf(itemToRemove);
        if (index !== -1) {
            newInventoryItems.splice(index, 1);
            return {
                ...state,
                selectedItem: action.inventoryItem,
                inventoryItems: newInventoryItems
              }
        }
        else{
            return {
                ...state,
                selectedItem: action.inventoryItem,
                ...state.inventoryItems
              }
        }
      case 'REMOVE_ITEM_ERROR_MESSAGE':
        return {
          ...state,
          selectedItem: action.inventoryItem
        }

    default:
      return state;
  }
};

export default inventoryReducer;
