const initialState = {
    userTypes: [],
    selectedUserTypeId: undefined
};

const userTypeReducer = (state = initialState, action) => {
    switch (action.type) {
        case "SET_USER_TYPES":
          return {
            ...state,
            userTypes: action.userTypes
          };
        case "SET_USER_TYPE_ID":
          return {
            ...state,
            selectedUserTypeId: action.selectedUserTypeId
          }
          case 'SET_NEW_USER_TYPE':
                return  {
                    ...state,
                    userType: action.payload.userType,
                    userTypes: action.payload.userTypes
                }
            case 'SET_NEW_USER_TYPE_ERROR':
                return  {
                    ...state,
                    userType: action.userType
                }
            case 'SET_UPDATED_USER_TYPE':
                return  {
                    ...state,
                    userType: action.payload.userType,
                    userTypes: action.payload.userTypes
                }
            case 'SET_UPDATED_USER_TYPE_ERROR':
                return  {
                    ...state,
                    userType: action.userType
                }
            case 'REMOVE_USER_TYPE':
                let userTypeToRemove = state.userTypes.find(userType=> {
                    if(userType.id === action.userType.id) {
                        return userType;
                    } 
                    return undefined;
                });
                let newUserTypes = [...state.userTypes];
                if((typeof userTypeToRemove) !== 'undefined') {
                    let index = newUserTypes.indexOf(userTypeToRemove);
                    if (index !== -1) {
                        newUserTypes.splice(index, 1);
                        return {
                            ...state,
                            ...state.selectedUserTypeId,
                            userTypes: newUserTypes,
                            userType: action.userType
                        }
                    }
                } else {
                    return {
                        ...state,
                        ...state.selectedUserTypeId,
                        ...state.newUserTypes,
                        userType: action.userType
                    }
                }
                break;
            case 'REMOVE_USER_TYPE_ERROR_MESSAGE':
                return {
                    ...state,
                    userType: action.userType
                }
          default:
            return state;
    }
}
export default userTypeReducer;