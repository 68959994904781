import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  setProfileImage,
  setUser,
  updateUsersInStore,
} from '../../actions/user-actions';
import Cookies from 'universal-cookie';
import { Services } from '../../api/services';
import { isTextValid } from '../../utilities/string.utilities';
import { getUserFromProps } from '../../utilities/utilities';
import PersonalFormData from './registerForm/personal-form-data';
import PersonalFormDataStep2 from './registerForm/personal-form-data-step-2';
import BankFormData from './registerForm/bank-form-data';
import IndependentFormData from './registerForm/independent-form-data';
import DependentFormData from './registerForm/dependent-form-data';
import ChangePasswordFormData from './registerForm/change-password-form-data';
import { getUserType, getPropertyValue } from './registerForm/userUtilities';
import {
  StepContainer,
  StepHeaderProfile,
} from './registerForm/step-container';
import MultimediaFormData from './registerForm/multimedia-form-data';
import { TitlePanel } from '../common/LocalPanels';

class Profile extends Component {
  constructor(props) {
    super(props);

    this.goToHome = this.goToHome.bind(this);
    this.state = {
      users: [],
      locations: [],
      user: {
        firstName: '',
        lastName: '',
        email: '',
        userEmail: '',
        password: '',
        userStatus: '',
        locationId: '',
        actualPassword: '',
        newPassword: '',
        confirmEmail: '',
        confirmPassword: '',
        changeEmailPassword: '',
      },
      emailError: '',
      confirmEmailError: '',
      changeEmailPasswordError: '',
      firstNameError: '',
      lastNameError: '',
      actualPasswordError: '',
      newPasswordError: '',
      confirmPasswordError: '',
      baseAlertClass: 'text-center alert alert-dismissible fade show alert-',
      alertClass: '',
      showProfileUpdatedMessage: false,
      profileUpdatedMessage: '',
      selectedUserId: '',
      processing: false,
      userLocationButtonText: '',
      selectedLocationId: 1,
      changePassword: false,
      file: {},
      uploadImage: false,
      profileImage: '',
      editMode: false,
      changeEmail: false,
      saved: false,
      step: 1,
      role: {
        id: '',
        name: '',
      },
    };
  }

  setUserIntoState = (usr) => {
    let user = getUserFromProps(usr);
    if (!user.file) {
      user.file = {};
      user.file.url = require('../../assets/images/default-user.png');
      this.setState({ ...this.state, user, userId: user.id });
    } else {
      if (!user.file.url) {
        const userFile =
          getPropertyValue(user.file) !== ''
            ? user.id + '/' + user.file.name
            : '';
        Services.Users.downloadImage(userFile)
          .then((data) => {
            if (getPropertyValue(data) !== '') {
              user.file.url = data;
              const userId = new Cookies().get('1950SystemUserId');
              if (user.id === userId) this.props.setProfileImage(data);
            } else {
              user.file = {};
              user.file.url = require('../../assets/images/default-user.png');
            }
            this.setState({ ...this.state, user, userId: user.id });
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        this.setState({ ...this.state, user, userId: user.id });
      }
    }
  };
 
  getUser = async (userId) => {
    if (typeof userId !== 'undefined' && userId && userId !== '') {      
      const user = await Services.Users.getUser(userId)
        .then((response) => {
          return response.val();
        })
        .catch((error) => {
          console.log(error);
        });
      if (getPropertyValue(user) !== '') {
        this.setUserIntoState(user);
        const role = await getUserType(user.id);
        await this.setState({
          ...this.state,
          role,
        });
      }
    }
  };
  componentDidMount = async () => {    
    if (
      typeof this.props.location !== 'undefined' &&
      this.props.location &&
      typeof this.props.location.state !== 'undefined' &&
      this.props.location.state &&
      typeof this.props.location.state.user !== 'undefined' &&
      this.props.location.state.user
    ) {
      this.setUserIntoState(this.props.location.state.user);
      const userId = new Cookies().get('1950SystemUserId');
      const role = await getUserType(userId);
      await this.setState({
        ...this.state,
        role,
      });
    } else {
      if (this.state.userId === undefined || this.state.userId === '') {
        const userId = new Cookies().get('1950SystemUserId');
        this.getUser(userId);
      }
    }
  };

  componentDidUpdate = async (prevProps) => {
    if (this.state.userId === undefined || this.state.userId === '') {
      const userId = new Cookies().get('1950SystemUserId');
      this.setState({
        ...this.state,
        userId,
      });
    }
  };

  onChangeImage = async (e) => {
    let files = e.target.files;
    if (files.length > 0) {
      const oldFileName =
        getPropertyValue(this.state.user.file) !== ''
          ? this.state.user.file.name
          : '';
      await Services.Users.uploadImageOnStorage(
        this.state.user.id,
        files[0],
        oldFileName
      )
        .then(async (response) => {
          if (response.success === true) {
            const file = {
              name: files[0].name,
              type: files[0].type,
              url: response.downloadUrl,
            };
            const fileResponse = await Services.Users.updateUserFileOnly(
              this.state.user.id,
              file
            );
            if (fileResponse.success === true) {
              //TODO: update users and user variables in the store
              if (getPropertyValue(fileResponse.user) !== '') {
                let users = this.props.users;
                if (getPropertyValue(users) !== '') {
                  users = users.map((user) => {
                    if (user.id === this.state.userId) {
                      user = fileResponse.user;
                    }
                  });
                } else {
                  users = [];
                  users.push(fileResponse.user);
                }
                const userId = new Cookies().get('1950SystemUserId');
                if (fileResponse.user.id === userId)
                  this.props.setUser(users, fileResponse.user);
                else {
                  this.props.updateUsersInStore(users);
                }
              }
              if (
                getPropertyValue(file) !== '' &&
                getPropertyValue(file.url) !== ''
              ) {
                this.props.setProfileImage(file.url);
              }
              this.setState({
                ...this.state,
                user: {
                  ...this.state.user,
                  file: {
                    ...this.state.user.file,
                    name: file.name,
                    url: file.url,
                  },
                },
              });
              this.showNotification(
                'Profile image uploaded successfully',
                'success'
              );
            } else {
              this.showNotification(
                'Error trying to upload profile image',
                'danger'
              );
            }
          } else {
            this.showNotification(
              'Error trying to upload profile image',
              'danger'
            );
          }
        })
        .catch((error) => {
          this.showNotification(
            'Error trying to upload profile image',
            'danger'
          );
        });
    }
  };

  handleCloseNotification = () => {
    this.setState({
      baseAlertClass: 'text-center alert alert-dismissible fade show alert-',
      alertClass: '',
      showProfileUpdatedMessage: false,
      message: '',
    });
  };

  goToHome = () => {
    this.props.history.push('/app');
  };

  changeStep = (step) => {
    if (this.state.step !== step) {
      this.setState({
        ...this.state,
        step,
      });
    }
  };

  showNotification = (message, type) => {
    if (type === 'success' || type === 'danger') {
      this.setState({
        ...this.state,
        message,
        alertClass: this.state.baseAlertClass + type,
      });
    }
  };

  onChangePassword = async (user) => {
    let oldPasswordError = '',
      confirmPasswordError = '',
      newPasswordError = '';
    const { oldPassword, newPassword, confirmPassword } = user;

    const isPasswordOk =
      isTextValid(oldPassword) &&
      (await Services.Users.isPasswordCorrect(oldPassword));

    if (!isPasswordOk) {
      oldPasswordError = 'The password is incorrect';
      this.showNotification(oldPasswordError, 'danger');
    } else {
      if (newPassword !== confirmPassword) {
        confirmPasswordError =
          'The confirm password does not match with the new password';
        this.showNotification(confirmPasswordError, 'danger');
      } else {
        if(newPassword !== undefined) {
          if (newPassword.trim().length >= 8) {
            const email = Services.Users.getEmail();
            Services.Users.changePassword(email, oldPassword, newPassword).then(
              (response) => {
                if (!response || response.success === false) {
                  this.showNotification(response.message, 'danger');
                } else {
                  if (response.success === true) {
                    this.showNotification(response.message, 'success');
                  }
                }
              }
            );
          } else {
            newPasswordError =
              'The password has to have 8 digits or more (space not counts as digit)';
            this.showNotification(newPasswordError, 'danger');
          }
        }
      }
    }
  };

  updateUser = async (user) => {
    // console.log('User data',user);       
    await this.setState({ processing: true });
    delete user.calendarBirthDate;
    delete user.password;
    let message = { userUpdated: false, message: '' };
    try {
      user.oldPassword && this.onChangePassword(user);
       
      delete user.confirmPassword;
      delete user.newPassword;
      delete user.oldPassword;
      delete user.uploadImage;
      delete user.userAdded;
      delete user.userStatus;
      var userToSave = { ...user };      

      if(this.state.step == 1){
        // Remove Contact
        delete userToSave.location;
        delete userToSave.homeAddress;
        delete userToSave.locationId;
        delete userToSave.cellularNumber;
        delete userToSave.personalEmail 
      } else if (this.state.step == 2){
        // Removde Personal Data
        delete userToSave.firstName;
        delete userToSave.lastName;
        delete userToSave.birthDate;
        delete userToSave.nationality;
      }             
      // console.log('User to save',userToSave);

      const response = await Services.Users.updateProfile(userToSave)
        .then((data) => {
          if (typeof data !== 'undefined' && data !== null) {
            console.log('data',data);
            if (data.userUpdated) {

              data = {...user,...data};
              // console.log('Data merged',data);

              data.users.forEach((userData) => {
                if (userData.id === data.id) {
                  userData.email = data.email;
                  userData.firstName = data.firstName;
                  userData.lastName = data.lastName;
                  userData.locationId = user.locationId ? user.locationId : 1;
                  if (data.uploadImage) {
                    userData['file'] = data.file;
                  }
                }
              });

              message.userUpdated = data.userUpdated;
              message.message = data.userUpdatedMessage;

              const users = data.users;
              delete data.users;
              delete data.userUpdated;
              delete data.userUpdatedMessage;
              const userId = new Cookies().get('1950SystemUserId');
              if (data.id === userId) this.props.setUser(users, data);
              else {
                this.props.updateUsersInStore(users);
              }
              this.setState({ processing: false });
              return data;
            } else {
              message.userUpdated = false;
              message.message = data.userUpdatedMessage;
            }
          } else {
            message.userUpdated = false;
            message.message = 'Error when updating the user profile';
          }
          this.setState({ processing: false });
          return message;
        })
        .catch((error) => {
          message.userUpdated = false;
          message.message = 'Error when updating the user profile';
          this.setState({ processing: false });
          return message;
        });
    } catch (err) {
      message.userUpdated = false;
      message.message = 'Error when updating the user profile';
      this.setState({ processing: false });
      return message;
    }

    if (message.userUpdated) {
      this.showNotification(message.message, 'success');
    } else {
      this.showNotification(message.message, 'danger');
    }
  };

  clearNotification=()=>{
    this.setState({message:null});
  }

  updateLocalUser = (user) => {
    this.setState({ user: { ...this.state.user, ...user } });
  };

  render() {
    return (
      <React.Fragment>
        <TitlePanel
          {...this.props}
          icon="far fa-user"
          title="Profile"
        ></TitlePanel>
        <div className="container">
          <div
            className={this.state.message ? '' : 'd-none'}
            style={{ "minHeight": '50px', marginTop: '20px' }}
          >
            <div
              className={this.state.alertClass}
              style={{ "minHeight": '50px' }}
              role="alert"
            >
              {this.state.message}
              <button
                type="button"
                className="close"
                onClick={this.handleCloseNotification}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
          </div>
        </div>
        <StepHeaderProfile
          step={this.state.step}
          onChangeStep={this.changeStep}
        />
        <StepContainer>
          {this.state.step === 1 && (
            <PersonalFormData
              updateLocalUser={this.updateLocalUser}
              updateUser={this.updateUser}
              user={this.state.user}
              title="Personal Data"
              nextText="Save Changes"
              handleCloseNotification={this.handleCloseNotification}
              profile
            />
          )}
          {this.state.step === 2 && (
            <PersonalFormDataStep2
              updateLocalUser={this.updateLocalUser}
              updateUser={this.updateUser}
              user={this.state.user}
              title="Contact"
              nextText="Save Changes"
              handleCloseNotification={this.handleCloseNotification}
              profile
            />
          )}
          {this.state.step === 3 &&
            this.state.role &&
            this.state.role.name &&
            (this.state.role.name === "Independiente" || this.state.role.name === "Operaciones-Factura") && (
              <IndependentFormData
                user={this.state.user}
                next={this.next}
                notify={this.showNotification}
                title="Complete your company information"
                nextText="Save Changes"
                handleCloseNotification={this.handleCloseNotification}
                profile
                notRequired = {false}
              />
            )}
          {this.state.step === 3 &&
            this.state.role &&
            this.state.role.name &&
            (this.state.role.name === "Dependiente" || this.state.role.name === "Operaciones-Nómina") && (
              <DependentFormData
                user={{id: this.state.user.id}}
                userTypeId={this.state.role.id}
                notify={this.showNotification}
                next={this.next}
                nextText="Save Changes"
                title="Upload your employee information"
                handleCloseNotification={this.handleCloseNotification}
                profile
              />
            )}
          {this.state.step === 4 && (
            <BankFormData
              user={this.state.user}
              notify={this.showNotification}
              title="Complete your bank data so we can pay you"
              next={this.next}
              nextText="Save Changes"
              handleCloseNotification={this.handleCloseNotification}
              profile
              notRequired = {false}
            />
          )}
          {this.state.step === 5 && (
            <MultimediaFormData              
              updateLocalUser={this.updateLocalUser}
              updateUser={this.updateUser}
              user={this.state.user}
              title="Now it's time to upload some files"
              next={this.next}
              toHome={this.goToHome}
              notify={this.showNotification}
              nextText="Save Changes"
              handleCloseNotification={this.handleCloseNotification}
              profile
            />
          )}
        </StepContainer>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    users: state.usersStore.users,
    user: state.usersStore.user,
    selectedUserId: state.usersStore.selectedUserId,
    locations: state.locationsStore.locations,
    profileImage: state.usersStore.profileImage,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setProfileImage: (imageUrl) => dispatch(setProfileImage(imageUrl)),
  setUser: (users, user) => dispatch(setUser(users, user)),
  updateUsersInStore: (users) => dispatch(updateUsersInStore(users)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
