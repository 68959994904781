import React from 'react';

function Navbar(props) {
    return (
        <div className="navbar">
                  <button onClick={props.linkToHome} type="button" className="float-left btn btn-sm btn-light m-2" >{ props.goBackButton ? props.goBackButton : "HOME" }</button>
                  <div className="text-left mt-2 float-right" style={{color: 'white', display: 'flex' }}>
                      {
                          (typeof props.buttons) !== 'undefined' && props.buttons ? props.buttons.map(button => {
                              if((typeof button.dataToggle) !== 'undefined' && button.dataToggle && button.dataToggle.length > 0 
                              && (typeof button.dataTarget) !== 'undefined' && button.dataTarget && button.dataTarget.length > 0) {
                                return (
                                    <button key={button.id} onClick={button.onClick} type="button" className={button.className} data-toggle={button.dataToggle} data-target={button.dataTarget}>
                                        {button.title}
                                    </button>
                                );
                              } else {
                                  if((typeof button.buttons) !== 'undefined' && button.buttons !== null) {
                                    return (
                                        <div className="dropdown"  key={button.id}>
                                            <button key={button.id} className="btn btn-sm btn-primary dropdown-toggle" type="button" id="dropdownMenu2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" >
                                            { button.title }
                                            </button>
                                            <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenu2" >
                                                {button.buttons.map(childButton => {
                                                    return( 
                                                        <button
                                                            key={childButton.id} onClick={childButton.onClick} className={childButton.className} type="button" value={childButton.value} >
                                                            {childButton.title}
                                                        </button>
                                                        )
                                                })}
                                            
                                            </div>
                                        </div>
                                    )
                                  } else {
                                      if((typeof button.hide) === 'undefined' || (button.hide !== null && !button.hide)) {
                                        return(
                                            <button key={button.id} onClick={button.onClick} type="button" className={button.className}>
                                                {button.title}
                                            </button>
                                        );
                                      } else {
                                          return("");
                                      }
                                    
                                  }
                                
                              }
                          })
                          : ""
                      }
                      
                      
                  </div>
                </div>
    );
}

export default Navbar;