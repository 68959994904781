import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Services } from '../../../api/services';
import { setLocations } from '../../../actions/location-actions';
import { setUser, updateUsersInStore } from '../../../actions/user-actions';
import Cookies from 'universal-cookie';
import { isTextValid } from '../../../utilities/string.utilities';
import {
  isBankFormRequired,
  isDependent,
  isIndependent,
} from '../../../utilities/utilities';
import PersonalFormData from './personal-form-data';
import ChangePasswordFormData from './change-password-form-data';
import DependentFormData from './dependent-form-data';
import IndependentFormData from './independent-form-data';
import BankFormData from './bank-form-data';
import Navbar from '../../main/navbar';
import { setGeneralEnums, setNavigationItems } from '../../../actions/general-actions';
import { withRouter } from 'react-router';
import { getUserType, getPropertyValue, getUserTypeByTypeId } from './userUtilities';
import { getUserFromProps } from '../../../utilities/utilities';
import { StepContainer, Welcome, StepHeader } from './step-container';
import PersonalFormDataStep2 from './personal-form-data-step-2';
import MultimediaFormData from './multimedia-form-data';
import { TransferWithinAStationOutlined } from '@material-ui/icons';
import auth from '../../classes/auth';
import moment from 'moment';

class RegisterForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      step: 0,
      user: {
        firstName: '',
        lastName: '',
        email: '',
        personalEmail: '',
        password: '',
        userStatus: '',
        locationId: '',
        birthDate: '',
        calendarBirthDate: new Date(),
        homeAddress: '',
        cellularNumber: '',
        identificationType: 'DNI',
        identificationNumber: '',
        nationality: '',
      },
      processing: false,
      formErrorMessage: '',
      alertClass: '',
      baseAlertClass: 'text-center alert alert-dismissible fade show alert-',
      alertErrorClass:
        'text-center alert alert-dismissible fade show alert-danger',
      alertSuccessClass:
        'text-center alert alert-dismissible fade show alert-success',
      specificData: {
        fileName: '',
        originalName: '',
        url: '',
      },
      showHomeButton: false,
      editMode: false,
      isUserIdSet: false,
      comeFromProfile: false,
      role: {
        id: '',
        name: '',
      },
    };
    this.getUser();
  }

  isRegisterFinished = (step) => {
    const userTypeId = this.state.user.userTypeId;
    return (
      step > 2 &&
      isDependent(userTypeId) === false &&
      isIndependent(userTypeId) === false
    );
  };

  onChangePassword = async (user) => {
    let oldPasswordError = '',
      confirmPasswordError = '',
      newPasswordError = '';
    const { oldPassword, newPassword, confirmPassword } = user;

    const isPasswordOk =
      isTextValid(oldPassword) &&
      (await Services.Users.isPasswordCorrect(oldPassword));

    if (!isPasswordOk) {
      oldPasswordError = 'The password is incorrect';
      this.showNotification(oldPasswordError, 'danger');
    } else {
      if (newPassword !== confirmPassword) {
        confirmPasswordError =
          'The confirm password does not match with the new password';
        this.showNotification(confirmPasswordError, 'danger');
      } else {
        if (newPassword.trim().length >= 8) {
          const email = Services.Users.getEmail();
          Services.Users.changePassword(email, oldPassword, newPassword).then(
            (response) => {
              if (!response || response.success === false) {
                this.showNotification(response.message, 'danger');
              } else {
                if (response.success === true) {
                  this.showNotification(response.message, 'success');
                }
              }
            }
          );
        } else {
          newPasswordError =
            'The password has to have 8 digits or more (space not counts as digit)';
          this.showNotification(newPasswordError, 'danger');
        }
      }
    }
  };

  getUser = async (userId) => {
    if (typeof userId !== 'undefined' && userId && userId !== '') {
      const user = await Services.Users.getUser(userId)
        .then((response) => {
          return response.val();
        })
        .catch((error) => {
          console.log(error);
        });
      if (user) {
        const birthDate =
          getPropertyValue(user.birthDate) === ''
            ? moment(new Date()).format('MM/DD/YYYY')
            : user.birthDate;
        const calendarBirthDate =
          getPropertyValue(user.birthDate) === ''
            ? new Date()
            : moment(user.birthDate, 'MM/DD/YYYY').toDate();

        this.setState({
          ...this.state,
          user: {
            ...this.state.user,
            id: user.id,
            firstName: user.firstName || '',
            lastName: user.lastName || '',
            homeAddress: user.homeAddress || '',
            cellularNumber: user.cellularNumber || '',
            personalEmail: user.personalEmail || '',
            identificationType: user.identificationType || '',
            identificationNumber: user.identificationNumber || '',
            nationality: user.nationality || '',
            birthDate,
            calendarBirthDate,
            email: user.email,
            locationId: user.locationId,
            needsToFillRegister: user.needsToFillRegister,
            userRoleId: user.userRoleId,
            userStatusTypeId: user.userStatusTypeId,
            userTypeId: user.userTypeId,
            file: {
              ...this.state.user.file,
              name:
                typeof user.file !== 'undefined' &&
                user.file !== null &&
                typeof user.file.name !== 'undefined' &&
                user.file.name !== ''
                  ? user.file.name
                  : '',
              url:
                typeof user.file !== 'undefined' &&
                user.file !== null &&
                typeof user.file.url !== 'undefined' &&
                user.file.url !== ''
                  ? user.file.url
                  : require('../../../assets/images/default-user.png'),
            },
          },
          userId,
        });
        console.log(user);
      }
    }
  };

  updateUser = async (user, step) => {
    await this.setState({ processing: true });
    delete user.calendarBirthDate;
    delete user.password;
    let message = { userUpdated: false, message: '' };
    try {
      if(step === 1)
        this.onChangePassword(user);

      delete user.confirmPassword;
      delete user.newPassword;
      delete user.oldPassword;
      delete user.uploadImage;
      delete user.userAdded;
      delete user.userStatus;
      delete user.needsToFillRegister;
      const response = await Services.Users.updateProfile(user)
        .then((data) => {
          if (typeof data !== 'undefined' && data !== null) {
            if (data.userUpdated) {
              data.users.forEach((userData) => {
                if (userData.id === data.id) {
                  userData.email = data.email;
                  userData.firstName = data.firstName;
                  userData.lastName = data.lastName;
                  userData.locationId = user.locationId ? user.locationId : 1;
                  if (data.uploadImage) {
                    userData['file'] = data.file;
                  }
                }
              });

              message.userUpdated = data.userUpdated;
              message.message = data.userUpdatedMessage;

              const users = data.users;
              delete data.users;
              delete data.userUpdated;
              delete data.userUpdatedMessage;
              const userId = new Cookies().get('1950SystemUserId');
              if (data.id === userId) this.props.setUser(users, data);
              else {
                this.props.updateUsersInStore(users);
              }
              this.setState({ processing: false });
              return data;
            } else {
              message.userUpdated = false;
              message.message = data.userUpdatedMessage;
            }
          } else {
            message.userUpdated = false;
            message.message = 'Error when updating the user profile';
          }
          this.setState({ processing: false });
          return message;
        })
        .catch((error) => {
          message.userUpdated = false;
          message.message = 'Error when updating the user profile';
          this.setState({ processing: false });
          return message;
        });
    } catch (err) {
      message.userUpdated = false;
      message.message = 'Error when updating the user profile';
      this.setState({ processing: false });
      return message;
    }

    if (message.userUpdated) {
      this.showNotification(message.message, 'success');
    } else {
      this.showNotification(message.message, 'danger');
    }
  };

  updateLocalUser = (user) => {
    this.setState({ user: { ...this.state.user, ...user } });
  };

  createUser = (user) => {
    console.log(user);
    this.setState({ processing: true });
    this.existsUser(user.email).then(existsUserWithEmail => {
      if(existsUserWithEmail.success === true) {
        this.showNotification("Already exists an user with this email: " + user.email, 'danger');
      } else {
        console.log(this.state.registrationCode);
        this.getRegistrationInfo(this.state.registrationCode).then(registrationInfo => {
          console.log(registrationInfo);
          if(registrationInfo.success === true) {
            Services.Users.createNewUser(user.email, user.firstName, user.lastName, user.newPassword, user.userRoleId, user.userTypeId, 
              user.locationId, user.userStatusTypeId, false, [],user.nationality,user.birthDate).then(response => {
                console.log(response);
                const message = { userUpdated: response.userAdded, message: response.userAdded ? "User successfully registered!" : "Error registering user" };
                console.log(message)
                delete response.userAdded;
                delete response.userUpdated;
                delete response.userAddedMessage;
                delete response.needsToFillRegister;
                this.setState({
                  ...this.state,
                  user: response,
                  processing: false
                })
                if (message.userUpdated) {
                  this.showNotification(message.message, 'success');
                } else {
                  this.showNotification(message.message, 'danger');
                }
                Services.Users.deleteRegistrationInfo(this.state.registrationCode).then(response => {
                  console.log(response)
                  // if(response === true) {
                  //   this.setState({
                  //     ...this.state,
                  //     registrationCode: undefined
                  //   })
                  // }
                })
            }).catch(error => {
              this.showNotification("Error trying to register user", 'danger');
              this.setState({
                ...this.state,
                processing: false
              })
            })
          } else {
            this.showNotification("You are not allowed to register an user", 'danger');
              this.setState({
                ...this.state,
                processing: false
              })
          }
        })
      }
    });
  }

  existsUser = (email) => {
    return Services.Users.getUsers().then(data => {
        if(data.val() !== null) {
          const response = data.val();
          if(response) {
              for (var key in response) {
                if(response[key].email === email) {
                  return { success: true, message: 'Already exists an user with this email' };
                }
              }
          }
        }
        return { success: false };
    })
    .catch(_ => {
      return { success: false, message: 'Error getting users' };
    })
  }

  getRegistrationInfo = (registrationCode) => {
    return Services.Users.getRegistrationInfo(registrationCode).then(response => {
      console.log(response);
      if(response.success === false) {
        return response;
      } else {
        response = response.val();
        console.log(response);
        return { success: true, response }
      }
    })
  }

  componentDidMount = async () => {
    let userId = 0;
    console.log(this.props.location);
    if (
      typeof this.props.location !== 'undefined' &&
      this.props.location &&
      typeof this.props.location.state !== 'undefined' &&
      this.props.location.state &&
      typeof this.props.location.state.registrationCode !== 'undefined' &&
      this.props.location.state.registrationCode
    ) {
      const registrationCode = this.props.location.state.registrationCode;
      console.log(registrationCode);
      if(typeof(registrationCode) === 'undefined' || registrationCode === null || registrationCode.length === 0) this.props.history.push('/');
      this.getRegistrationInfo(registrationCode).then(registrationInfo => {
        console.log("GET REGISTRATION INFO")
        console.log(registrationInfo)
        if(registrationInfo.success === false) {
          this.setState({
            ...this.state,
            message: 'Error getting user information',
            alertClass: this.state.alertErrorClass
          })
          setTimeout(() => {
            this.props.history.push('/');
          }, 2000)
        } else {
          if(registrationInfo.response && registrationInfo.response.id) {
            getUserTypeByTypeId(registrationInfo.response.userTypeId).then((role)=>{
              this.setState({
                ...this.state,
                user: { ...registrationInfo.response, registrationCode },
                registrationCode,
                role
              })
            });            
          }
        }
      })
    }
    // if (
    //   typeof this.props.location !== 'undefined' &&
    //   this.props.location &&
    //   typeof this.props.location.state !== 'undefined' &&
    //   this.props.location.state &&
    //   typeof this.props.location.state.user !== 'undefined' &&
    //   this.props.location.state.user
    // ) {
    //   let user = getUserFromProps(this.props.location.state.user);
    //   userId = user.id;
    //   if (!user.file) {
    //     user.file = {};
    //     user.file.url = require('../../../assets/images/default-user.png');
    //   }
    //   if (this.state.user && user && this.state.user.id !== user.id)
    //     this.setState({ ...this.state, user, userId: user.id });
    // } else {
    //     if (this.state.userId === undefined || this.state.userId === '') {
    //       userId = new Cookies().get('1950SystemUserId');
    //       this.getUser(userId);
    //     }
    // }
    if (typeof userId !== 'undefined' && userId && userId !== '' && userId !== 0){
      const role = await getUserType(userId);
      if (typeof role !== 'undefined' && role && userId !== ''){
        this.setState({
          ...this.state,
          role,
        });
      }
    }      
  };

  finishRegister = async () => {
    console.log("enter into finish register")
    let users = await Services.Users.getUsers()
      .then((response) => {
        let usersAux = [];
        response = response.val();
        for (var key in response) {
          usersAux.push(response[key]);
        }
        return usersAux;
      })
      .catch((error) => {
        console.log(error);
      });
      console.log("userId: "+this.state.user.id)
    await Services.Users.getUser(this.state.user.id).then(async (user) => {
      user = user.val();
      if (getPropertyValue(user) !== '') {
        console.log("entre al if de getPropertyValue de user distinto de vacio y voy a actualizar el needsToFillRegister")
        user.needsToFillRegister = false;
        const updated = await Services.Users.uploadUserData(user);
        if (updated === true) {
          this.showNotification('User was successfully registered', 'success');
          console.log("user successfully registered");
          // setTimeout(() => {
          //   this.props.history.push('/app');
          // }, 3000);
        } else {
          this.showNotification('Error trying to update user', 'danger');
          console.log("error trying to update user");
        }
        users = getPropertyValue(users) === '' ? [] : users;
        users = users.map((userAux) => {
          if (userAux.id === user.id) userAux = user;
        });
        //const userId = new Cookies().get('1950SystemUserId');
        //if (user.id === userId) 

        // Login user
        auth.login(() => {}, user.id);

        this.props.setUser(users, user);
        await Services.Users.getRoleNavigationInfo(user).then(data => {
          console.log("navigation items");
          console.log(data);
          if(data.navigation && data.navigation.length > 0) {
            this.props.setNavigationItems(data.navigation);
          }
          setTimeout(()=> {
            this.props.history.push('/app')
          }, 1000)
        })
        // else {
        //   this.props.updateUsersInStore(users);
        // }
      }
    });
  };

  handleCloseNotification = () => {
    this.setState({
      baseAlertClass: 'text-center alert alert-dismissible fade show alert-',
      alertClass: '',
      showProfileUpdatedMessage: false,
      message: '',
    });
  };

  changeStep = (step) => {
    this.setState({
      step,
    });
  };

  showNotification = (message, type) => {
    if (type === 'success' || type === 'danger') {
      this.setState({
        ...this.state,
        message,
        alertClass: this.state.baseAlertClass + type,
      });
    }
  };

  submitPersonalData = (user) => {
    this.setState({
      ...this.state,
      user,
    });
  };

  next = () => {
    console.log('Current step: ',this.state.step);
    console.log('Next step: ',this.state.step + 1);
    this.changeStep(this.state.step + 1);
  };

  goToHome = () => {
    this.props.history.push('/');
  };

  render() {
    const { step, role = { name: '' } } = this.state;
    return (
      <React.Fragment>
        <div className="container">
          <div
            className={this.state.message ? '' : 'd-none'}
            style={{ "minHeight": '50px', marginTop: '20px' }}
          >
            <div
              className={this.state.alertClass}
              style={{ "minHeight": '50px' }}
              role="alert"
            >
              {this.state.message}
              <button
                type="button"
                className="close"
                onClick={this.handleCloseNotification}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
          </div>
        </div>
        <StepHeader step={step} onChangeStep={this.changeStep} />
        <StepContainer>
          {step === 0 ? (
            <Welcome next={this.next} />
          ) : step === 1 ? (
            <PersonalFormData
              updateLocalUser={this.updateLocalUser}
              updateUser={this.state.registrationCode ? this.createUser : this.updateUser}
              user={this.state.user}
              next={this.next}
              title="Tell us your name and birthday!"
              handleCloseNotification={this.handleCloseNotification}
              registrationCode={this.state.registrationCode}
            />
          ) : step === 2 ? (
            <PersonalFormDataStep2
              updateLocalUser={this.updateLocalUser}
              updateUser={this.updateUser}
              user={this.state.user}
              next={this.next}
              title="Tell us how to contact you if we need it to"
              handleCloseNotification={this.handleCloseNotification}
            />
          ) : step === 3 ? (
            (role.name === "Independiente" || role.name === "Operaciones-Factura") ? (
              <IndependentFormData
                user={this.state.user}
                next={this.next}
                notify={this.showNotification}
                title="Complete your company information"
                handleCloseNotification={this.handleCloseNotification}
                notRequired={true}
              />
            ) : (
              (role.name === "Dependiente" || role.name === "Operaciones-Nómina") ? (
                <DependentFormData
                  user={{id: this.state.user.id}}
                  userTypeId={this.state.role.id}
                  notify={this.showNotification}
                  next={this.next}
                  title="Upload your employee information"
                  handleCloseNotification={this.handleCloseNotification}
                />
              ) : this.next()
            )
          ) : step === 4 ? (
            <BankFormData
              user={this.state.user}
              notify={this.showNotification}
              title="Complete your bank data so we can pay you"
              next={this.next}
              handleCloseNotification={this.handleCloseNotification}
              notRequired={true}
            />
          ) : step === 5 ? (
            <MultimediaFormData
              updateLocalUser={this.updateLocalUser}
              updateUser={this.updateUser}
              user={this.state.user}
              title="Now it's time to upload some files"
              next={this.next}
              toHome={this.goToHome}
              notify={this.showNotification}
              handleCloseNotification={this.handleCloseNotification}
              finishRegister={this.finishRegister}
              history={this.props.history}
              onboarding
            />
          ) : (
            <></>
          )}
        </StepContainer>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    users: state.usersStore.users,
    user: state.usersStore.user,
    selectedUserId: state.usersStore.selectedUserId,
    locations: state.locationsStore.locations,
    profileImage: state.usersStore.profileImage,
    registerFormResult: state.usersStore.registerFormResult,
    userBankData: state.usersStore.userBankData,
    bankData: state.usersStore.bankData,
    userSpecificFormData: state.usersStore.userSpecificFormData,
    userTypes: state.generalStore.userTypes,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setUser: (users, user) => dispatch(setUser(users, user)),
  updateUsersInStore: (users) => dispatch(updateUsersInStore(users)),
  setNavigationItems: (navigationItems) =>
    dispatch(setNavigationItems(navigationItems)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(RegisterForm)
);
