import Cookies from 'universal-cookie';
import { Services } from '../../api/services';

class Auth {
    constructor() {
        this.authenticated = false;
    }

    login(cb=()=>{}, userId) {
        const cookies = new Cookies();
        cookies.set('1950SystemLogged', true, { path: '/' });
        cookies.set('1950SystemUserId', userId, { path: '/' });
        this.authenticated = true;
        cb();
    }

    logout(cb=()=>{}) {
        const cookies = new Cookies();
        cookies.remove('1950SystemUserId', { path: '/' });
        cookies.remove('1950SystemLogged', { path: '/' });
        this.authenticated = false;
        cb();
    }

    isAuthenticated() {
        const firebaseUser = Services.Users.getUserLoggedIn();
        return firebaseUser !== undefined && firebaseUser !== null;
    }

    isAuthenticatedLocal() {        
        let logged = new Cookies().get('1950SystemLogged');
        let currentUserIdSession = new Cookies().get('1950SystemLogged');
        if(typeof currentUserIdSession !== 'undefined' &&
            currentUserIdSession !== null && logged == 'true'){
            // console.log('isAuthenticatedLocal',true);
            return true;
        } else{
            // console.log('isAuthenticatedLocal',false);
            return false;
        }            
      }
}

export default new Auth();