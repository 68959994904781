import {Services} from '../api/services';

export const getNavigationItems = () => {
    return (dispatch, getState) => {
        return Services.Roles.getNavigationItems().then((data)=> {
            let navigationItems = [];
            if(data !== null) {
                if(data.val() !== null) {
                    const response = data.val();
                    for (var key in response) {
                        navigationItems.push(response[key]);
                    }
                    dispatch({ type: 'SET_NAVIGATION_ITEMS', navigationItems });
                }
                dispatch({ type: 'SET_NAVIGATION_ITEMS', navigationItems });
            }
            dispatch({ type: 'SET_NAVIGATION_ITEMS', navigationItems });
        }); 
   };
}

export const getRoleNavigationItems = (roleId) => {
    return (dispatch, getState) => {
        Services.Roles.getRoleNavigationItems(roleId).then((data)=> {
            let roleNavigationItems = [];
            if(data !== null) {
                roleNavigationItems = data.navigation;
            } 
            dispatch({ type: 'SET_ROLE_NAVIGATION_ITEMS', roleNavigationItems });
        }); 
   };
}

export const setRoles = (roles) => {
    return (dispatch, getState) => {
        Services.Roles.getRoles().then((data)=>{
            if(data.val() !== null) {
                roles = [];
                let response = data.val();
                for (var key in response) {
                    getRoleNavItems(response, key, roles, dispatch)
                    
                }
                //dispatch({ type: 'SET_ROLES', roles: roles.sort((a,b) => (String(a.name).toLowerCase() > String(b.name).toLowerCase()) ? 1 : ((String(b.name).toLowerCase() > String(a.name).toLowerCase()) ? -1 : 0)) });
            } else {
                roles = [];
                dispatch({ type: 'SET_ROLES', roles });
            }}
        )
        .catch((error)=> {
            alert("Error while getting the roles!") 
            roles = [];
            dispatch({ type: 'SET_ROLES', roles });
        });
    }
};

const getRoleNavItems = (response, key, roles, dispatch) => {
    Services.Roles.getRoleNavigationItems(response[key].id, response[key]).then((data)=> {
        if(data !== null) {
            data.role.roleNavigationItems = data.navigation;
            roles.push(data.role);
            if(roles.length === Object.keys(response).length) {
                dispatch({ type: 'SET_ROLES', roles: roles.sort((a,b) => (String(a.name).toLowerCase() > String(b.name).toLowerCase()) ? 1 : ((String(b.name).toLowerCase() > String(a.name).toLowerCase()) ? -1 : 0)) });
            }
        } 
    });
}

export const setSelectedRole = (selectedRoleId) => {
    return (dispatch, getState) => {
        dispatch({ type: 'SET_ROLE_ID', selectedRoleId });      
   };
};

export const createNewRole = (name, description, navigationItems, roles) => {
    return (dispatch, getState) => {
        Services.Roles.createNewRole(name, description, navigationItems)
        .then((data)=>{
            let role = {};
            if(data) {
                role = {
                    id: data.id,
                    name: data.name,
                    description: data.description,
                    status: data.status,
                    roleNavigationItems: navigationItems
                };
                if(data.roleAdded)
                {
                    roles.push(role);
                    role["roleAdded"] = data.roleAdded;
                    role["roleAddedMessage"] = data.roleAddedMessage;
                    dispatch({ type: 'SET_NEW_ROLE', payload: { roles, role }});
                }
            }
            else{
                role["roleAdded"] = false;
                role["roleAddedMessage"] = "Error when creating the new role";
                dispatch({ type: 'SET_NEW_ROLE_ERROR', role });
            } 
           }).catch((error)=>{
                let role = { };
                role["roleAdded"] = false;
                role["roleAddedMessage"] = "Error when creating the new role";
                dispatch({ type: 'SET_NEW_ROLE_ERROR', role });
           });
   };
};
   export const updateRole = (id, name, description, status, navigationItems, roles) => {
    return (dispatch, getState) => {
        Services.Roles.updateRole(id, name, description, status, navigationItems)
        .then((data)=>{
            let role = {};
            if(data) {
                role = {
                    id: data.id,
                    name: data.name,
                    description: data.description,
                    status: data.status,
                    navigationItems
                };
                if(data.roleUpdated)
                {
                    roles.forEach((roleData) => {
                        if(roleData.id === data.id) {
                            roleData.name = data.name;
                            roleData.description = data.description;
                            roleData.status = data.status;
                            roleData.navigationItems = navigationItems;
                        }
                    });
                    role["roleUpdated"] = data.roleUpdated;
                    role["roleUpdatedMessage"] = data.roleUpdatedMessage;
                    dispatch({ type: 'SET_UPDATED_ROLE', payload: { roles, role }});
                }
            }
            else{
                role["roleUpdated"] = false;
                role["roleUpdatedMessage"] = "Error when updating the role";
                dispatch({ type: 'SET_UPDATED_ROLE_ERROR', role });
            } 
           }).catch((error)=>{
                let role = { };
                role["roleUpdated"] = false;
                role["roleUpdatedMessage"] = "Error when updating the role";
                dispatch({ type: 'SET_UPDATED_ROLE_ERROR', role });
           });
   };
};

export const updateRolesInStore = (roles) => {
    return (dispatch, getState) => {
        dispatch({ type: 'SET_ROLES', roles });
    };
};