import { TableSortLabel } from '@material-ui/core';
import React from 'react';
import formatDate from '../../../utilities/date.utilities';

import './styles.css';

const Row = ({ row, columns }) => {
  if(columns) {
    return (
      <tr>
        {columns.map(
          ({ customComponent, field, type, label, stylesCell, className }) => (
            <td key={label} className={className} style={stylesCell}>
              {type === 'date'
                ? formatDate(row[field])
                : customComponent
                ? customComponent(row)
                : row[field]}
            </td>
          )
        )}
      </tr>
    );
  } else {
    return null;
  }
};

const TableHeader = ({ sortField, sortDirection, handleSort, columns }) => {
  if(columns) {
    return (
      <thead>
        <tr>
          {columns
            .sort((a, b) => (a.position > b.position ? 1 : -1))
            .map(({ sortable, label, field, className, stylesHeader }) => (
              <th key={label + '_' + field} scope="col" className={className} style={stylesHeader}>
                <TableSortLabel
                  className="header-label"
                  active={sortable && sortField === field}
                  direction={sortDirection}
                  style={{ cursor: sortable ? 'pointer' : 'default' }}
                  onClick={() =>
                    sortable &&
                    handleSort(field, sortDirection === 'asc' ? 'desc' : 'asc')
                  }
                >
                  {label}
                </TableSortLabel>
              </th>
            ))}
        </tr>
      </thead>
    );
  }else {
    return null;
  }
};

const LocalTable = ({
  children,
  sortField,
  sortDirection,
  sortRows,
  handleSort,
  columns,
  rows,
}) => {
  return (
    <div className="container d-none d-md-block">
      <div className="table-responsive">
        <table className="table">
          <TableHeader
            sortField={sortField}
            sortDirection={sortDirection}
            sortRows={sortRows}
            handleSort={handleSort}
            columns={columns}
          />
          <tbody>
            {rows.map((item, index) => (
              <Row key={'row_' + index} row={item} columns={columns} />
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

const ActionButton = ({
  handleAction,
  icon,
  iconSrc,
  style,
  dataToggle,
  dataTarget,
}) => (
  <div
    className="action-button"
    style={style}
    data-toggle={dataToggle}
    data-target={dataTarget}
    onClick={handleAction}
  >
    {iconSrc ? <img src={iconSrc} /> : <i className={icon}></i>}
  </div>
);

export { LocalTable, ActionButton };
