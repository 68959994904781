import React, { Component } from 'react';
import CapacityPopUp from './capacity-popup'
import { setProjects } from '../../actions/projects-actions'
import { setClients } from '../../actions/clients-actions'
import { setUsers } from '../../actions/user-actions'
import { setUserProjects, setSelectedUserProject, deleteUserCapacity } from '../../actions/general-actions'
import Spinner from 'react-bootstrap/Spinner'
import { connect } from "react-redux";
import  DeleteSimpleModal  from '../shared/simple-modal/delete-simple-modal';
import { FaSortAlphaDown, FaSortAlphaUp } from 'react-icons/fa/index';
import Navbar from '../main/navbar';
import { getUsers, getClients, getClientStatusTypes, getProjectStatusTypes, getProjects, getCapacities } from '../../utilities/api.calls';
import { updateClientsInStore } from '../../actions/clients-actions';
import { updateProjectsInStore } from '../../actions/projects-actions';

class IndexCapacity extends Component {
    constructor(props) {
        super(props);
        this.handleSelectedEdit = this.handleSelectedEdit.bind(this);
        this.showAllCapacities = this.showAllCapacities.bind(this);
        this.showActiveCapacities = this.showActiveCapacities.bind(this);
        this.goToHome = this.goToHome.bind(this);
        this.state = {
            projects: [],
            capacitiesWereLoaded: false,
            errorMessage: '',
            clients: [],
            users: [],
            userProjects: [],
            selectedUserProjectId: "",
            selectedUserId: "",
            confirmButton: true,
            baseAlertClass: "text-center alert alert-dismissible fade show alert-",
            showUserProjectDeletedMessage: false,
            userProjectDeletedMessage: "",
            alertClass: "",
            processing: false,
            activeCapacitiesOnly: true,
            orderByUserAsc: false,
            orderByProjectAsc: false,
            orderByClientAsc: false,
            orderByBeginDateAsc: false,
            orderByEndDateAsc: false,
            buttons:
            [
                {
                    id: 1,
                    className: "btn btn-sm btn-success mr-2",
                    dataToggle: "modal",
                    dataTarget: "#createCapacity",
                    title: "Add Capacity",
                    onClick: this.handleSelectedEdit.bind(this)
                },
                {
                    id: 2,
                    className: "btn btn-sm btn-primary",
                    onClick: this.showAllCapacities.bind(this),
                    title: "Show All"
                }
            ]
        }
    }

    componentDidMount = () => {
        // this.props.setProjects(this.state.projects); 
        // this.props.setClients(this.state.clients); 
        this.props.setUsers(this.state.users);
        // this.props.setUserProjects(this.state.userProjects);
        getClientStatusTypes().then(responseStatus => {
            if(responseStatus && responseStatus.success === true) {
                var clientsStatusTypesById = [];
                responseStatus.clientsStatusTypes.forEach((clientStatusType) => {
                    clientsStatusTypesById[clientStatusType.id] = clientStatusType.name;
                });
                getClients().then(responseClients => {
                    if(responseClients && responseClients.success === true) {
                        let clients = [];
                        let clientsToSaveInStore = [];
                        responseClients.clients.forEach((client) => {
                            client['statusType'] = ((typeof clientsStatusTypesById[client.statusId]) !== 'undefined') ? clientsStatusTypesById[client.statusId] : null;
                            clients[client.id] = client;
                            clientsToSaveInStore.push(client);
                        });
                        updateClientsInStore(clientsToSaveInStore);
                        getProjectStatusTypes().then(responseProjectStatus => {
                            if(responseProjectStatus && responseProjectStatus.success === true) {
                                var projectsStatusTypesById = [];
                                responseProjectStatus.projectsStatusTypes.forEach((projectsStatusType) => {
                                    projectsStatusTypesById[projectsStatusType.id] = projectsStatusType.name;
                                });
                                getProjects().then(response => {
                                    if(response && response.success === true) {
                                        let projects = [];
                                        let projectsToSaveInStore = [];
                                        response.projects.forEach((project) => {
                                            project['statusType'] = ((typeof projectsStatusTypesById[project.statusId]) !== 'undefined') ? projectsStatusTypesById[project.statusId] : null;
                                            if(clients) {
                                                project['clientName'] = clients[project.clientId].name;
                                                project['client'] = clients[project.clientId];
                                            }
                                            projects[project.id] = project;
                                            projectsToSaveInStore.push(project);
                                        });
                                        updateProjectsInStore(projectsToSaveInStore);
                                        getUsers().then(responseUsers => {
                                            if(responseUsers && responseUsers.success === true) {
                                                let users = [];
                                                responseUsers.users.forEach((user) => {
                                                    users[user.id] = user;
                                                })
                                                //TODO: get userProjects (capacities)
                                                getCapacities().then(responseCapacities => {
                                                    if(responseCapacities && responseCapacities.success === true) {
                                                        let capacities = [];
                                                        if(responseCapacities.capacities && responseCapacities.capacities[0] && users && clients && projects) {
                                                            let capacitiesAux = [];
                                                            let usrProjs;
                                                            let userProject = {};
                                                            let rowProject = null;
                                                            let rowClient = null;
                                                            let rowUser = null;
                                                            Object.values(responseCapacities.capacities).forEach(usrProj=> {
                                                                usrProjs = Object.values(usrProj);
                                                                if(usrProjs && usrProjs.length > 0) {
                                                                    usrProjs.forEach(capacity => {
                                                                        rowUser = users[capacity.userId] || null;//users.find(user=> {if(user.id === capacity.userId) {return user} return null });
                                                                        rowProject = projects[capacity.projectId]//projects.find(project=> {if(project.id === capacity.projectId) {return project} return null });
                                                                        if(rowProject)
                                                                            rowClient = clients[rowProject.clientId]//clients.find(client=> {if(client.id === rowProject.clientId) {return client} return null });
                                                                        userProject = capacity;
                                                                        userProject['user'] = rowUser ? rowUser.firstName + " " + rowUser.lastName : "";
                                                                        userProject['project'] = rowProject ? rowProject.name : "";
                                                                        userProject['client'] = rowClient ? rowClient.name : "";
                                                                        userProject['begin'] = userProject.beginDate ? new Date(userProject.beginDate) : new Date();
                                                                        userProject['end'] = userProject.endDate ? new Date(userProject.endDate) : new Date();
                                                                        if(typeof(capacitiesAux[userProject.id]) === 'undefined' || capacitiesAux[userProject.id] === null){
                                                                            capacitiesAux[userProject.id] = userProject;
                                                                            capacities.push(userProject);
                                                                        }
                                                                    });
                                                                }
                                                            });
                                                        }
                                                        if(capacities && capacities.length > 0) {
                                                            this.setState({
                                                                ...this.state,
                                                                capacitiesWereLoaded: true,
                                                                userProjects: responseCapacities.capacities,
                                                                capacities: capacities.sort(
                                                                    (a,b) => (a.user.toLowerCase() > b.user.toLowerCase()) ? 1 : 
                                                                        ((b.user.toLowerCase() > a.user.toLowerCase()) ? -1 : 0)
                                                                    ),
                                                                orderByUserAsc: false,
                                                                projects: projectsToSaveInStore,
                                                                clients: clientsToSaveInStore
                                                            });
                                                        }
                                                    }
                                                })
                                            }
                                            if(responseUsers && responseUsers.success === false) {
                                                this.setState({
                                                    ...this.state,
                                                    capacitiesWereLoaded: true,
                                                    errorMessage: response.error
                                                });
                                            }
                                        })
                                    }
                                    if(response && response.success === false) {
                                        this.setState({
                                            ...this.state,
                                            capacitiesWereLoaded: true,
                                            errorMessage: response.error
                                        });
                                    }
                                })
                            }
                            if(responseProjectStatus && responseProjectStatus.success === false) {
                                this.setState({
                                    ...this.state,
                                    capacitiesWereLoaded: true,
                                    errorMessage: responseProjectStatus.error
                                });
                            }
                        })
                    }
                    if(responseClients && responseClients.success === false) {
                        this.setState({
                            ...this.state,
                            capacitiesWereLoaded: true,
                            errorMessage: responseClients.error
                        });
                    }
                })
            }
            if(responseStatus && responseStatus.success === false) {
                this.setState({
                    ...this.state,
                    capacitiesWereLoaded: true,
                    errorMessage: responseStatus.error
                });
            }
        })
    }

    componentDidUpdate = async (prevProps) => {
        const { userProject } = this.props;
        if(prevProps.userProject !== userProject && (typeof userProject) !== 'undefined' && userProject 
       && (typeof userProject.userProjectDeleted) !== 'undefined' && (typeof userProject.userProjectDeletedMessage) !== 'undefined'
       && userProject.userProjectDeletedMessage && userProject.userProjectDeletedMessage.length > 0) {
            const className =userProject.userProjectDeleted ? "success" : "danger";
            await this.setState({
                confirmButton: false,
                showUserProjectDeletedMessage: true,
                userProjectDeletedMessage: userProject.userProjectDeletedMessage,
                alertClass: this.state.baseAlertClass+className,
                processing: false
            });
       }
        if(prevProps.projects !== this.props.projects) { 
            this.setState({
                ...this.state,
                projects: this.props.projects
            }) 
        }

        if(prevProps.users !== this.props.users) {
            this.setState({
                ...this.state,
                users: this.props.users
            }) 
        }

        if(prevProps.userProjects !== this.props.userProjects) { 
            let { projects, clients, users, userProjects } = this.props;
            let capacities = [];
            let capacitiesAux = [];
            let usrProjs;
            let userProject;
            let rowProject = null;
            let rowClient = null;
            let rowUser = null;
            if((typeof projects) === 'undefined' || projects === null || projects.length === 0) projects = this.state.projects;
            if((typeof clients) === 'undefined' || clients === null || clients.length === 0) clients = this.state.clients;
            if((typeof clients) === 'undefined' || clients === null || clients.length === 0) clients = this.state.clients;
            if(userProjects && users && clients && projects) {
                Object.values(userProjects).forEach(capacity => {
                    if((typeof capacity) !== 'undefined' && capacity !== null) {
                        rowUser = users.find(user=> {if(user.id === capacity.userId) {return user} return null });
                        rowProject = projects.find(project=> {if(project.id === capacity.projectId) {return project} return null });
                        if(rowProject)
                            rowClient = clients.find(client=> {if(client.id === rowProject.clientId) {return client} return null });
                        userProject = capacity;
                        userProject['user'] = rowUser ? rowUser.firstName + " " + rowUser.lastName : "";
                        userProject['project'] = rowProject ? rowProject.name : "";
                        userProject['client'] = rowClient ? rowClient.name : "";
                        userProject['begin'] = userProject.beginDate ? new Date(userProject.beginDate) : new Date();
                        userProject['end'] = userProject.endDate ? new Date(userProject.endDate) : new Date();
                        if(typeof(capacitiesAux[userProject.id]) === 'undefined' || capacitiesAux[userProject.id] === null){
                            capacitiesAux[userProject.id] = userProject;
                            capacities.push(userProject);
                        }
                    }
                })


                    // Object.values(userProjects[0]).forEach(usrProj=> {
                    //     usrProjs = Object.values(usrProj);
                    //     if(usrProjs && usrProjs.length > 0) {
                    //         usrProjs.forEach(capacity => {
                    //             rowUser = users.find(user=> {if(user.id === capacity.userId) {return user} return null });
                    //             rowProject = projects.find(project=> {if(project.id === capacity.projectId) {return project} return null });
                    //             if(rowProject)
                    //                 rowClient = clients.find(client=> {if(client.id === rowProject.clientId) {return client} return null });
                    //             userProject = capacity;
                    //             userProject['user'] = rowUser ? rowUser.firstName + " " + rowUser.lastName : "";
                    //             userProject['project'] = rowProject ? rowProject.name : "";
                    //             userProject['client'] = rowClient ? rowClient.name : "";
                    //             userProject['begin'] = userProject.beginDate ? new Date(userProject.beginDate) : new Date();
                    //             userProject['end'] = userProject.endDate ? new Date(userProject.endDate) : new Date();
                    //             if(typeof(capacitiesAux[userProject.id]) === 'undefined' || capacitiesAux[userProject.id] === null){
                    //                 capacitiesAux[userProject.id] = userProject;
                    //                 capacities.push(userProject);
                    //             }
                    //         });
                    //     }
                    // });
            }
            if(capacities && capacities.length > 0) {
                this.setState({
                    ...this.state,
                    userProjects: this.props.userProjects,
                    capacities: capacities.sort(
                        (a,b) => (a.user.toLowerCase() > b.user.toLowerCase()) ? 1 : 
                            ((b.user.toLowerCase() > a.user.toLowerCase()) ? -1 : 0)
                        ),
                    orderByUserAsc: false

                });
            } else {
                this.setState({
                    ...this.state,
                    userProjects: this.props.userProjects
                });
            }
        }
    }

    handleCloseAlert = () => {
        this.props.setSelectedUserProject(undefined);
        this.setState({
            confirmButton: true,
            showUserProjectDeletedMessage: false,
            userProjectDeletedMessage: "",
            alertClass: "",
            processing: false,
            orderByUserAsc: false,
            orderByProjectAsc: false,
            orderByClientAsc: false,
            orderByBeginDateAsc: false,
            orderByEndDateAsc: false
        });
      }

    handleSelectedEdit = (userProjectId, userId) => {
        if((typeof userProjectId) === 'undefined' || 
            ((typeof userProjectId) !== 'string' && (typeof userProjectId) !== 'number')) 
                userProjectId = "";
        if((typeof userId) === 'undefined') userId = "";
        let usrProjs;
        let userProject = null;
        let rowProject = null;
        let rowClient = null;
        let rowUser = null;
        if(userProjectId !== "" && userId !== "" && this.props.userProjects && this.props.userProjects[0] && this.props.users && this.props.clients && this.props.projects) {
            Object.values(this.props.userProjects[0]).forEach(usrProj=> {
                usrProjs = Object.values(usrProj);
                if(usrProjs && usrProjs.length > 0) {
                    usrProjs.forEach(capacity => {
                        if(capacity.id === userProjectId && capacity.userId === userId) {
                            rowUser = this.props.users.find(user=> {if(user.id === capacity.userId) {return user} return null });
                            rowProject = this.props.projects.find(project=> {if(project.id === capacity.projectId) {return project} return null });
                            if(rowProject)
                                rowClient = this.props.clients.find(client=> {if(client.id === rowProject.clientId) {return client} return null });
                            userProject = capacity;
                            userProject['user'] = rowUser ? rowUser.firstName + " " + rowUser.lastName : "";
                            userProject['project'] = rowProject ? rowProject.name : "";
                            userProject['client'] = rowClient ? rowClient.name : "";
                            userProject['begin'] = userProject.beginDate ? new Date(userProject.beginDate) : new Date();
                            userProject['end'] = userProject.endDate ? new Date(userProject.endDate) : new Date();
                        }
                    });
                }
            });
    }
        this.setState({
            selectedUserProjectId: userProjectId,
            selectedUserId: userId,
            capacity: userProject
         })
        this.props.setSelectedUserProject(userProjectId, userId);
    };

    getDateColor = (endDate) => {
        let cellStyle = { bgColor:'green', color:'white' };
        if(new Date(endDate).getMonth() <= new Date().getMonth() && new Date(endDate).getYear() <= new Date().getYear()) {
            cellStyle = { bgColor:'red', color:'white' }

        } else if(new Date(endDate).getMonth() === new Date().getMonth()+1 && new Date(endDate).getYear() <= new Date().getYear()) {
            cellStyle = { bgColor:'yellow', color:'black' }
        }
        return cellStyle; 
            
    }
    
    alphabeticSortByUserAsc = () => {
        this.setState({
            capacities: this.state.capacities.sort(
            (a,b) => (a.user.toLowerCase() > b.user.toLowerCase()) ? 1 : 
              ((b.user.toLowerCase() > a.user.toLowerCase()) ? -1 : 0)
          ),
          orderByUserAsc: false
        });
      }

    alphabeticSortByUserDesc = () => {
        this.setState({
            capacities: this.state.capacities.sort(
            (a,b) => (a.user.toLowerCase() > b.user.toLowerCase()) ? -1 : 
                ((b.user.toLowerCase() > a.user.toLowerCase()) ? 1 : 0)
            ),
            orderByUserAsc: true
        });
    }

    alphabeticSortByProjectAsc = () => {
        this.setState({
            capacities: this.state.capacities.sort(
            (a,b) => (a.project.toLowerCase() > b.project.toLowerCase()) ? 1 : 
              ((b.project.toLowerCase() > a.project.toLowerCase()) ? -1 : 0)
          ),
          orderByProjectAsc: false
        });
      }

    alphabeticSortByProjectDesc = () => {
        this.setState({
            capacities: this.state.capacities.sort(
            (a,b) => (a.project.toLowerCase() > b.project.toLowerCase()) ? -1 : 
                ((b.project.toLowerCase() > a.project.toLowerCase()) ? 1 : 0)
            ),
            orderByProjectAsc: true
        });
    }

    alphabeticSortByClientAsc = () => {
        this.setState({
            capacities: this.state.capacities.sort(
            (a,b) => (a.client.toLowerCase() > b.client.toLowerCase()) ? 1 : 
              ((b.client.toLowerCase() > a.client.toLowerCase()) ? -1 : 0)
          ),
          orderByClientAsc: false
        });
      }

    alphabeticSortByClientDesc = () => {
        this.setState({
            capacities: this.state.capacities.sort(
            (a,b) => (a.client.toLowerCase() > b.client.toLowerCase()) ? -1 : 
                ((b.client.toLowerCase() > a.client.toLowerCase()) ? 1 : 0)
            ),
            orderByClientAsc: true
        });
    }

    alphabeticSortByBeginDateAsc = () => {
        this.setState({
            capacities: this.state.capacities.sort(
            (a,b) => (a.begin > b.begin) ? 1 : 
              ((b.begin> a.begin) ? -1 : 0)
          ),
          orderByBeginDateAsc: false
        });
      }

    alphabeticSortByBeginDateDesc = () => {
        this.setState({
            capacities: this.state.capacities.sort(
            (a,b) => (a.begin > b.begin) ? -1 : 
                ((b.begin > a.begin) ? 1 : 0)
            ),
            orderByBeginDateAsc: true
        });
    }

    alphabeticSortByEndDateAsc = () => {
        this.setState({
            capacities: this.state.capacities.sort(
            (a,b) => (a.end > b.end) ? 1 : 
              ((b.end > a.end) ? -1 : 0)
          ),
          orderByEndDateAsc: false
        });
      }

    alphabeticSortByEndDateDesc = () => {
        this.setState({
            capacities: this.state.capacities.sort(
            (a,b) => (a.end > b.end) ? -1 : 
                ((b.end > a.end) ? 1 : 0)
            ),
            orderByEndDateAsc: true
        });
    }

    handleCloseNotification = () => {
        this.setState({
            ...this.state,
            alertClass: "",
            processing: false,
            deleteUserName: '',
            deleteProjectName: '',
            deleteClientName: '',
            deleteBeginDate: '',
            deleteEndDate: '',
            showUserProjectDeletedMessage: false,
            userProjectDeletedMessage: ''
        });
    }

    showActiveCapacities = async () => {
        let buttons = this.state.buttons;
        buttons[1]['onClick'] = this.showAllCapacities.bind(this)
        buttons[1]['title'] = "Show All";
        await this.setState({
            activeCapacitiesOnly: true,
            buttons
        });
    }

    showAllCapacities = async () => {
        let buttons = this.state.buttons;
        buttons[1]['onClick'] = this.showActiveCapacities.bind(this)
        buttons[1]['title'] = "Show Active capacities only";
        await this.setState({
            activeCapacitiesOnly: false,
            buttons
        });
    }

    goToHome = () => {
        this.props.history.push('/app')
    }

    render() { 
        const { projects, clients, users } = this.props;
        const { buttons, capacitiesWereLoaded } = this.state;
        if(!capacitiesWereLoaded) {
            //!projects || projects.length <= 0 || !clients || clients.length <= 0 || !users || users.length <= 0  ) {
            return (
                <React.Fragment>
                    {/* <Navbar /> */}
                    <div style={{textAlign: 'center', marginTop: '5rem'}}>
                        <Spinner style={{color: 'white'}} animation="border" />
                    </div>
                </React.Fragment> 
            )
        }
        return ( 
            <React.Fragment>
                <Navbar linkToHome={this.goToHome} buttons={buttons}/>
                <table className="table table-hover inventory-table" >
                    <thead>
                        <tr>
                        <th scope="col">User
                            {this.state.orderByUserAsc ?
                                <span onClick={this.alphabeticSortByUserAsc}> [<FaSortAlphaDown />]</span>
                                :
                                <span onClick={this.alphabeticSortByUserDesc}> [<FaSortAlphaUp />]</span>
                            }
                        </th>
                        <th scope="col">Project
                            {this.state.orderByProjectAsc ?
                                <span onClick={this.alphabeticSortByProjectAsc}> [<FaSortAlphaDown />]</span>
                                :
                                <span onClick={this.alphabeticSortByProjectDesc}> [<FaSortAlphaUp />]</span>
                            }
                        </th>
                        <th scope="col">Client
                            {this.state.orderByClientAsc ?
                                <span onClick={this.alphabeticSortByClientAsc}> [<FaSortAlphaDown />]</span>
                                :
                                <span onClick={this.alphabeticSortByClientDesc}> [<FaSortAlphaUp />]</span>
                            }
                        </th>
                        <th scope="col">Start Date
                            {this.state.orderByBeginDateAsc ?
                                <span onClick={this.alphabeticSortByBeginDateAsc}> [<FaSortAlphaDown />]</span>
                                :
                                <span onClick={this.alphabeticSortByBeginDateDesc}> [<FaSortAlphaUp />]</span>
                            }
                        </th>
                        <th scope="col">End Date
                            {this.state.orderByEndDateAsc ?
                                <span onClick={this.alphabeticSortByEndDateAsc}> [<FaSortAlphaDown />]</span>
                                :
                                <span onClick={this.alphabeticSortByEndDateDesc}> [<FaSortAlphaUp />]</span>
                            }
                        </th>
                        <th scope="col">Status</th>
                        <th scope="col">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                    {this.state.capacities ? this.state.capacities.map(capacity => {
                                if((this.state.activeCapacitiesOnly &&  capacity.status === "Active") || !this.state.activeCapacitiesOnly) {
                                    const cellStyle = this.getDateColor(capacity.endDate);
                                    return (
                                        <tr key={capacity.userId+capacity.id}>
                                            <td>{capacity.user ? capacity.user : "not named user"}</td>
                                            <td>{capacity.project ? capacity.project : "not named project"}</td>
                                            <td>{capacity.client ? capacity.client : "not named client"}</td>
                                            <td>{capacity.beginDate}</td>
                                            <td style={{ fontWeight: 'bold', color: cellStyle.color, backgroundColor: cellStyle.bgColor }}>{capacity.endDate}</td>
                                            <td>{capacity.status}</td>
                                            <td>
                                                <button onClick={()=> this.handleSelectedEdit(capacity.id, capacity.userId)} type="button" value={capacity.id} className="btn btn-sm btn-secondary" data-toggle="modal" data-target="#createCapacity">
                                                    Edit
                                                </button>
                                                <button type="button" className="btn btn-sm btn-danger ml-1" data-toggle="modal" data-target="#confirmDelete" onClick={async ()=> await this.setState({
                                                    deleteUserName: capacity.user ? capacity.user : "not named user",
                                                    deleteProjectName: capacity.project ? capacity.project : "not named project",
                                                    deleteClientName: capacity.client ? capacity.client : "not named client",
                                                    deleteBeginDate: capacity.beginDate,
                                                    deleteEndDate: capacity.endDate,
                                                    deleteProjectId: capacity.id,
                                                    deleteUserId: capacity.userId
                                                })}>
                                                    Delete
                                                </button>
                                            </td>
                                        </tr>)
                                } else {
                                    return null
                                }
                    }) : null  
                    }
                    </tbody>
                </table>
                <CapacityPopUp modalId="createCapacity" modalTitleId={ this.state.selectedUserProjectId === "" ? "Add Capacity" : "Update Capacity"} 
                projects={this.state.projects} clients={this.state.clients} userProject={this.state.capacity} userProjects={this.state.capacities}/>
                <DeleteSimpleModal modalName="confirmDelete" title="Confirm Delete" processing={this.state.processing} 
                confirmButton={this.state.confirmButton} text={[
                    <div key="userTypeDelete">
                        <div className={ this.state.showUserProjectDeletedMessage ? "" : "d-none" }>
                            <div className={ this.state.alertClass } role="alert">
                                { this.state.userProjectDeletedMessage }
                                <button type="button" className="close" onClick={ this.handleCloseNotification }>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                        </div>
                        <div key="userProjectDelete" className="text-center">
                            <div>User: {this.state.deleteUserName}</div>
                            <div>Project: {this.state.deleteProjectName}</div>
                            <div>Client: {this.state.deleteClientName}</div>
                            <div>Start Date: {this.state.deleteBeginDate}</div>
                            <div>End Date: {this.state.deleteEndDate}</div>
                        </div>
                    </div>
                    ]} 
                    onConfirm={()=> {this.props.deleteUserCapacity(this.state.deleteProjectId, this.state.deleteUserId, 
                                    this.props.userProjects);
                                    this.setState({processing:true}); }}
                    onClose={() => {this.handleCloseAlert()}}
                    
                    />
            </React.Fragment>
         );
    }
}

const mapStateToProps = state => {
    return {
      clients: state.clientsStore.clients,
      projects: state.projectsStore.projects,
      users: state.usersStore.users,
      userProjects: state.generalStore.userProjects,
      userProject: state.generalStore.userProject
    };
  };
  
  const mapDispatchToProps = dispatch => ({
    setUsers: (users) => dispatch(setUsers(users)),
    setClients: (clients) => dispatch(setClients(clients)),
    setProjects: (projects) => dispatch(setProjects(projects)),
    setUserProjects: (userProjects) => dispatch(setUserProjects(userProjects)),
    setSelectedUserProject: (userProjectId, userId) => dispatch(setSelectedUserProject(userProjectId, userId)),
    deleteUserCapacity: (userProjectId, userId, userProjects) => dispatch(deleteUserCapacity(userProjectId, userId, userProjects)) 
  });
  
  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(IndexCapacity);