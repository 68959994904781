
import firebase from 'firebase/app';
import "firebase/database";
import { PRINT_IN_CONSOLE } from '../../components/shared/constants/constants'

export class InventoryApi {
    setInventoryItems(userId) {
        return new Promise((resolve, reject) => {
            if(userId !== 0)
            {
                firebase.database()
                .ref(`InventoryItems`)
                .orderByChild('userId')
                .equalTo(userId)
                .once("value")
                .then((items) => {
                    resolve(items);
                });
            }
            else {
                firebase.database()
                .ref(`InventoryItems`)
                .once("value")
                .then((items) => {
                    resolve(items);
                });
            }      
        });
    }

    getInventoryItems() {
        return new Promise((resolve, reject) => {
            firebase.database()
                .ref(`InventoryItems`)
                .once("value")
                .then((items) => {
                    resolve(items);
                });
        });
    }

    getUserInventoryItems(userId) {
        return firebase.database()
        .ref(`InventoryItems`)
        .orderByChild('userId')
        .equalTo(userId)
        .once("value");
    }

    updateInventoryItemUser(itemId, userId) {
        return new Promise((resolve, reject) => {
            firebase.database()
                .ref(`InventoryItems/${itemId}`)
                .update({ userId: userId })
                .then(()=> { resolve(itemId); })
                .catch((error) => {
                    if(PRINT_IN_CONSOLE) {
                        console.log('Error updating item:', error.message);
                    }
                    resolve(null); 
                });
        });
    }

    updateItem(item, selectedUser, isNewItem) {
        return new Promise((resolve, reject) => {
            if(isNewItem) {
                const itemKey = firebase
                .database()
                .ref(`InventoryItems`)
                .push({
                    codeId: item.codeId,            
                    type: item.type,   
                    brand: item.brand,           
                    model: item.model, 
                    locationId: item.locationId, 
                    detail: item.detail,
                    lastUpdate: item.lastUpdate,
                    userId: selectedUser.id 
                }).getKey();
    
                firebase.database()
                    .ref(`InventoryItems/${itemKey}`)
                    .update({ id: itemKey })
                    .then(()=> { resolve(itemKey); })
                    .catch((error) => {
                        if(PRINT_IN_CONSOLE) {
                            console.log('Error creating item:', error.message);
                        }
                        resolve(null); 
                })
            } else {
                firebase.database()
                    .ref(`InventoryItems/${item.id}`)
                    .update({ 
                        codeId: item.codeId,            
                        type: item.type,   
                        brand: item.brand,           
                        model: item.model, 
                        locationId: item.locationId, 
                        detail: item.detail,
                        lastUpdate: item.lastUpdate,
                        userId: item.userId 
                    })
                    .then(()=> { resolve(item.id); })
                    .catch((error) => {
                        if(PRINT_IN_CONSOLE) {
                            console.log('Error creating item:', error.message);
                        }
                        resolve(null); 
                })
            }
            
              
        });
    }

    deleteItem(itemKey) {
        return new Promise((resolve, reject) => {
            firebase
            .database()
            .ref(`InventoryItems/${itemKey}`)
            .remove()
            .then(()=> { 
                let response = { "id": itemKey };
                resolve(response);
            })
            .catch((error) => {
                if(PRINT_IN_CONSOLE) {
                    console.log('Error removing inventory item:', error.message);
                }
                resolve({ 'message': 'Error removing inventory item'});
                //resolve(null); 
            });              
        });
    }
}

