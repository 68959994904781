import React, { Component, useState } from 'react';
import RolesModal from '../roles/roles-modal';
import Spinner from 'react-bootstrap/Spinner';
import { connect } from 'react-redux';
import { setRoles, setSelectedRole } from '../../actions/role-actions';
import { FaSortAlphaDown, FaSortAlphaUp } from 'react-icons/fa/index';
import Navbar from '../main/navbar';
import { ActionButton, AddNew, LocalTable, Sort } from '../common';
import edit from '../../assets/images/icons/edit.svg';
import { TitlePanel } from '../common/LocalPanels';

class RolesMain extends Component {
  constructor(props) {
    super(props);
    this.handleSelectedEdit = this.handleSelectedEdit.bind(this);
    this.goToHome = this.goToHome.bind(this);
    this.showActiveRoles = this.showActiveRoles.bind(this);
    this.showAllRoles = this.showAllRoles.bind(this);
    this.state = {
      roles: [],
      role: {
        id: 0,
        name: '',
        description: '',
      },
      activeRolesOnly: true,
      selectedRoleId: '',
      orderByNameAsc: true,
      orderByDescriptionAsc: true,
      orderByStatusAsc: true,
      buttons: [
        {
          id: 1,
          className: 'btn btn-sm btn-success mr-2',
          dataToggle: 'modal',
          dataTarget: '#createRole',
          title: 'Add Role',
          onClick: this.handleSelectedEdit.bind(this),
        },
        {
          id: 2,
          className: 'btn btn-sm btn-primary',
          onClick: this.showAllRoles.bind(this),
          title: 'Show All',
        },
      ],
      sortField: '',
      sortDirection: 'asc',
      tableColumns: [
        {
          position: 1,
          field: 'name',
          label: 'Name',
          sortable: true,
        },
        {
          position: 2,
          field: 'description',
          label: 'Description',
          sortable: true,
        },
        {
          position: 3,
          field: 'status',
          label: 'Status',
          sortable: true,
          customComponent: (row) => {
            return (
              <div className="d-flex justify-content-start">
                <div
                  className="status-dot"
                  style={{
                    position: 'relative',
                    left: '20px',
                    backgroundColor:
                      row.status === 'Active' ? '#73FF98' : '#FF6666',
                  }}
                ></div>
              </div>
            );
          },
        },
        {
          position: 4,
          field: 'roleNavItems',
          label: 'Permissions',
          sortable: true,
        },
        {
          position: 9,
          field: '',
          label: 'Actions',
          sortable: false,
          type: 'button',
          customComponent: (row) => {
            return (
              <div className="button-container">
                <ActionButton
                  row={row}
                  dataToggle="modal"
                  dataTarget="#createRole"
                  handleAction={() => this.handleSelectedEdit(row.id)}
                  icon="fa fa-edit icon edit"
                  iconSrc={edit}
                  color=""
                />
              </div>
            );
          },
        },
      ],
    };
  }

  componentDidMount = () => {
    const { roles } = this.state;
    this.props.setRoles(roles);
  };

  componentDidUpdate = (prevProps) => {
    if (prevProps.roles !== this.props.roles) {
      let roles = [];
      this.props.roles.forEach((role) => {
        let roleToAdd = role;
        let navItems = [];
        let navItemsString = '';
        role.roleNavigationItems.map((navItem, index) => {
          navItems.push(navItem.text);
          if (index + 1 === role.roleNavigationItems.length) {
            return (navItemsString += navItem.text + '.');
          } else {
            return (navItemsString += navItem.text + ', ');
          }
        });
        role['roleNavItems'] = navItemsString;
        roleToAdd.roleNavigationItems = navItems;
        roles.push(roleToAdd);
      });
      this.setState({
        ...this.state,
        roles: roles,
      });
    }
  };

  handleSelectedEdit = (id) => {
    this.setState({
      selectedRoleId: id,
    });
    this.props.setSelectedRole(id);
  };

  sortRoles = (field, sortDirection) => {
    const asc = sortDirection === 'asc';
    let { roles } = this.state;
    roles = roles.sort((a, b) =>
      a[field].toLowerCase() > b[field].toLowerCase()
        ? asc
          ? 1
          : -1
        : b[field].toLowerCase() > a[field].toLowerCase()
        ? asc
          ? -1
          : 1
        : 0
    );

    this.setState({
      roles: roles,
      sortField: field,
      sortDirection: sortDirection,
    });
  };

  showActiveRoles = async () => {
    let buttons = this.state.buttons;
    buttons[1]['onClick'] = this.showAllRoles.bind(this);
    buttons[1]['title'] = 'Show All';
    await this.setState({
      activeRolesOnly: true,
      buttons,
    });
  };

  showAllRoles = async () => {
    let buttons = this.state.buttons;
    buttons[1]['onClick'] = this.showActiveRoles.bind(this);
    buttons[1]['title'] = 'Show Active roles only';
    await this.setState({
      activeRolesOnly: false,
      buttons,
    });
  };

  goToHome = () => {
    this.props.history.push('/app');
  };

  render() {
    const { roles, sortField, sortDirection, tableColumns } = this.state;
    if (roles.length <= 0) {
      return (
        <React.Fragment>
          <div style={{ textAlign: 'center', marginTop: '5rem' }}>
            <Spinner style={{ color: 'white' }} animation="border" />
          </div>
        </React.Fragment>
      );
    }
    return (
      <React.Fragment>
        <TitlePanel
          title="Roles"
          total={roles.length}
          history={this.props.history}
          icon="user-lock"
        />
        <Filter
          {...this.props}
          handleNewItem={() => this.handleSelectedEdit('')}
          sortField={sortField}
          handleSort={this.sortRoles}
          tableColumns={tableColumns}
        ></Filter>

        <Items
          items={roles}
          sortDirection={sortDirection}
          sortField={sortField}
          handleSort={this.sortRoles}
          tableColumns={tableColumns}
          handleSelectedEdit={this.handleSelectedEdit}
        />
        <RolesModal
          modalId="createRole"
          modalTitleId={
            this.state.selectedRoleId === '' ? 'Create Role' : 'Update Role'
          }
        />
      </React.Fragment>
    );
  }
}

const Filter = ({
  children,
  handleSort,
  sortField,
  handleNewItem,
  tableColumns,
}) => {
  return (
    <div className="container ">
      <div className="row filter-container">
        <div className="col-10 col-sm-10 col-md-3 col-lg-3 d-none">
          <div className="inner-addon right-addon">
            <i className="fa fa-search icon"></i>
            <input
              type="text"
              className="form-control search-input"
              placeholder="Search"
            />
          </div>
        </div>
        <div className="col-md-10 col-lg-10 d-none d-md-block">{children}</div>
        <div className="col-12 col-md-2 filter-buttons-container">
          <AddNew handleNew={handleNewItem} dataTarget="#createRole" />
          <Sort
            handleSort={handleSort}
            sortField={sortField}
            tableColumns={tableColumns}
          />
        </div>
      </div>
    </div>
  );
};

const Items = (props) => {
  return (
    <>
      <Cards {...props} />
      <LocalTable
        {...{
          ...props,
          rows: props.items,
          columns: props.tableColumns,
        }}
      />
    </>
  );
};

const Card = ({ id, name, description, status, roleNavItems, handleSelectedEdit }) => {
  const [open, setOpen] = useState(false);
  return (
    <div className="col-12 col-lg-3 col-md-4 col-sm-6 col-exsm-12">
      <div className="inventory-card">
        <div>{name}</div>
        <div className="card-grey">{description}</div>
        <div className="card-blue">{status}</div>
        {open && (
          <>
            <div className="card-font-size-min">{roleNavItems}</div>
            <div className="action-buttons-card-container w-100 mt-1 d-flex justify-content-center">
              <ActionButton
                dataToggle="modal"
                dataTarget="#createRole"
                handleAction={() => handleSelectedEdit(id)}
                icon="fa fa-edit icon edit"
                iconSrc={edit}
                color=""
              />
            </div>
          </>
        )}
        <div className="see-more-container">
          <div className="see-more" onClick={() => setOpen(!open)}>
            <i className={'fas fa-chevron-' + (open ? 'up' : 'down')} />
          </div>
        </div>
      </div>
    </div>
  );
};

const Cards = ({ items, handleSelectedEdit }) => {
  return (
    <div className="container d-md-none cards-container">
      <div className="row">
        {items.map((item) => (
          <Card key={'rol_' + item.name} {...item}
          handleSelectedEdit={handleSelectedEdit} />
        ))}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    roles: state.rolesStore.roles,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setRoles: (roles) => dispatch(setRoles(roles)),
  setSelectedRole: (roleId) => dispatch(setSelectedRole(roleId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RolesMain);
