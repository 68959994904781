import React, { Component } from 'react';
import onClickOutside from 'react-onclickoutside';
import Spinner from 'react-bootstrap/Spinner';

class DeleteSimpleModal extends Component {
    state = { 
        showConfirmButton: true,
        isMounted: false
    }

    handleClickOutside = async() => {
        if(this.state.isMounted) {
            this.props.onClose();
            await this.setState({
                isMounted: false
            })
        }
    }

    confirm = async() => {
        await this.setState({
            isMounted: true
        });
        this.props.onConfirm();
    }

    close = async() => {
        await this.setState({
            isMounted: false
        });
        this.props.onClose();
    }

    render() { 
        return ( 
            <div id={this.props.modalName} className="modal" tabIndex="-1" role="dialog">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">{this.props.title}</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        {this.props.text}
                    </div>
                    <div className="modal-footer">
                    { this.props.processing ? 
                        <Spinner className="mr-4" style={{color: '#578db9'}} animation="border" />
                        :
                        <div>
                            {this.props.confirmButton ? 
                                <button type="button" className="btn btn-primary mr-1" onClick={this.confirm}>Confirm</button>
                                :
                                ""
                            }
                            <button type="button" className="btn btn-secondary ml-1" data-dismiss="modal"  onClick={this.close}>Close</button>
                        </div>
                    }
                    </div>
                    </div>
                </div>
            </div>
         );
    }
}
export default onClickOutside(DeleteSimpleModal);