import React, { Component } from 'react';
import {
  setGeneralEnums,
  getButtonNavigationItems,
  getNavigationItems,
  setButtonNavigationItems,
} from '../../actions/general-actions';
import { connect } from 'react-redux';
import Cookies from 'universal-cookie';
import ButtonCard from '../shared/button-card/button-card';
import auth from '../classes/auth';
import {
  setUser,
  setUsers,
  downloadImage,
  setProfileImage,
} from '../../actions/user-actions';
import Overlay from '../shared/overlay/overlay';
import './index.css';
import Image from '../../assets/images/default-user.png';
import { config } from '../../utilities/env';

import UsersPopUp from '../users/users-popup';
import { Services } from '../../api/services';
import { getUsers } from '../../utilities/api.calls';
//const config = process.env.REACT_APP_PRODUCTION ? require('../../api/config/1950labs-prod.json') : (process.env.REACT_APP_TESTING ? require('../../api/config/1950labs-testing.json') : require('../../api/config/1950labs-staging.json'));

class IndexMain extends Component {
  state = {
    users: [],
    currentUserId: '',
    user: undefined,
    profileImage: Image,
    profileImageName: '',
    navigationItems: [],
    buttonNavigationItems: [],
  };

  componentDidMount() {
    const firebaseUser = Services.Users.getUserLoggedIn();
    if (!firebaseUser) {
      this.props.history.push('/');
    } else {
      getUsers().then((response) => {
        if (response.success) {
          Services.Users.getUser(firebaseUser.uid)
            .then((user) => {
              if (user !== undefined && user !== null) user = user.val();
              this.props.setUser(response.users, user);
            })
            .catch((error) => {
              console.log(error);
            });
        }
      });
    }
    const currentUserId = firebaseUser.uid;
    this.setState({
      currentUserId
    });
    this.props.setGeneralEnums();
    this.props.getButtonNavigationItems(currentUserId);
    this.props.setUsers();
  }

  componentDidUpdate(prevProps) {
    const { users, profileImage, buttonNavigationItems } = this.props;
    const { currentUserId } = this.state;
    if (
      prevProps.users !== users ||
      prevProps.profileImage !== profileImage ||
      (this.props.user && !this.state.user)
    ) {
      let user = this.props.user;
      if (!user) {
        users.find((usr) => {
          if (usr.id === currentUserId) {
            return usr;
          }
          return undefined;
        });
      }
      if (
        (user !== undefined &&
          user !== null &&
          user.needsToFillRegister === undefined) ||
        (user !== undefined &&
          user !== null &&
          user.needsToFillRegister !== undefined &&
          user.needsToFillRegister === true)
      ) {
        auth.login(() => {
          this.props.history.push({
            pathname: '/registerForm',
            state: {
              user: user,
            },
          });
        }, user.id);
      }
      if (typeof user !== 'undefined' && user) {
        if (typeof user.file !== 'undefined' && user.file) {
          if (
            typeof user.file.url !== 'undefined' &&
            user.file.url &&
            this.state.profileImage !== user.file.url
          ) {
            this.setState({
              user,
              profileImage: user.file.url,
              profileImageName: user.file.name,
            });
          } else {
            if (
              typeof this.state.user === 'undefined' ||
              this.state.user === null ||
              typeof this.state.user.file === 'undefined' ||
              this.state.user.file === null ||
              this.state.user.file.name !== user.file.name ||
              (this.state.user.file.name === user.file.name &&
                this.state.profileImageName === '')
            ) {
              const userFile = user.file
                ? currentUserId + '/' + user.file.name
                : '';
              this.props.downloadImage(userFile);
              const profileImageName =
                prevProps.profileImage !== profileImage &&
                typeof profileImage !== 'undefined' &&
                profileImage
                  ? user.file.name
                  : typeof user.file !== 'undefined' &&
                    user.file &&
                    typeof user.file.url !== 'undefined' &&
                    user.file.url
                  ? user.file.url
                  : '';
              this.setState({
                user,
                profileImage: this.props.profileImage,
                profileImageName,
              });
            }
          }
        } else {
          this.setState({
            user,
            profileImage: require('../../assets/images/default-user.png'),
          });
        }
      }
      if (
        typeof this.props.navigationItems !== 'undefined' &&
        this.props.navigationItems !== null &&
        this.props.navigationItems.length > 0 &&
        this.state.navigationItems.length === 0
      ) {
        this.setState({
          ...this.state,
          navigationItems: this.props.navigationItems,
        });
      }
    }
    if(buttonNavigationItems !== null && buttonNavigationItems !== undefined && buttonNavigationItems.length > 0 && this.state.buttonNavigationItems.length === 0) {
      this.setState({
        ...this.state,
        buttonNavigationItems: this.props.buttonNavigationItems
      })
    }
  }

  handleClick = () => {
    this.props.setUsers();
  };

  render() {
    const { user, profileImage, navigationItems, buttonNavigationItems } = this.state;

    if (!buttonNavigationItems || navigationItems.length === 0 || !user) {
      return <Overlay />;
    } else {
      return (
        <React.Fragment>
          <div className="container main-container">
            <div className="row">
              <div className="col-12">
                <h2 className="section-title">Administration Panel</h2>
              </div>
              {navigationItems
                .sort((a, b) =>
                  String(a.order).toLowerCase() > String(b.order).toLowerCase()
                    ? 1
                    : String(b.order).toLowerCase() >
                      String(a.order).toLowerCase()
                    ? -1
                    : 0
                )
                .map((navItem) => {
                  if (
                    typeof navItem !== 'undefined' &&
                    navItem &&
                    typeof navItem.navItem !== 'undefined' &&
                    navItem.navItem
                  ) {
                    if (navItem.path === 'profile') {
                      return (
                        <ButtonCard
                          link={navItem.path}
                          key={navItem.id}
                          title={navItem.text}
                          description={navItem.description}
                          icon={profileImage}
                          isExternal={false}
                        />
                      );
                    }
                    if (navItem.component === 'InviteUser') {
                      return (
                        <ButtonCard
                          link={navItem.path}
                          key={navItem.id}
                          title={navItem.text}
                          description={navItem.description}
                          icon={navItem.icon}
                          isExternal={false}
                          isPopUp={true}
                          dataTarget="#inviteUser"
                        />
                      );
                    }
                    return (
                      <ButtonCard
                        link={navItem.path}
                        key={navItem.id}
                        title={navItem.text}
                        description={navItem.description}
                        icon={navItem.icon}
                        isExternal={false}
                      />
                    );
                  }
                  return undefined;
                })}
              <div className="col-12">
                <br />
              </div>
              <div className="col-12">
                <h2 className="section-title">External Page Links</h2>
              </div>
              {buttonNavigationItems
                .sort((a, b) =>
                  a.title.toLowerCase() > b.title.toLowerCase()
                    ? 1
                    : b.title.toLowerCase() > a.title.toLowerCase()
                    ? -1
                    : 0
                )
                .map((btnItem) => {
                  return (
                    <ButtonCard
                      link={btnItem.link}
                      key={btnItem.id}
                      title={btnItem.title}
                      description={btnItem.description}
                      icon={btnItem.iconUrl}
                      bgImage={btnItem.imageUrl}
                      isExternal={true}
                    />
                  );
                })}
            </div>
          </div>

          <UsersPopUp
            modalId="inviteUser"
            modalTitleId="Invite User"
            needsToFillRegister
          />
        </React.Fragment>
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    locations: state.generalStore.locations,
    userRoles: state.generalStore.userRoles,
    userStatusTypes: state.generalStore.userStatusTypes,
    userTypes: state.generalStore.userTypes,
    buttonNavigationItems: state.generalStore.buttonNavigationItems,
    navigationItems: state.generalStore.navigationItems,
    users: state.usersStore.users,
    user: state.usersStore.user,
    profileImage: state.usersStore.profileImage,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setUser: (users, user) => dispatch(setUser(users, user)),
  setUsers: (users) => dispatch(setUsers(users)),
  setGeneralEnums: (locations, userRoles, userStatusTypes, userTypes) =>
    dispatch(setGeneralEnums(locations, userRoles, userStatusTypes, userTypes)),
  getButtonNavigationItems: (currentUserId) =>
    dispatch(getButtonNavigationItems(currentUserId)),
  getNavigationItems: (currentUserId) =>
    dispatch(getNavigationItems(currentUserId)),
  downloadImage: (fileUrl) => dispatch(downloadImage(fileUrl)),
  setButtonNavigationItems: (buttonNavigationItems) =>
    dispatch(setButtonNavigationItems(buttonNavigationItems)),
  setProfileImage: (imageUrl) => dispatch(setProfileImage(imageUrl)),
});

export default connect(mapStateToProps, mapDispatchToProps)(IndexMain);
