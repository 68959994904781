import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './../../../App.css';
import RequiredInputLabel from '../../shared/required-input-label';

import { isTextValid } from '../../../utilities/string.utilities';
import _ from 'lodash';
import moment from 'moment';
import { Services } from '../../../api/services';

const PersonalFormData = ({
  updateLocalUser,
  updateUser,
  user,
  title,
  next,
  nextText = 'Next Step',
  profile,
  admin,
  handleCloseNotification,
  registrationCode
}) => {
  const labelClass = 'mb-2 rb-textbox-style';
  const inputClass = 'form-control mb-2 rb-textbox-style';

  const [error, setError] = useState({});
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const onSubmitPersonalData = async () => {
    handleCloseNotification();
    setError({});
    const birthDate =
      typeof user.birthDate !== 'undefined' && user.birthDate
        ? user.birthDate
        : moment(new Date()).format('MM/DD/YYYY');//new Date().toLocaleDateString();

    const calendarBirthDate =
      typeof user.calendarBirthDate !== 'undefined' && user.calendarBirthDate
        ? user.calendarBirthDate
        : new Date();

    user = { ...user, birthDate, calendarBirthDate };

    try {
      await isPersonalDataValid(user);
      updateUser(user, 1);
      !profile && next();
    } catch (err) {
      await setError(err);
      throw err;
    }
  };

  const birthDateChanged = (data) => {
    const birthDate = moment(data).format('MM/DD/YYYY');
    updateLocalUser({
      birthDate,
      calendarBirthDate: data,
    });
  };

  const isPersonalDataValid = async (data) => {
    let error = {};

    if (isTextValid(data.firstName) === false) {
      error = { ...error, ['firstName']: 'First name is required' };
    }
    if (isTextValid(data.lastName) === false) {
      error = { ...error, ['lastName']: 'Last name is required' };
    }
    
    var aDate   = moment(data.birthDate, ["MM-DD-YYYY", "DD-MM-YYYY"]);
    var isValid = aDate.isValid();    
    const datePicker = document.getElementById('birthDayPicker');
    let birthDateText = "";
    if(datePicker) birthDateText = datePicker.value;
 
    if (isTextValid(data.birthDate) === false || isTextValid(birthDateText) === false) {
      error = { ...error, ['birthDate']: 'Birth date is required' };
    } else if(!isValid){
      error = { ...error, ['birthDate']: 'Birth date is invalid' };
    } else if (data.calendarBirthDate > new Date()) {
      error = { ...error, ['birthDate']: 'Birth date is invalid' };
    } 

    if (isTextValid(data.nationality) === false) {
      error = { ...error, ['nationality']: 'Nationality is required' };
    }
    
    if (!profile) {
      if (isTextValid(data.oldPassword) === false && !registrationCode) {
        error = { ...error, ['oldPassword']: 'Old password is required' };
      }
      if (isTextValid(data.newPassword) === false) {
        error = { ...error, ['newPassword']: 'Password is required' };
      }

      if (isTextValid(data.confirmPassword) === false) {
        error = { ...error, ['confirmPassword']: 'Re-Password is required' };
      }
    }

    if (
      (!isTextValid(data.oldPassword) && isTextValid(data.newPassword) && !registrationCode) ||
      (!isTextValid(data.oldPassword) && isTextValid(data.confirmPassword) && !registrationCode)
    ) {
      error = { ...error, ['oldPassword']: 'Old password is required' };
    }

    if (isTextValid(data.oldPassword) && !registrationCode) {
      if (!(await Services.Users.isPasswordCorrect(data.oldPassword))) {
        error = { ...error, ['oldPassword']: 'Old password is incorrect' };
      }
    }

    if (!isTextValid(data.confirmPassword) && isTextValid(data.newPassword)) {
      error = { ...error, ['confirmPassword']: 'Re-Password is required' };
    }

    if (
      isTextValid(data.confirmPassword) &&
      data.newPassword !== data.confirmPassword
    ) {
      error = { ...error, ['newPassword']: 'Passwords must match' };
      error = { ...error, ['confirmPassword']: 'Passwords must match' };
    }

    if (!_.isEmpty(error)) throw error;
  };

  return (
    <React.Fragment>
      <h2 className="onboarding-title">{title}</h2>
      <div className="container" style={{ display: 'flex', flexWrap: 'wrap' }}>
        <div className="col-12 col-lg-6">
          <RequiredInputLabel
            labelName="First name:"
            labelClass={labelClass}
            inputClass={inputClass}
            inputName="firstName"
            value={user.firstName}
            errorMessage={error.firstName}
            onChangeFunction={(event) => {
              updateLocalUser({
                firstName: event.target.value,
              });
            }}
          />
        </div>
        <div className="col-12 col-lg-6">
          <RequiredInputLabel
            labelName="Last name:"
            labelClass={labelClass}
            inputClass={inputClass}
            inputName="lastName"
            value={user.lastName}
            errorMessage={error.lastName}
            onChangeFunction={(event) => {
              updateLocalUser({
                lastName: event.target.value,
              });
            }}
          />
        </div>
        <div className="col-12 col-lg-6">
          <div className="form-group rb-label-input">
            <label>
              BirthDate:
              <span className="ml-1" style={{ color: 'red' }}>
                *
              </span>
            </label>
            <DatePicker
              id="birthDayPicker"
              name="birthDayPicker"
              onChange={birthDateChanged}
              selected={user.calendarBirthDate}
              dateFormat="MM/DD/YYYY"
              value={user.birthDate}
              showYearDropdown
              filterDate = {(date) => {
                return moment() > date;
              }}
            />
            <div className="text-danger">{error.birthDate}</div>
          </div>
        </div>
        <div className="col-12 col-lg-6">
          <RequiredInputLabel
            labelName="Nationality:"
            labelClass={labelClass}
            inputClass={inputClass}
            inputName="nationality"
            errorMessage={error.nationality}
            value={user.nationality}
            onChangeFunction={(event) => {
              updateLocalUser({
                nationality: event.target.value,
              });
            }}
          />
        </div>
        {!admin && (
          <>
            {!registrationCode && <><div className="col-12 col-lg-6 d-flex">
              <RequiredInputLabel
                labelName="Write your old password:"
                labelClass={labelClass}
                inputClass={inputClass}
                inputName="oldPassword"
                inputType={showOldPassword ? 'text' : 'password'}
                errorMessage={error.oldPassword}
                onChangeFunction={(event) => {
                  updateLocalUser({
                    oldPassword: event.target.value,
                  });
                }}
                showPasswordComponent={
                  <i
                    onClick={() => setShowOldPassword(!showOldPassword)}
                    className={
                      'far fa-eye' +
                      (showOldPassword ? '-slash ' : ' ') +
                      'show-pass'
                    }
                  ></i>
                }
              />
            </div>
            <div className="col-12 col-lg-6"></div></>}
            <div className="col-12 col-lg-6 d-flex">
              <RequiredInputLabel
                labelName="Write your password:"
                labelClass={labelClass}
                inputClass={inputClass}
                inputName="newPassword"
                inputType={showNewPassword ? 'text' : 'password'}
                errorMessage={error.newPassword}
                onChangeFunction={(event) => {
                  updateLocalUser({
                    newPassword: event.target.value,
                  });
                }}
                showPasswordComponent={
                  <i
                    onClick={() => setShowNewPassword(!showNewPassword)}
                    className={
                      'far fa-eye' +
                      (showNewPassword ? '-slash ' : ' ') +
                      'show-pass'
                    }
                  ></i>
                }
              />
            </div>
            <div className="col-12 col-lg-6 d-flex">
              <RequiredInputLabel
                labelName="Re write your password:"
                labelClass={labelClass}
                inputClass={inputClass}
                inputName="confirmPassword"
                inputType={showConfirmPassword ? 'text' : 'password'}
                errorMessage={error.confirmPassword}
                onChangeFunction={(event) => {
                  updateLocalUser({
                    confirmPassword: event.target.value,
                  });
                }}
                showPasswordComponent={
                  <i
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                    className={
                      'far fa-eye' +
                      (showConfirmPassword ? '-slash ' : ' ') +
                      'show-pass'
                    }
                  ></i>
                }
              />
            </div>
          </>
        )}

        <div className="col-12 text-center">
          <button
            type="button"
            onClick={onSubmitPersonalData}
            className="next-button"
          >
            {nextText}
            {!profile && (
              <i className="fas fa-arrow-right next-button-icon"></i>
            )}
          </button>
        </div>
      </div>
    </React.Fragment>
  );
};

export default PersonalFormData;
