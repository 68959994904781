import React, { Component } from 'react';
import Textbox from '../shared/textbox';
import auth from '../classes/auth';
import './login-form.css';
import logo from '../../assets/images/logo.png';
import { Services } from '../../api/services';
import { setNavigationItems } from '../../actions/general-actions';
import { setUser } from '../../actions/user-actions';
import { connect } from 'react-redux';
import Spinner from 'react-bootstrap/Spinner';
import Cookies from 'universal-cookie';
import { config } from '../../utilities/env';
//const config = process.env.REACT_APP_PRODUCTION ? null : (process.env.REACT_APP_TESTING ? require('../../api/config/1950labs-testing.json') : require('../../api/config/1950labs-staging.json'));
class LoginForm extends Component {
  state = {
    email: '',
    emailError: '',
    password: '',
    passwordError: '',
    user: [],
    processing: false,
  };

  handleTextboxChange = (e) => {
    this.setState({ [e.target.name]: e.currentTarget.value });
  };

  componentDidMount = () => {
    const firebaseUser = Services.Users.getUserLoggedIn();
    if (firebaseUser !== undefined && firebaseUser !== null) {
      this.props.history.push('/app');
    }
    // const currentUserId = new Cookies().get("1950SystemUserId");
    // const isAuthenticated = auth.isAuthenticated();
    // console.log(isAuthenticated);
    // if (
    //   typeof currentUserId !== "undefined" &&
    //   currentUserId &&
    //   currentUserId.toString().length > 0 &&
    //   (isAuthenticated === true || isAuthenticated === "true")
    // ) {
    //   this.props.history.push("/app");
    // }
  };

  handleLogin = (e) => {
    e.preventDefault();
    const error = this.validateForm();

    if (!error) {
      this.setState({
        processing: true,
      });
      Services.Users.login(this.state.email, this.state.password)
        .then((data) => {
          if (data && data.user !== null && data.navigation !== null) {
            this.props.setNavigationItems(data.navigation);
            this.props.setUser([], data.user);
            setTimeout(() => {
              if (
                typeof data.user.needsToFillRegister === 'undefined' ||
                (typeof data.user.needsToFillRegister !== 'undefined' &&
                  data.user.needsToFillRegister === true)
              ) {
                auth.login(() => {
                  this.props.history.push({
                    pathname: '/registerForm',
                    state: {
                      user: data.user,
                    },
                  });
                }, data.user.id);
              } else {
                auth.login(() => {
                  this.props.history.push('/app');
                }, data.user.id);
              }
            }, 2000);
          } else {
            alert('Incorrect Email/Password');
            this.setState({
              processing: false,
            });
          }
        })
        .catch((error) => {
          this.setState(
            {
              password: '',
              processing: false,
            },
            () => {
              alert('Incorrect Email/Password');
            }
          );
        });
    }
  };

  validateForm = () => {
    this.setState({
      emailError: '',
      passwordError: '',
    });
    if (this.state.email.length <= 0) {
      this.setState({ emailError: 'Email is Required.' });
      return true;
    } else if (this.state.password.length <= 0) {
      this.setState({ passwordError: 'Password is Required.' });
      return true;
    }
  };

  render() {
    return (
      <React.Fragment>
        {typeof config !== 'undefined' && config !== null ? (
          typeof config['version'] !== 'undefined' &&
          config['version'] !== null &&
          config['version'] !== '' ? (
            config['projectId'] === 'labs-prod-ff285' ? (
              ''
            ) : config['projectId'] === 'labs-test-3c183' ? (
              <div style={{ color: 'black' }}>
                testing version: {config['version']}
              </div>
            ) : (
              <div style={{ color: 'black' }}>
                development version: {config['version']}
              </div>
            )
          ) : (
            ''
          )
        ) : (
          ''
        )}
        {/* {process.env.REACT_APP_PRODUCTION ? null
                    : (process.env.REACT_APP_TESTING ? <div style={{color: 'white'}}>testing version: {process.env.REACT_APP_VERSION}</div> 
                        : <div style={{color: 'white'}}>development version: {process.env.REACT_APP_VERSION}</div>)
                } */}

        {/* <header className="App-header">
                    <a href='/'>
                        <img src={logo} className="App-logo" alt="logo" />
                    </a>
                </header> */}
        <div
          className="navbar-container margin-navbar"
          style={{ backgroundColor: '#f7f7f7' }}
        >
          <div className="container">
            <div className="row">
              <div className="col-12 navbar-content">
                <div className="title-forgot-pw">DASHBOARD</div>
              </div>
            </div>
          </div>
        </div>
        <form
          className="login-form-main"
          style={{
            backgroundColor: 'white',
            border: 'none',
            display: 'flex',
            paddingBottom: '65px',
          }}
        >
          <a href="/">
            <img
              src={logo}
              className="App-logo"
              style={{ marginLeft: '-7%!important' }}
              alt="logo"
            />
          </a>
          <Textbox
            name="email"
            errorText={this.state.emailError}
            inputLabel="Email address"
            labelClass="email-label"
            inputType="email"
            inputPlaceholder="Sample@1950labs.com"
            inputClass="input-login form-control"
            handleTextboxChange={this.handleTextboxChange.bind(this)}
            errorMessage={this.state.emailError}
            errorMessageStyle={{ color: 'red' }}
            style={{ width: '50%!important', marginLeft: '7%!important' }}
          />
          <Textbox
            name="password"
            value={this.state.password}
            errorText={this.state.passwordError}
            inputLabel="Password"
            labelClass="email-label"
            inputType="password"
            inputPlaceholder="Password"
            inputClass="input-login form-control"
            handleTextboxChange={this.handleTextboxChange.bind(this)}
            errorMessage={this.state.passwordError}
            errorMessageStyle={{ color: 'red' }}
            style={{ width: '50%!important', marginLeft: '7%!important' }}
            // showFogotLink={true}
          />
          {this.state.processing ? (
            <Spinner
              className="mr-4"
              style={{ color: '#578db9' }}
              animation="border"
            />
          ) : (
            <div className="d-none d-md-flex">
              <a
                href="forgotPassword"
                className="back-to-login"
                style={{ marginTop: '16px', marginRight: '15px' }}
              >
                Forgot Password?
              </a>
              <button
                type="submit"
                onClick={this.handleLogin}
                className="add-new-button d-md-block"
              >
                Login
              </button>
            </div>
          )}
        </form>
        <div
          className="d-flex d-md-none justify-content-center w-100"
          style={{ marginTop: '-24px' }}
        >
          {this.state.processing ? (
            null
            // <Spinner
            //   className="mr-4"
            //   style={{ color: '#578db9' }}
            //   animation="border"
            // />
          ) : (
            <div className="flex-column">
              <button
                type="submit"
                onClick={this.handleLogin}
                className="add-new-button"
              >
                Login
              </button>
              <br />
              <br />
              <a
                href="forgotPassword"
                className="back-to-login"
                style={{ marginTop: '16px', marginRight: '0px!important', marginLeft: '0px!important' }}
              >
                Forgot Password?
              </a>
            </div>
          )}
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    navigationItems: state.generalStore.navigationItems,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setNavigationItems: (navigationItems) =>
    dispatch(setNavigationItems(navigationItems)),
  setUser: (users, user) => dispatch(setUser(users, user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);
